/* Is Invalid  */
/* Sign -in-header */
.onboarding-header {
  background: #fff;
  padding: 17px 0;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.05);
}

.onboarding-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onboarding-header .title h4 {
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: #000;
}



.btn-light{
  background-color: #fff!important;
    border-radius: 10px;
    color: #063088;
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    width: 78px;
    flex:0 0 78px;
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    text-transform: capitalize;

    border: none;
    cursor: pointer;
}
.onboard-form .is-invalid {
  border: 2px solid #CA3838;
}
.onboard-form .form-group .is-invalid + label:after {
    content: "";
    background: url('../images/new/error-icon.svg') center center no-repeat;
    background-size: 14px 14px;
    margin-left: 8px;
    display: inline-block;
    width: 14px;
    height: 14px;
}
.onboard-form .form-group .is-invalid + label,
.onboard-form  .group-hover .is-invalid ~ .eye-img i,
.onboard-form  .form-group .is-invalid{
     color:#CA3838!important;
}
.onboarding-title-sm{
  display: none;
  margin-bottom: 30px;
  text-align: center;
}
.onboarding-title-sm h4{
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}
.onboard-form .form-group label {
  order: -1;
  transition: 0.3s all ease;
  font-weight: 400;
}

.onboard-form .form-input:focus+label,
.onboard-form .form-input:hover+label {
  color: var(--color-secondary);
  font-size: 16px;

}
.onboard-form  .group-hover:hover .eye-img i {
  color: var(--color-secondary);
}
.onboard-form .group-hover:hover .form-input
 {
  outline: none;
  border-color: var(--color-secondary)!important;
}
.onboard-form .group-hover:hover label{
  color: var(--color-secondary);
  font-size: 16px;
}
.onboard-form .form-input:hover ~ .eye-img i,
.onboard-form .form-input:focus ~ .eye-img i {
  color: var(--color-secondary);
}
.onboard-form .eye-img i{
  color:#E8E6E6;
  cursor: pointer;
}
.onboard-form .form-input:hover {
    outline: none;
    border-color: var(--color-secondary);
}
.onboard-form .form-input:hover.is-invalid,
.onboard-form .form-input:focus.is-invalid{
  border-color: #CA3838!important;
}
.eye-img {
  position: absolute;
  right: 12px;
  top: 53%;
}
/* custom check box */
.onboard-form .form-group .checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.onboard-form .form-input {
    padding: 10px 40px;
}
.onboard-form .form-group .checkbox-lable {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.onboard-form .form-group .checkbox-lable:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #E8E6E6;
  border-radius: 2px;
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  top: -0.5px;
  margin-right: 9px;
}

.onboard-form .form-group input:checked+.checkbox-lable {
  color: #063088;
}

.onboard-form .form-group input:checked+.checkbox-lable:before {
  background-color: #063088;
  border: 2px solid #063088;
  color: #063088;
}

.onboard-form .form-group input:checked+.checkbox-lable:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.onboarding-header .show-desk{
  display: block;
}
.onboarding-header .show-mob{
  display: none;
}
@media (max-width:991px) {
  .onboarding-title-sm{
    display: block;
  }
  .onboarding-header .show-desk{
    display: none;
  }
  .onboard-form .form-input {
    padding: 10px 26px;
}
  .onboarding-header .show-mob{
    display: block;
  }
  .btn-light{
      font-size: 12px;
      line-height: 30px;
      width: 63px;
      height: 34px;
  }
  .logo-img svg{
    width: 104px;
  }
  .onboarding-header .title h4 {
   display: none;
  }
  .onboarding-header-row {
   padding: 0 20px;
  }
}
@media (max-width:768px) {
  .eye-img {
    top: 56%;
  }
}
