@import "~@ng-select/ng-select/themes/default.theme.css";

html {
  scroll-behavior: smooth;
}

.overflow-hidden {
    overflow: hidden !important;
    touch-action: none !important;
    -ms-touch-action: none !important;
    height: 100% !important;
    width: 100%;
    position: relative !important;
    -webkit-touch-action: none !important;
    touch-action: none !important;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}
*:focus {
    outline: none;
    box-shadow: none;
}

:root {
    --color-success: #23CE6B;
    --color-primary: #08083D;
    --color-secondary: #063088;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    -webkit-appearance:none;
}
a:not(.btn){
  text-decoration: none;
}
/*--- Fonts Start ---*/


@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/HelveticaMedium.woff2') format('woff2'),
        url('./fonts/HelveticaMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway-Bold.woff2') format('woff2'),
        url('./fonts/Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica.woff2') format('woff2'),
        url('./fonts/Helvetica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Bold.woff2') format('woff2'),
        url('./fonts/Helvetica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Light.woff2') format('woff2'),
        url('./fonts/Helvetica-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


body {
    font-family: "Helvetica";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
}
html body.fixed {
    overflow: hidden !important;
    touch-action: none !important;
    -ms-touch-action: none !important;
    height: 100% !important;
    overflow: hidden;
    width: 100%;
    position: relative !important;
    -webkit-touch-action: none !important;
    touch-action: none !important;
}

/*--- Fonts End ---*/

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a,
a:hover {
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s all ease;
}

h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    font-family: "Helvetica";
}
.guideBlogTemp .breadcrumb{
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  flex-wrap: nowrap!important;
}
.container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
}

.d-none {
    display: none !important;
}

section {
    position: relative;
    z-index: 1;
}

.section-title {
    text-align: center;
    margin-bottom: 38px;
}

.section-title.title-bg {
    background: linear-gradient(180deg, #06287A 20.94%, #070B43 87.72%);
    margin-bottom: 0;
    padding: 160px 0 60px;
}
.section-title h1 {
    margin: 0;
    color: var(--color-primary);
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    font-family: "Helvetica";
}
.section-title h2 {
    margin: 0;
    color: var(--color-primary);
}

.title-container {
    max-width: 968px;
    margin: 0 auto;
    padding: 0 15px;
}

.section-title h2:last-child {
    margin-bottom: 0;
}

.section-title h2 span,
.section-title h1 span {
    color: var(--color-success) !important;
}

.section-title p {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    margin: 0;
}

.section-title h2+p {
    margin-top: 25px;
}

.section-title.title-bg * {
    color: #fff;
}

.btn-primary {
    background-color: var(--color-success);
    border-radius: 10px;
    color: #FAFAFA;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    max-width: 344px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50px;
    text-transform: capitalize;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    border: none;
    cursor: pointer;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #1EB25D !important;
    box-shadow: none !important;
    outline: none !important;
}
.btn-primary .fa-spin{
  margin-left:10px;
}
.btn-secondary {
    background-color: var(--color-secondary);
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    max-width: 344px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    text-transform: uppercase;
    border: none;
    width: 100%;
    cursor: pointer;
}

.btn-secondary-outline {
    border: 1px solid var(--color-secondary);
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--color-secondary);
    max-width: 344px;
    height: 56px;
    background-color: #fff;
    max-width: 344px;
    min-width: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-secondary-outline:hover {
    background-color: var(--color-secondary) !important;
    color: #fff;
}

.btn-secondary-outline.btn-sm {
    height: 36px;
    border-radius: 5px;
}

.btn-primary.btn-sm {
    border-radius: 4px;
    max-width: 164px;
    width: 100%;
    height: 36px;
    min-width: 86px;
    color: #fff;
    text-transform: capitalize;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}
.btn-outline-light {
    max-width: 160px;
    height: 34px;
    right: 107px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px;
    padding: 8px 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}


.bg-green {
    background-color: var(--color-success) !important;
}

.bg-dark {
    background-color: var(--color-primary) !important;
}

.bg-blue {
    background-color: var(--color-secondary) !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters>.col,
.row.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.overflow-hidden {
  height: 100%;
 overflow: hidden;
 width: 100%;
 position: fixed;
}

.d-none,
.desktop-hide {
    display: none !important;
}

.d-block {
    display: block !important;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-outer {
    flex: 1 0 auto;
}

footer {
    flex-shrink: 0;
}
.mobile-divider {
  display:none;
  background: linear-gradient(180deg, #063C9C 0%, #08083D 100%);
  padding: 24px 0 70px;
}

/*--- Body CSS End ---*/


/*--- Swiper SLider CSS Start ---*/

section .swiper-pagination-bullet {
    width: 14.29px;
    height: 14.29px;
    background: #F6F9FF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: 0.25s all ease;
    margin: 0 7px !important;
    position: relative;
}


section .swiper-pagination-bullet:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: var(--color-secondary);
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

section .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--color-secondary);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

section .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    opacity: 1;
}
/*--- Swiper SLider CSS End ---*/


/*--- Ng Select CSS Start ---*/

body .franchise-filters .ng-select {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0;
    height: 37px;
    box-sizing: border-box;
    min-width: 100px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
}
.franchise-filters .ng-select .ng-select-container,
.application-content .ng-select .ng-select-container.ng-has-value .ng-value {
    border-radius: 3px !important;
    padding: 5px 9px 7px 0;
    border: none;
    height: 37px !important;
    /* max-width: 180px; */
    overflow-x: auto;
    cursor: pointer;
    min-width: 110px;
}
.contactCard .form-group.error .ng-select .ng-select-container {
      border: 1px solid  #b93d3d!important;
}
.contactCard  .ng-select:focus,
.contactCard  .ng-select:focus-visible{
  border-color: #E1E1E1!important;
  outline: none!important;
}
.contactCard .form-group .ng-select .ng-select-container {
      border: 1px solid transparent!important;
      font-size: 13px;
      background-color: #F5F5F5;
      line-height: 18px;
      color: #263238;
      font-weight: 400;
      width: 100%;
      min-height: 42px;
      border-radius: 8px 0px 0px 8px;
}
.contactCard .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #000 transparent transparent;

}
.contactCard .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    border-color: transparent transparent #020202!important;
}
.contactCard .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px;
}
.contactCard .ng-select .ng-arrow-wrapper {
    padding-right: 13px;
    top:3px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: unset;
    text-overflow: unset;
}
.contactCard .ng-select .ng-clear-wrapper {
   display: none;
}
.contactCard .ng-dropdown-panel.ng-select-bottom {
    min-width: 100%!important;
}

.contactCard .ng-select.ng-select-single .ng-select-container {
    height: 42px;
}
.contactCard .ng-select .ng-select-container .ng-value-container {
    padding-left: 16px;
}
body .contactCard .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}
body .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    padding: 5px 9px 7px 9px;
}
.application-content .ng-select .ng-select-container.ng-has-value .ng-value {
    margin-bottom: 0 !important;
}
.application-content .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value {
    background: #F6F6F6;
    overflow: visible;
    opacity: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: #8D8D8D !important;
}
.application-content .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value .ng-value-label {
    color: #8D8D8D !important;
}
.application-content .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    border: none;
    padding: 0;
    font-size: 20px;
    line-height: 11px;
    color: #8D8D8D !important;
    top: -1px;
    margin-left: 10px;
    position: relative;
}
.application-content .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: transparent !important;
}
.application-content .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0;
    width: 100%;
    position: relative;
    top: 2px;
}
.justify-end{
  justify-content: end!important;
}
body .franchise-filters .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #000000;
    padding: 0 !important;
    position: static !important;
    transform: none !important;
}

body .franchise-filters .ng-select .ng-arrow-wrapper {
    background: url("../images/new/angle-down.svg") center 90% no-repeat;
    background-size: 11px 7.16px;
    width: 11px;
    height: 7.16px;
    top: 2px;
}

.franchise-filters .ng-select .ng-arrow-wrapper .ng-arrow {
    display: none !important;
}

body .franchise-filters .ng-select .ng-select-container {
    padding: 5px 9px 7px 15px;
    border: none;
    height: 37px !important;
    max-width: 180px;
    overflow-x: auto;
    cursor: pointer;
    min-width: 110px;
}
body .franchise-filters .categorySelection .ng-select-container {
    width: 150px;
}
/* width */
body .franchise-filters .ng-select .ng-select-container::-webkit-scrollbar {
    height: 3px;
}
.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
    width: 3px;
}
body .application-content   .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 463px;
}
/* Track */
body .franchise-filters .ng-select .ng-select-container::-webkit-scrollbar-track,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
    border-radius: 3px;
}

/* Handle */
body .franchise-filters .ng-select .ng-select-container::-webkit-scrollbar-thumb,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
    background: var(--color-success);
    border-radius: 3px;
    opacity: 0.7;
}

.franchise-filters .ng-select .ng-select-container.ng-has-value {
    background: #F6F6F6;
}

.franchise-filters .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.franchise-filters .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 !important;
}

body .franchise-filters .ng-select .ng-select-container .ng-value-container {
    padding: 0 10px 0 0;
    flex-wrap: nowrap;
}
body .franchise-filters .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    flex-wrap: nowrap;
    padding: 0;
}

body .franchise-filters .ng-select .ng-clear-wrapper {
    margin-left: 0;
    width: auto;
    height: auto;
}

body .franchise-filters .ng-select .ng-clear-wrapper .ng-clear {
    font-size: 19px;
    line-height: 11px;
}

body .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: inherit !important;
    box-shadow: none !important;
    outline: none !important;
}

.franchise-filters .ng-select .ng-select-container.ng-has-value .ng-arrow-wrapper {
    display: none;
}

body .franchise-filters .ng-dropdown-panel {
    min-width: 100%;
    height: auto;
    transition: 0.3s all ease;
    top: calc(100% + 4px);
    border: none;
    padding: 0;
    border-radius: 3px;
    background-color: transparent;
    border: none;
}
body .franchise-filters .ng-dropdown-panel-items {
    opacity: 0;
    visibility: hidden;
    position: relative;
    top: -10px;
    box-shadow: 0px 1px 4px rgb(0, 0, 0, 0.25);
    background: #FFFFFF;
    transition: 0.2s all ease;
    transition-delay: 0.2s;
}
body .franchise-filters .ng-select-opened .ng-dropdown-panel-items {
    opacity: 1;
    visibility: visible;
    top: 0;
}

body .franchise-filters .ng-dropdown-panel .ng-option {
    padding: 7px 9px 7px 10px !important;
    font-size: 14px;
    background-color: transparent !important;
    border-bottom: none;
    border-radius: 0;
    position: relative;
    height: 37px;
    line-height: 1;
    display: flex;
    align-items: center;
}
body .franchise-filters .ng-dropdown-panel .ng-option:after {
    content: "";
    width: calc(100% - 30px);
    height: 1px;
    background-color: rgba(242, 242, 242, 0.6);;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
}
body .franchise-filters .ng-dropdown-panel .ng-option:last-child:after {
    content: none;
}

body .franchise-filters .ng-dropdown-panel .ng-option:last-child {
    border-bottom: none;
}

body .franchise-filters .ng-dropdown-panel .ng-option:hover {
    background-color: #F6F9FF !important;
    color: var(--color-secondary) !important;
}

.franchise-filters .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-bottom: 0;
}

.franchise-filters .ng-select .ng-clear-wrapper:hover .ng-clear,
.franchise-filters .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.franchise-filters .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    color: var(--color-secondary) !important;
}
.franchise-filters .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    color: #999;
}
.franchise-filters .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    background-color: transparent;
    border: none;
    font-size: 20px;
    line-height: 23px;
}
.franchise-filters .ng-select.ng-select-multiple .ng-select-container  .ng-clear-wrapper {
    display: none  !important;
}
.franchise-filters .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.franchise-filters  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: transparent;
}
/* icon-success */
.mem_svg_wrap{
  width: 75px;
    height: 75px;
    margin: 0 auto;
    margin: 29px auto 34px;
  }
  .mem_svg_wrap img{
    width:100%;
  }
  .svg_pop_wrapper p{
    margin-bottom: 29px;
  }
  /* custom check box */
   .form-group .checkbox {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    z-index: 111;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
   .form-input {
      padding: 10px 40px;
  }
   .form-group .checkbox-lable {
    position: relative;
    cursor: pointer;
    margin-bottom: 0px;
  }

   .form-group .checkbox-lable:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #E8E6E6;
    border-radius: 2px;
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    top: -0.5px;
    margin-right: 9px;
  }

   .form-group input:checked+.checkbox-lable {
    color: #063088;
  }

   .form-group input:checked+.checkbox-lable:before {
    background-color: #063088;
    border: 2px solid #063088;
    color: #063088;
  }

   .form-group input:checked+.checkbox-lable:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
/* Checkbox Style */

.options-checkbox,
.options-radio {
    position: relative;
}

.options-checkbox input,
.options-radio input {
    padding: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 11;
    background: red;
}

.options-checkbox label,
.options-radio label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    display: flex;
    align-items: center;
    width: 100%;
}

.options-checkbox label:before,
.options-radio label:before {
    content: '';
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    border: 1.3px solid #666;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0;
    -webkit-appearance: none;
    background-color: transparent;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
}

.options-checkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 3.5px;
    width: 5px;
    height: 9px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.options-radio label:before {
    border-radius: 50%;
    width: 17px;
    height: 17px;
    flex: 0 0 17px;
}

.options-radio input:checked+label:after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    flex: 0 0 11px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    left: 3px;
}

.filter-popup .options-radio label:before {
    margin-right: 30px;
}

.filters-mobile {
    overflow: auto;
    display: none;
    margin: 0 auto 35px;
}

.filtersRow-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 10px 15px 10px 5px;
    min-width: 560px;
}

.filterTag-wrap {
    border: none;
    height: 37px !important;
    background-color: #fff;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    display: flex;
    max-width: 100%;
    margin-right: 10px;
    flex: 1 0 auto;
}
.filterTag-wrap .filter-tag + .filter-tag {
    margin-left: 10px;
}
.filterTag-wrap:last-child {
    margin-right: 0;
}
.filter-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    color: #000000;
    width: 100%;
    text-align: left;
    background: url("../images/new/angle-down.svg") 92% center no-repeat;
    background-size: 12px 9px;
    padding: 7px 35px 7px 15px;
    cursor: pointer;
    height: 37px;
    min-width: 100px;
    max-width: 180px;
    border-radius: 3px;
}

.filter-tag {
    padding: 7px 15px 7px 15px;
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    height: 37px;
    color: #8D8D8D;
    max-width: 100%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.filter-tag .clear-tag {
    background: transparent;
    border: none;
    padding: 0;
    color: #8D8D8D;
    font-size: 22px;
    margin-left: 5px;
    height: 20px;
    width: 20px;
    line-height: 3px;
    cursor: pointer;
}

.filter-tag .clear-tag:hover {
    color: var(--color-secondary) !important;
}


/*--- List Filters CSS End ---*/


/*--- Filter Popup CS Start ---*/

.filterPopup-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: 0.3s all ease;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
.filterPopup-wrap
.filter-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.filter-popup {
    width: 414px;
    min-height: 341px;
    background: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    transition: 0.3s all ease;
    position: relative;
    top: 40px;
}
.filterPopup-wrap.show  {
    opacity: 1;
    visibility: visible;
    z-index: 999;
}
.filterPopup-wrap.show .filter-popup {
    top: 0;
}

.filterPopup-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.filterPopup-title {
    display: flex;
    align-items: center;
}

.filterPopup-title h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.filterPopup-close {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 12px;
    top: -4px;
    position: relative;
}

.filterPopup-title svg+h3 {
    margin-left: 15px;
}

.filterPopup-body {
    flex: 1 0 auto;
}
.filterPopup-body ul {
    padding-bottom: 35px;
}
.filter-popup .options-checkbox label:before {
    width: 17px;
    height: 17px;
    flex: 0 0 17px;
    margin-right: 30px;
}

.filter-popup input[type="checkbox"]:checked+label:after {
    top: 4px;
    left: 5px;
    width: 6px;
    height: 11px;
}

.filter-popup li {
    font-size: 16px;
    color: #000;
}

.filter-popup li+li {
    margin-top: 15px;
}


/*--- Filter Popup CS End ---*/


/*--- bread-crumbs CSS Start ---*/

.bread-crumb {
    margin-bottom: 54px;
}

.bread-crumb ol {
    display: flex;
    align-items: center;
}

.bread-crumb li {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    text-transform: capitalize;
    padding-right: 5px;
    color:#062877;
}

.bread-crumb a {
    color: #6B6B6B;
}

.bread-crumb li:after {
    content: ">";
    color: #6B6B6B;
    margin-left: 5px;
}

.bread-crumb li:last-child:after {
    content: none;
}


/*--- bread-crumbs CSS End ---*/


/*--- Applicatios Select CSS Start ---*/

body .application-section .ng-select-container {
    border: 2px solid #E8E6E6;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: transparent;
    width: 100%;
    height: 58px !important;
    padding: 10px 35px;
    cursor: pointer;
    box-shadow: none !important;
    outline: none !important;
}

.application-section .ng-select .ng-select-container .ng-value-container .ng-placeholder,
.application-section .ng-select .ng-select-container .ng-value-label {
    font-size: 15px;
    line-height: 30px;
    color: #949494;
    font-weight: bold;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 50%;
    padding: 0;
    transform: translateY(-50%);
}
.application-section .ng-select .ng-arrow-wrapper {
    padding: 0;
}

body .application-section .ng-arrow-wrapper:after {
    content: "\f067";
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    color: #949494;
}

.application-section .ng-select-opened .ng-arrow-wrapper:after {
    content: "\f068";
    color: #fff;
}

body .application-section .ng-arrow {
    display: none !important;
}

body .application-section .ng-option {
    border-bottom: 1px solid var(--color-secondary);
    width: 100%;
    height: 58px !important;
    padding: 10px 35px !important;
    background-color: #fff;
    display: flex !important;
    align-items: center;
}

body .application-section .ng-option:last-child {
    border-bottom: none
}

body .application-section .ng-select-opened .ng-select-container {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: #fff;
}
body .form-group.error .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #CA3838 !important;
}
body  .application-section .ng-select.ng-select-opened .ng-value-container .ng-placeholder,
body  .application-section .ng-select.ng-select-opened .ng-value-container .ng-value-label,
body  .application-section .ng-select .ng-select-container .ng-value-container .ng-input>input {
    color: #fff !important;
}

.application-section .ng-value-container {
    padding: 0 !important;
}

.application-section .ng-dropdown-panel {
    box-shadow: none !important;
    border: 1px solid var(--color-secondary);
}

.application-section .ng-clear-wrapper {
    display: none !important;
}

.application-section .ng-select .ng-select-container.ng-has-value .ng-value-label {
    color: #043899;
}

body .application-section .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: inherit !important;
    box-shadow: none !important;
    outline: none !important;
}


/*--- Applicatios Select CSS End ---*/


/*--- Applicatios Input CSS Start ---*/

.form-group {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.form-group label {
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: #949494;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    transition: 0.3s all ease;
}

.form-group .required {
    color: #ff0000;
    display: inline-block;
    font-size: 18px;
}

.form-group+.form-group {
    margin-top: 25px;
}

.label-bottom {
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: #949494;
    display: inline-block;
    text-align: right;
    margin-top: 10px;
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.label-bottom:before {
    content: "\f067";
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    color: #949494;
    margin-right: 10px;
}

.label-bottom.minus:before {
      content: "\f068";
      display: inline-block;
      font: normal normal normal 16px/1 FontAwesome;
      color: #949494;
      margin-right: 10px;
}

.label-bottom:hover,
.label-bottom:hover:before {
    color: var(--color-secondary);
}

.form-input {
    height: 58px;
    border: 2px solid #E8E6E6;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-secondary) !important;
    font-weight: bold;
    background-color: transparent;
    padding: 10px 35px;
    position: relative;
}
body .form-input:-webkit-autofill,
body .form-input:-webkit-autofill:hover,
body .form-input:-webkit-autofill:focus,
body .form-input:-webkit-autofill:active {
    color: var(--color-secondary) !important;
    -webkit-text-fill-color: var(--color-secondary) !important;
}
.form-input::placeholder {
    padding-left: 5px;
}
textarea.form-input {
    height: 155px;
}

.form-input:focus {
    outline: none;
    border-color: var(--color-secondary);
}
.form-group.error .form-input,
body .application-section .form-group.error .ng-select-container {
    border-color: #CA3838 !important;
}
body .application-section .form-group.error .ng-arrow-wrapper:after {
    color: #CA3838 !important;
}
.form-group.error label,
.form-group.error label span {
    color: #CA3838;
}
.form-group.error>label:after {
    content: "";
    background: url('../images/new/error-icon.svg') center center no-repeat;
    background-size: 14px 14px;
    margin-left: 4px;
    display: inline-block;
    width: 19px;
    height: 19px;
}
/*-- Radio Button Style --*/

.radios-row {
    display: flex;
    flex-wrap: wrap;
}

.radios-row .form-radio-group {
    max-width: 33.333%;
    flex: 0 0 33.3333%;
    width: 100%;
    margin-bottom: 15px;
}

.radios-wrap .form-radio-group {
    max-width: 100%;
    flex: 0 0 100%;
}

.form-radio-group {
    position: relative;
    margin-top: 10px;
    width: 33%;
}


.form-radio-input {
    display: none;
}

.form-radio-group .form-radio-label {
    cursor: pointer;
    position: relative;
    padding-left: 40px;
    padding: 5px 10px 5px 40px;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 0;
}

.form-radio-button {
    height: 22px;
    width: 22px;
    border: 2px solid #E8E6E6;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 4px;
}

.form-radio-button::after {
    content: "";
    display: block;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-secondary);
    opacity: 0;
    transition: opacity 0.2s;
}

.form-radio-input:checked~.form-radio-label .form-radio-button {
    border-color: var(--color-secondary);
    background-color: transparent;
}

.form-radio-input:checked~.form-radio-label {
    color: var(--color-primary);
}

.form-radio-input:checked~.form-radio-label .form-radio-button::after {
    opacity: 1;
}
.disclaimer-step .form-radio-input:checked~.form-radio-label .form-radio-button::after {
    background: url('../images/new/check-round.svg') center center no-repeat;
}

.note-box,
.disclaimer-box {
    background-color: #FFFFFF;
    border: 2px solid #E3E2E2;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 45px 45px;
    margin-bottom: 35px;
}

.note-box p,
.disclaimer-box p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #949494;
}

.note-box p+p,
.disclaimer-box + p  {
    margin-top: 15px;
}

.text-danger {
    color: #F35563 !important;
}


/*--- Applicatios Input CSS End ---*/


/*--- Header Title CSS Start ---*/
.head-title {
    display: none;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    padding: 17px 40px 17px 20px;
    height: 68px;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 50px;
    z-index: 100;
    width: calc(100% - 50px);
}
.head-title h4,
.head-title h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.head-title h4.w-100,
.head-title h1.w-100 {
   width:100%;
}
.head-title .close-btn {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
}
.head-title.frDesc-title {
    justify-content: center;
}
/*--- Header Title CSS End ---*/

/*--- LeaderBox CSS Start ---*/
.leader-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 32px 65px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 17px;
    cursor: pointer;
}

.leader-box:last-child {
    margin-bottom: 0;
}

.leader-box:after {
    content: "";
    width: 40px;
    height: 40px;
    background-image: url("/assets/images/new/angle-shape.png");
    background-position: center center;
    background-size: 23px;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: -26.5px;
    top: 0px;
}

.leader-box .check-icon {
    width: 54px;
    height: 40px;
}

.leader-box .check-icon img,
.leader-box .check-icon svg {
    width: 100%;
}

.leader-box p {
    max-width: 485px;
    font-weight: normal;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
    padding-left: 65px;
}
/*--- LeaderBox CSS End ---*/

/*--- Datepicker CSS Start ---*/
body .form-input .datepicker-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
body .form-input .datepicker-container input {
    width: 100%;
    background: transparent;
    padding: 10px 35px;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-secondary);
    font-weight: bold;
    border: none;
    height: 100%;
}
body .form-input .datepicker-container .calendar-container .main-calendar-container .main-calendar-day-names,
body .form-input .datepicker-container .calendar-container .main-calendar-container .main-calendar-days {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
}
body .form-input .datepicker-container .calendar-container .main-calendar-container .main-calendar-days .day-unit,
.datepicker-container .calendar-container .main-calendar-container .main-calendar-day-names .day-name-unit {
    width: 35px !important;
    height: 35px !important;
    font-weight: 700;
}
body .form-input .datepicker-default .day-unit.is-selected,
body .form-input .datepicker-default .day-unit:hover,
body .form-input .datepicker-default .year-unit.is-selected,
body .form-input .datepicker-default .year-unit:hover {
    background-color: var(--color-secondary) !important;
    border-color: var(--color-secondary) !important;
}
body .form-input .datepicker-default .calendar-container {
    top: 100% !important;
    width: 302px !important;
}
/*--- Datepicker CSS End ---*/

/*--- Responsive Start ---*/

@media (max-width: 1440px) {
    body .application-content .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 383px;
    }
}

@media (max-width: 991px) {
    .btn-primary {
        text-transform: capitalize;
    }
    h2 {
        font-size: 30px;
        line-height: 34px;
        color: #000000;
    }
    .section-title h1 {
        font-size: 30px;
        line-height: 34px;
        color: #000000;
        text-align: center;
    }
    .section-title h2 {
        text-align: center;
    }
    .section-title p {
        font-size: 14px;
        line-height: 18px;
    }
    .desktop-hide {
        display: block !important;
    }
    .section-title.title-bg {
      background: linear-gradient(180deg, #063C9C 0%, #08083D 100%);
        padding: 115px 0 70px;
    }
    .filters-mobile {
        display: block;
    }

    body .application-content .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 347px;
  }
    body .form-group.error .ng-select .ng-select-container * {
        font-size: 14px !important;
    }
    body .application-section .ng-select-container,
    body .application-section .ng-option {
        padding:  10px 30px 10px 30px!important;
    }
    .form-input {
        padding: 10px 30px;
        width: 100%;
    }
    .form-group .required {
        font-weight: bold;
        font-size: 24px;
        line-height: 23px;
        color: var(--color-secondary);
    }
    .form-group>label {
        display: flex;
        margin-bottom: 9px;
    }
    .form-group>label .required {
        margin-left: auto;
        order: 12;
        top: 10px;
        position: relative;
        padding: 0 0 0 5px;
    }
    .franchiseProduct-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
      background: var(--color-success);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
    section .swiper-pagination-bullet {
    width: 10px;
    height: 10px;

    margin: 0 5px !important;

    }
    section .swiper-pagination-bullet:before {
    content: "";
    width: 14px;
    height: 14px;
    }
    .note-box {
        padding: 34px 13px;
        border: 1px dashed #272755;
        border-radius: 5px;
        background-color: transparent;
    }
    .note-box p {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .disclaimer-box {
        height: 349px;
        padding: 24px;
        background: #FFFFFF;
        overflow: auto;
    }
    .disclaimer-box p {
        font-size: 14px;
    }
    .label-bottom,
    .label-bottom:before {
        color: var(--color-secondary);
        font-weight: 700;
        font-size: 14px;
    }
    .label-bottom:before {
        margin-right: 7px;
    }

    /* width */
    .disclaimer-box::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    .disclaimer-box::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    /* Handle */
    .disclaimer-box::-webkit-scrollbar-thumb {
        background: var(--color-secondary);
        border-radius: 3px;
    }

    .head-title {
        display: flex;
    }

    .mobile-divider {
        display: block;
    }

    .leader-box {
        padding: 32px;
        margin-bottom: 15px;
        margin-left: 5px;
        max-width: calc(100% - 15px);
    }
    .leader-box p {
        padding-left: 28px;
        font-size: 14px;
        line-height: 16px;
    }
    .leader-box .check-icon  {
        width: 36px;
        height: 27px;
    }
    .leader-box .check-icon img {
        width: 36px;
        height: 27px;
    }
    .lg-show {
        display: none;
    }
    body .form-input .datepicker-default .calendar-container {
        width: 280px !important;
    }

    body .form-input .datepicker-container input {
        padding: 10px 30px;
    }
}

@media (max-width: 390px) {
    .leader-box p {
        padding-left: 18px;
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 380px) {
    .leader-box {
        padding: 25px;
    }
    .project-title p {
        font-size: 25px;
    }
}

@media (max-width: 375px) {
    .head-title h4,
    .head-title h1 {
        font-size: 17px;
    }
}
/*--- Responsive End ---*/




/* .inputWrap,
.inputWrap input {
    width: 100%;
    position: relative;
}
.inputWrap:after {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url("../images/new/input-red.png");
    background-position: center center;
    background-size: 15px;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: -20.5px;
    top: 0px;
} */

/*--- Form inputs CSS start ---*/
.gb_fieldStyle.gb_input{
  background: transparent!important;
}
.gb_inputGroup {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    transition: 0.3s all ease;
}
.gb_inputGroup + .gb_inputGroup {
    margin: 30px 0 0 0;
}
.gb_inputGroup.dateTimeField .datepicker-container input {
    width: 100%;
    padding: 10px 1px 10px 13px;
}
.date_timespan.gb_invalid label{
  color:#D93025!important;
}
.gb_inputGroup .datepicker-container  {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    top: -11px;
    left: -16px;
    height: 45px;
}

.gb_inputGroup .gb_input.date_timespan:focus {
    border-color: #1A73E8!important;
    outline: none !important;
}
.gb_inputGroup .datepicker-default .calendar-container {
    top: 46px!important;
}
.gb_input {
    background: #FFFFFF;
    border: 1px solid #E7E9EB;
    border-radius: 4px;
    height: 46px;
    padding: 10px 13px;
}
textarea.gb_input {
    height: 130px;
    resize: none;
}
.gb_inputGroup label {
    font-size: 14px;
    line-height: 16px;
    font-family: 'Helvetica';
    font-weight: 400;
    color: #9691A4;
    position: absolute;
    left: 13px;
    top: 24px;
    transition: 0.3s all ease;
    transform: translateY(-50%);
    min-width: 45px;
    font-weight: initial;
    pointer-events: none;
}

.gb_inputGroup .gb_input::placeholder {
    opacity: 0;
}

.gb_inputGroup .gb_input:focus + .gb_inputLabel,
.gb_inputGroup .gb_input:not(:placeholder-shown) + .gb_inputLabel {
    top: 1px;
    background: var(--color-white);
    display: inline-block;
    padding: 4px 4px;
    font-size: 12px;
}
.gb_inputGroup .gb_input:focus + .gb_inputLabel {
    color: #1A73E8;
}
.gb_inputGroup .gb_input:focus {
    border-color: #1A73E8;
    outline: none !important;
}
.gb_inputGroup .gb_input:disabled {
    border-color: #E7E9EB !important;
    background-color: #F9F9F9 !important;
}
.gb_inputGroup .gb_input:disabled + .gb_inputLabel {
    color: #C3C3C3 !important;
}
.gb_inputIcon {
    position: absolute;
    right: 7px;
    top: 1px;
    width: 30px;
    height: 44px;
    padding: 0;
    background-color: var(--color-white);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gb_inputGroup .gb_input:disabled + label + .gb_inputIcon {
    background-color: #F9F9F9;
}
.gb_errorLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: none;
    align-items: center;
    color: #D93025;
    margin: 7px 0 0 0;
    order: -1;
}
.gb_errorLabel svg {
    margin-right: 6px;
}
.gb_inputGroup  .gb_invalid {
    border-color: #D93025 !important;
}
.gb_inputGroup .gb_invalid + .gb_inputLabel,
.gb_inputGroup .gb_invalid + label {
    color: #D93025 !important;
}
.gb_inputGroup .gb_invalid + label + .gb_errorLabel,
.gb_inputGroup .gb_invalid + .gb_errorLabel {
    display: flex;
}
/*--- Form inputs CSS start ---*/
/* btnLoader */
.dotLoader {
    margin: 0px auto;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.dotLoader.static {
    position: static;
    transform: none;
    padding: 20px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dotLoader.white .dot{
  background-color: rgba(255, 255, 255, 0.7)
}
.dotLoader .dot{
  background-color: rgba(34, 34, 34, 1);
  width: 6px;
  height: 6px;
  margin-right: 7px;
  border-radius: 100%;
  display: inline-block;
  animation-name: dotLoaderAnim;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
  vertical-align: middle;
  transform: scale(1) translateY(0);
}
.dotLoader .dot1 {
    animation-delay: 0s;
}
.dotLoader .dot2 {
    animation-delay: 0.15s;
}
.dotLoader .dot3 {
    margin-right: 0px;
    animation-delay: 0.30s;
}
@keyframes dotLoaderAnim{
  0%, 80%, 100% {
    opacity: 0;
    transform: scale(1) translateY(0);
  }
  30%, 50% {
      opacity: 1;
      transform: scale(1.5) translateY(-2px);
  }
}
.swal2-container {

    z-index: 9999999!important;

}
/* modal global style starts here */
.cModal p{
  margin: 0;
}
.cModal button{
  outline: none;
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 100%;
}
.cModal button:focus{
  outline: none;
}
.cModal .closeBtn{
  position: absolute;
  left: 24px;
  width: auto;
}
.cModal .closeBtn svg{
  display: block;
}
.cModal .modalLogo{
  max-width: 159px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.cModal .modalLogo img{
  display: block;
  width: 100%;
}
.cModal .modalBgLogo{
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.cModal{
  position: fixed;
  max-height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease-out;
  z-index: 999999;
  padding: 40px;
  visibility: hidden;
}
.cModal .modalWrapper{
  background-color: #fff;
  background-image: url('/assets/images/cModal-gb.png');
  background-size: cover;
  background-position: center;
  max-width: 568px;
  width: 100%;
  max-height: 100%;
  border-radius: 12px;
  padding: 24px 0px 28px;
  position: relative;
  transition: transform .3s ease-out;
  transform: translate(0,-50px);
  display: flex;
  flex-direction: column;
}
.cModal.showModal{
  opacity: 1;
  transition: opacity .15s linear;
  pointer-events: all;
  visibility: visible;
}
.cModal.showModal .modalWrapper{
  transform: none;
}
.cModal .modalHeader,
.cModal .modalBody,
.cModal .modalFooter{
  position: relative;
  z-index: 1;
  padding: 0 24px;
}
.cModal .modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(239, 236, 243, 0.69);
  padding-bottom: 16px;
}
.cModal .modalHeader h3{
  font-family: "Raleway-Bold";
  font-size: 19px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #222222;
  margin: 0;
  cursor: default;
}
.cModal .modalBody{
  padding-top: 32px;
  overflow-y: auto;
}
.cModal .modalBody h2{
  font-family: "Raleway-SemiBold";
  font-size: 24px;
  line-height: 28px;
  color: #222;
  margin: 0 0 28px;
}

.cModal .modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(239, 236, 243, 0.69);
  padding-top: 16px;
}
/* launchProdModal css starts */
.launchProdModal{
  background-color: rgba(0, 0, 0, 0.4);
}
.launchProdModal .modalLogo{
  margin-bottom: 60px;
}
.cModal .modalBgLogo {
    height: auto;
    width: 21.455%;
}
.launchProdModal .modalWrapper{
  max-width: 1100px;
  padding: 70px 0;
  border-radius: 15px;
}
.launchProdModal .modalBody{
  padding: 0 24px;
}
.launchProdModal .inner{
  max-width: 596px;
  width: 100%;
  margin: 0 auto;
}
.launchProdModal .inner h3 {
    font-size: 40px;
    line-height: 47px;
    color: #3A383E;
    font-family: 'Helvetica';
    font-weight: 400;
    margin: 0 0 36px;
}
.launchProdModal .modalBody > .inner p {
    font-size: 16px;
    line-height: 21px;
    color: #9E9E9E;
    font-family: 'Helvetica';
    font-weight: 400;
}
.launchProdModal .joinCommunity {
    display: flex;
    padding: 39px 0 69px;
}
.launchProdModal .gb_inputGroup .gb_input {
    background: #F9F9F9;
    border: 1px solid #e7e9eb;
    border-radius: 3px;
    min-height: 45px;
    padding: 8px 30px;
    display: block;
    font-size: 15px;
    line-height: 18px;
    font-family: "Helvetica";
    font-weight: 400;
    margin-right: 7px;
    height: 100%;
}
.launchProdModal .gb_inputGroup .gb_inputLabel,
.launchProdModal .gb_inputGroup .gb_input::placeholder {
    color: #063088;
    font-style: italic;
}
.launchProdModal .gb_inputGroup .gb_input:focus + .gb_inputLabel{
    color: #063088;
    background: #fff
}
.launchProdModal .gb_inputGroup .gb_input:focus,
.launchProdModal .gb_inputGroup .gb_input:not(:placeholder-shown){
    background: #fff;
}
.launchProdModal .joinCommunity .joinCta{
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    font-family: 'Helvetica';
    font-weight: 700;
    background: #063088;
    border-radius: 3px;
    display: inline-flex;
    width: auto;
    align-items: center;
    justify-content: center;
    padding: 5px 22px;
    transition: 0.2s all ease;
    width: 206px;
    min-height: 45px;
    position: relative;
}
.launchProdModal .joinCommunity .joinCta:hover{
    opacity: 0.85;
}
.launchProdModal .thanyouContent {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.launchProdModal .thanyouContent > img {
    max-width: 300px;
    /* width: 100%; */
}
.launchProdModal .inner .thanyouContent p{
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #3A383E;
}
@media screen and (max-width: 1440px), (max-height: 900px){
  .launchProdModal .modalWrapper {
    max-width: 900px;
    padding: 40px 0;
  }
  .launchProdModal .modalLogo {
    margin-bottom: 30px;
  }
  .launchProdModal .inner h3 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 26px;
}
.launchProdModal .joinCommunity {
    padding: 29px 0 39px;
}
.launchProdModal .gb_inputGroup .gb_input {
    padding: 8px 25px;
}
}
@media screen and (max-width: 575px){
.launchProdModal{
  padding: 30px 20px;
}
.launchProdModal .inner h3 {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 26px;
}
.launchProdModal .joinCommunity {
    padding: 29px 0 39px;
}
.launchProdModal .joinCommunity {
    flex-direction: column;
}
.launchProdModal .gb_inputGroup .gb_input {
    margin-right: 0;
    margin-bottom: 12px;
}
.launchProdModal .joinCommunity .joinCta {
    width: 100%;
}
.launchProdModal .inner .thanyouContent p{
  font-size: 18px;
  line-height: 30px;
}
}
/* launchProdModal css ends */
/* modal global style ends here */
