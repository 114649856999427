table {
    border-collapse: collapse;
}
/*--- Franchise Description CSS Start ---*/
.franchiseDescription-section img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
}
body .franchiseDescription-section .section-title {
  padding: 143px 0 45px;
}
.franchiseDescription-inner {
  background-color: #fff;
  padding: 54px 0 0 0;
}
.franchiseDescription-section .franchiseProduct-img {
  max-width: 100%;
  height: 350px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.franchiseProduct-swiper {
  padding-bottom: 100px;
}
.franchiseProduct-swiper.swiper-container-initialized.swiper-container-horizontal {
  overflow: hidden;
}
/* Apply For Franchisetitle */
.applyForfranchise h3,
.applyForfranchise .section-title h2 {
  font-size: 33px;
  line-height: 38px;
  color: #F6F6F6;
  font-weight: 700;
}
.applyForfranchise {
  padding-bottom: 100px;
  overflow: hidden;
}
.applyForfranchise .section-title.title-bg {
  padding: 94px 0 132px;
  border-radius: 8px;
}
.applyForfranchise  .title-container {
    max-width: 100%;
}


/* Apply For Franchise section */
.applyForfranchise-card {
  padding: 0 50px 0 17px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px 6px 7px 7px;
  display: flex;
  align-items: center;
  max-width: 950px;
  margin: -68px auto 0;
  position: relative;
}
.applyForfranchise-card:after {
    content: "";
    width: 40px;
    height: 40px;
    background-image: url("../images/new/angle-shape.png");
    background-position: center center;
    background-size: 23px;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: -25.5px;
    top: 0px;
}
.applyForfranchise-card-img {
  max-width: 225.38px;
  height: 263.83px;
  width: 100%;
  position: relative;
  transform: scaley(1.05);
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.applyForfranchise-card-img>img{
  height: 100%;
  border-radius: 5px;
}
.franchiseDescription-section .location-icon {
    position: absolute;
    right: 2px;
    bottom: 8px;
    width: 35.14px;
    height: 35.14px;
}
.applyForfranchise-card-wrap {
    padding: 0 23px;
    width: 100%;
    padding: 0 6px 0 23px;
    width: 100%;
}
.applyForfranchise-card-wrap h4,
.applyForfranchise-card-wrap h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
    opacity: 0.8;
    margin-bottom: 15px;
}
.applyForfranchise-card-wrap p {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #828282;
    opacity: 0.8;
    margin-bottom: 3px;
}
.applyForfranchise-card-wrap p span {
     color: #000000;
}
.applyForfranchise-card-wrap p strong {
    font-size: 18px;
    line-height: 20.7px;
    display: flex;
    align-items: center;
}
.applyForfranchise-card-wrap p .strike-through {
    margin-left: 5px;
}
.franchises-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.franchiseDescription-section .franchise-title {
    padding-bottom: 26px;
}
.applyForfranchise-card-wrap .franchise-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.applyForfranchise-card-wrap .map-icon {
    position: relative;
}
.applyForfranchise-card-wrap .map-icon:after {
    content: "";
    width: 26px;
    height: 26px;
    display: inline-block;
    background: rgba(141, 141, 141, 0.1);
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
}

.applyForfranchise-card-header {
    /* max-width: 43.36%; */
    width: 100%;
}

.applyForfranchise-card-wrap .applybtn {
    width: 100%;
    max-width: 56.64%;
    text-align: center;
}

.franchiseDescription-section .applybtn .btn-primary {
    max-width: 350px;
    margin-left: auto;
    text-transform: inherit;
}

.applybtn-mobile {
    display: none;
}

/* applyForfranchiseDetail */

.franchiseDescription-section .sm-bredcrumb.bread-crumb {
    display: none;
}

.franchiseDescription-section .section-title .sm-bredcrumb.bread-crumb li {
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    padding: 26px 2px;
}

.franchiseDescription-section .sm-bredcrumb.bread-crumb li:after {
    color: #fff;
}

.franchiseProduct-swiper .swiper-pagination {
    position: relative;
}

.franchiseDescription-section .swiper-pagination {
    bottom: -35px;
    left: 0;
    width: 100%;
}

.applyForfranchiseDetail {
  background: #F6F7F9;
  margin-top: -290px;
  padding: 390px 0 88px 0;
}
.top-container {
    position: relative;
    z-index: 1;
}

.applyForfranchiseDetail-about h5,
.applyForfranchiseDetail-about h3 {
    font-weight: 700;
    font-size: 33px;
    line-height: 31px;
    letter-spacing: 1px;
    color: #000000;
    margin: 0 0 45px 0;
    text-align: center;
}

.applyForfranchiseDetail-about li {
  display: flex;
}

.applyForfranchiseDetail-about li span {
  max-width: 42%;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding-bottom: 25px;
}

.applyForfranchiseDetail-about li span:first-child {
  font-weight: 400;
  display: inline-block;
}

.applyForfranchiseDetail-about p {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  color: #6B6B6B;
  margin-top: 25px;
}
.applyForfranchiseDetail-about p a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-decoration: underline;
    color: #043899;
}
.applyForfranchiseDetail-about p .hidden {
    display: none;
}

/* investment table */
.investment-table-card {
  background: #FFFFFF;
  box-shadow: 0px 0.5px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  margin: -68px auto 66px;
}

.investment-table-card .investment-table-card-header h3 {
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  color: #FFFFFF;
  background: #06358F;
  mix-blend-mode: normal;
  padding: 23px 0;
  overflow: hidden;
  text-align: left;
  border-radius: 8px 8px 0 0;
}
.investment-table-card .investment-table-card-header h3 span {
  font-size: 14px;
}
.investment-table-card .table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.investment-table-card tbody td,
.investment-table-card thead th {
  border: none;
  padding: 19px 0;
  width: 33.33%;
  text-align: center;
}
.investment-table-card tbody td + td,
.investment-table-card thead th + th {
    border-left: 1px solid #B9D5FF;
}
.investment-table-card tbody td {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #6B6B6B;
}

.investment-table-card thead th {
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  padding: 23px 0;
}

.investment-table-card tbody tr td:first-child,
.investment-table-card thead tr th:first-child {
  border-left: none;
}

.investment-table-card tbody tr:nth-child(odd) {
  background: #F4F6F9;
}

.franchiseDescription-section .note p {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: justify;
    color: #6B6B6B;
    mix-blend-mode: normal;
}

.franchiseDescription-section .note a {
  text-decoration: underline;
  color: #3859A0;
}

.franchiseDescription-section .note p span {
  color: #393964;
  font-style: normal;
  padding-top: 20px;
  display: block;
}

.franchiseDescription-section {
  padding-bottom: 0;
  position: static;
}

/* currentOpportunity */
.franchiseDescription-section .currentOpportunity {
  background: linear-gradient(180deg, #043FA7 -25.1%, #06216B 49.32%);
  padding: 96px 0;
  margin-bottom: 100px;
}

.currentOpportunity .section-heading h3 {
  font-weight: bold;
  font-size: 33px;
  line-height: 38px;
  color: #FFFFFF;
  text-align: center
}

/* Accordion CSS Start */
.franchiseDescription-section .accordion-card {
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  width: calc(100% - 15px);
}
.franchiseDescription-section .accordion-card:after {
    content: "";
    width: 25px;
    height: 25px;
    background-image: url("../images/new/Union.png");
    background-position: center center;
    right: -17px;
    top: 0;
    background-size: 13px;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
}
@media (min-width: 992px) {
    .franchiseDescription-section .accordion-card:hover:after  {
        background-image: url("../images/new/angle-shape-colored.png");
        background-size: 15px;
        right: -15.5px;
        top: 0px;
    }
    .franchiseDescription-section .accordion-card:hover:before {
        content: none;
    }
    .franchiseDescription-section .accordion-card:hover .accordion-header {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        border-top-right-radius: 6px;
    }
    .franchiseDescription-section .accordion-card:hover h4,
    .franchiseDescription-section .accordion-card:hover .fa {
      color: #fff;
    }
}
.franchiseDescription-section .accordion-card.show:after  {
    background-image: url("../images/new/angle-shape-colored.png");
    background-size: 15px;
    right: -15.5px;
    top: 0px;
}
.franchiseDescription-section .currentOpportunity .accordion-card:after{
    content: "";
    width: 25px;
    height: 25px;
    background-image: url("../images/new/angle-shape.png") !important;
    background-position: center center;
    background-size: 15px;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: -16px;
    top: 0;
}
.franchiseDescription-section .accordion-card:before {
    content: "";
    width: 2px;
    height: 21px;
    background-color: #fff;
    right: 0;
    top: 2px;
    display: inline-block;
    position: absolute;
}
.franchiseDescription-section .accordion-card.show:before {
    content: none;
}

.franchiseDescription-section .accordion-header {
  padding: 14px 90px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.franchiseDescription-section .accordion-card .accordion-header {
    border-top-right-radius: 1px;
}

.franchiseDescription-section .accordion-card h4 {
  font-size: 16px;
  line-height: 18px;
  color: #000;
  font-weight: bold;
  padding-right: 10px;
}

.franchiseDescription-section .accordion-body {
  padding: 50px 90px;
  display: none;
}

.franchiseDescription-section .accordion-card p,
.franchiseDescription-section .accordion-card .accordion-body h4 {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  font-weight: normal !important;
  color: #666666 !important;
  mix-blend-mode: normal;
}
.franchiseDescription-section .accordion-card p strong,
.franchiseDescription-section .accordion-card h4 strong {
    color: #06348E;
}
.franchiseDescription-section .accordion-card.show .accordion-header {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  border-radius: 5px 5px 0 0;
}

.franchiseDescription-section .accordion-card.show h4,
.franchiseDescription-section .accordion-card.show .fa {
  color: #fff;
}

.franchiseDescription-section .accordion-card.show .accordion-body {
  background: #F4F6F9;
  border: 0.5px solid #063088;
  border-radius: 0 0 7px 7px;
}
.franchiseDescription-section .accordion-card .accordion-body {
  border: 0.5px solid #063088;
}
.franchiseDescription-section .accordion-card.show .fa-minus {
  display: block !important;
}

.franchiseDescription-section .accordion-card.show .fa-plus {
  display: none !important;
}

/* Acoordian for currentOpportunity */
.franchiseDescription-section .currentOpportunity .accordion-header {
  padding: 14px 90px;
  border: 2px solid #fff;
}
.franchiseDescription-section .currentOpportunity .container,
.franchiseDescription-section .applyForfranchiseDetail .container,
.franchiseDescription-section .table-container {
    max-width: 980px;
}

.franchiseDescription-section .currentOpportunity .accordion-body {
  padding: unset;
  display: none;
}

.franchiseDescription-section .currentOpportunity .accordion-card h4 {
  color: #06348E;
  font-weight: bold;
  line-height: 30px;
}

.franchiseDescription-section .currentOpportunity .accordion-card .accordion-header i {
  color: #06348E;
}

.franchiseDescription-section .currentOpportunity .accordion-card .accordion-header {
  background-color: #fff;
  border-color: #fff;
  border-radius: 2px;
}
.franchiseDescription-section .currentOpportunity .accordion-card.show .accordion-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.franchiseDescription-section .currentOpportunity .accordion-card li {
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    padding: 14px 50px;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    min-width: 25%;
}
.franchiseDescription-section .currentOpportunity .accordion-card li:nth-child(4n + 4) {
    border-right: none;
}
.franchiseDescription-section .currentOpportunity .accordion-card ul {
    display: flex;
    flex-wrap: wrap;
}

.franchiseDescription-section .currentOpportunity .accordion-card.show .accordion-body {
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #fff;
  border-radius: 0 0 2px 2px;
}

.franchiseDescription-section .currentOpportunity .accordion-card+.accordion-card {
  margin-top: 42px;
}

.franchiseDescription-section .currentOpportunity .accordions-outer {
  padding-top: 54px
}

/* Faqs accordian */
.franchiseDescription-section .faqs {
  padding: 10px 0 105px
}


.franchiseDescription-section .faqs h3 {
  text-align: center;
  font-weight: bold;
  font-size: 33px;
  line-height: 38px;
  color: #08083D;
}

.franchiseDescription-section .faqs .accordion-card+.accordion-card {
  margin-top: 42px;
}
.aboutFranchise-table {
    background: #FFFFFF;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 50px;
    width: 100%;
}
.aboutFranchise-table td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6B6B6B;
    padding: 20px 45px;

}
.aboutFranchise-table td:first-child {
    color: #000000;
    border-right: 0.5px solid #B9D5FF;
    width: 30%;
}
.aboutFranchise-table details summary {
    border-bottom: 1px solid #F5F5F5;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    color: #6B6B6B;
    opacity: 0.8;
    margin: 0 -17px 10px -17px;
    padding: 0 10px 8px 10px;
    position: relative;
    outline: none !important;
    box-shadow: none !important;
}
.aboutFranchise-table details {
    border: 1px solid #F5F5F5;
    border-radius: 2px;
    padding: 8px 17px;
    max-width: 150px;
    display: none;
}
.aboutFranchise-table details[close] summary {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.aboutFranchise-table details summary::marker {
    content: none;
}
.aboutFranchise-table details summary::before {
    content: "";
    width: 9px;
    height: 9px;
    border-left: 2px solid #6B6B6B;
    border-bottom: 2px solid #6B6B6B;
    transform: rotate(-45deg);
    display: block;
    transition: 0.3s all ease;
    position: absolute;
    top: 3px;
    right: 19px;
}
.aboutFranchise-table details[open] summary::before {
    transform: rotate(-225deg);
}
.aboutFranchise-table details[open] summary {
    margin: 0 -17px 10px -17px;
    padding: 0 10px 8px 10px;
    border-bottom: 1px solid #F5F5F5;
}
.aboutFranchise-table tr:nth-child(2n + 2) {
    background-color: #F4F6F9;
}
.seeMore-link,
.overview-body .seeMore-link {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-decoration: none;
    color: #043899;
    margin-top: 35px;
    display: inline-block;
}
.seeMore-mobile {
    display: none !important;
}
.seeMore-link:hover span,
.seeMore-link span {
    text-decoration: underline;
}
.seeMore-link svg {
    margin-right: 5px;
    position: relative;
    top: -2px;
    width: 30px;
    height: 20px;
}

.franchiseDescription-section .faqs .section-title {
    padding: 40px 0 60px 0;
    background: linear-gradient(180deg, #043FA7 -25.1%, #062680 61.56%);
    border-radius: 8px;
    text-align: center;
    margin-bottom: 30px;
}
.faqs .faq-icon {
    margin: 0 auto 37px;
    width: 106px;
}
.faqs .section-title h2,
.faqs .section-title h3 {
    font-weight: 500;
    font-size: 33px;
    line-height: 31px;
    letter-spacing: 1px;
    color: #FFFFFF !important;
}
.franchiseDescription-section .faqs .accordions-outer {
    max-width: 950px;
    margin: 0 auto;
}


/*--- Investment Section CSS Start ---*/
.investment-section {
    padding: 0 0 70px 0;
    background-color: #fff;
}
.investment-section .invest-row {
    margin-left: -7.5px;
    margin-right: -7.5px;
    margin-bottom: 70px;
}
.investment-section .invest-row .col {
    max-width: 33.333%;
    flex: 0 0 33.3333%;
    padding-left: 7.5px;
    padding-right: 7.5px;
}
.investment-title  {
    padding: 30px 30px;
    background: #05318C;
    border-radius: 8px;
    color: #F9F9F9;
    height: 195px;
    display: flex;
}
.investment-title .icon {
    display: none;
    align-self: flex-start;
    width: 50px;
    height: 50px;
    margin-left: -10px;
    margin-top: -7px;
    margin-right: 7px;
}
.investment-title-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.investment-title h3 {
    font-weight: 500;
    font-size: 34px;
    line-height: 33px;
    letter-spacing: 1px;
    margin: 0 0 15px 0;
}
.investment-title p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0;
}
.invest-title-desc {
    display: flex;
    justify-content: space-between;
}
.invest-title-desc svg {
    margin-left: 10px;
}
.invest-button {
    position: relative;
    padding: 30px 30px;
    color: #053089;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 3px 10px 15px 10px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    transition: 00.3s all ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 195px;
    border: 1px solid transparent;
}
.view-details {
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.5px;
    text-decoration: underline;
    color: #043899;
    text-align: center;
    display: flex;
    justify-content: center;
    border-top: 1px dashed #C7C7C7;
    padding-top: 14px;
    margin-top: 10px;
}
.invest-price {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.invest-button .svg {
    width: 68px;
    height: 68px;
}
.invest-button .invest-price .gif {
    width: 100px;
    height: 100px;
    position: relative;
    top: -15px;
}
.invest-button:hover .svg .gif {
    display: block;
}
.info-price {
    text-align: right;
}
.invest-button p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    color: #8D8D8D;
}
.invest-button h4 {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: right;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 15px;
}
.price-footer {
    padding: 15px 0 0 0;
    border-top: 1px dashed #C7C7C7;
}
.invest-button  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: right;
    letter-spacing: 0.5px;
    color: #000000;
}

/* Invest Accordion */
.viewInvest-btn {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: right;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: #08083D;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 540px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    transition: 0.2s all ease;
    margin-bottom: 30px;
}
.viewInvest-btn svg {
    margin-left: 20px;
    transform: rotate(0);
    transition: 0.5s all ease;
}
.viewInvest-btn.show svg {
    transform: rotate(180deg);
}

.invest-accordion {
    display: none;
}
.investAccordion-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    align-items: flex-start;
}
.investAccordion-col {
    width: 100%;
    max-width: calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    background-color: #fff;
    padding: 0 35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: column;
}
.invest-accordion h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 39px;
    color: #23CE6B;
    padding: 22px 15px 30px;
    text-align: center;
}
.breakdown-table h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20.364px;
    line-height: 23px;
    color: #000000;
    padding: 15px 30px;
    border: 1px solid #B9D5FF;
}


.breakdownLists h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 8px;
}
.list-items {
    border-bottom: 1px solid #F3F3F3;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.list-items ul {
    padding-top: 8px;
}
.list-items:last-child {
    border-bottom: none;
}
.breakdownLists ul:nth-child(3) {
    border-top: none;
}
.breakdownLists li {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6B6B6B;
    margin-bottom: 15px;
    padding-left: 26px;
    position: relative;
}
.breakdownLists li:last-child {
    margin-bottom: 0;
}
.breakdownLists li:before {
    content: '';
    /* background: url("../images/new/check-icon-green.png") center center no-repeat; */
    content: "\f058";
    font: normal normal normal 18px/1 FontAwesome;
    background-size: 16px 16px !important;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
}
.greenOutlineContent .breakdownLists li:before {
    color: #23CE6B;
}
.blueOutlineContent .breakdownLists li:before {
    color: #063088;
}
.breakdownLists li.cross:before {
    content: "\f057";
    color: #FC0113;
}
.total-footer {
    padding: 15px;
    margin-top: auto;
    width: calc(100% + 70px);
    position: relative;
    left: -35px;
    text-align: center;
    border-radius: 0px 0px 20px 20px;
    margin-top: 25px;
}
.total-footer p {
    font-weight: 700;
    font-size: 20.364px;
    line-height: 23px;
    color: #FFFFFF;
}

/* Take Away */
.greenOutlineContent {
    border-top: 8px solid #23CE6B;;
}
.invest-accordion .greenOutlineContent h2 {
    color: #23CE6B;
}
.greenOutlineContent .total-footer {
    background: #23CE6B;
}

/* Dine In */
.blueOutlineContent {
    border-top: 8px solid #063088;
}
.invest-accordion .blueOutlineContent h2 {
    color: #063088;;
}
.blueOutlineContent .total-footer {
    background: #063088;
}





.breakdown-table th,
.breakdown-table td {
    padding: 20px 25px;
    font-style: normal;
    color: #000000;
}
.breakdown-table th:first-child,
.breakdown-table td:first-child {
    display: none;
}
.breakdown-table th {
    font-weight: 700;
    font-size: 17.3094px;
    line-height: 16px;
    border: 1px solid #B9D5FF;
}
.breakdown-table td {
    font-weight: 400;
    font-size: 16.2912px;
    line-height: 19px;
    color: #6B6B6B;
    border: 1px solid #B9D5FF;
}
.breakdown-table table {
    margin-top: -1px;
}
.breakdown-table table {
    width: 100%;
}



.investment-section .investment-table-card {
    margin: 45px 0 0 0;
    width: 100%;
    box-shadow: none;
}
.investment-table-card.left {
    text-align: left;
}
.investment-table-card.left .investment-table-card-header h3,
.investment-table-card.left tbody td,
.investment-table-card.left thead th {
    text-align: left;
}
.investment-table-card.left tbody td,
.investment-table-card.left thead th {
    padding: 27px 100px;
}
.investment-table-card.left .investment-table-card-header h3 {
    padding-left: 80px;
    padding-right: 80px;
}
.investment-table-card.left tbody td:first-child,
.investment-table-card.left thead th:first-child {
    width: 23%;
    padding-left: 80px;
    padding-right: 80px;
}
.investment-section .investment-table-card tbody td + td,
.investment-section .investment-table-card thead th + th {
    width: 100%;
}
/*--- Investment Section CSS End ---*/

/* Skeleton */
.franchiseDescription .animated-background,
.franchiseDescription .fakeTextLine,
.franchiseDescription .fakeImage {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}
.franchiseDescription .fakeCard {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 10px #00000029;
    padding: 20px;
    border-radius: 10px;
    min-height: 200px;
    display: flex;
}
.franchiseDescription .fakeCard  .fakeImage {
    width: 30%;
    flex: 0 0 30%;
    margin-right: 15px;
    height: 263.83px;
}
.franchiseDescription .fakeCard-right {
    max-width: 70%;
    width: 100%;
    flex: 0 0 70%;
}
.franchiseDescription .fakeCard .fakeTextLine {
  width: calc(100% - 350px);
}
.franchiseDescription .fakeImages {
    display: flex;
}
.franchiseDescription .fakeImage {
  height: 400px;
  width: 33.333%;
}
.franchiseDescription .fakeImage + .fakeImage {
    margin-left: 15px;
}

.franchiseDescription .fakeText {
  width: calc(100% - 350px);
}

.franchiseDescription .fakeTextLine {
  height: 20px;
  width: 100%;
  margin: 0 0 20px;
}

.franchiseDescription.detail-fakeSec {
    width: 100%;
    margin-top: 85px;
    padding-top: 40px;
    display: none;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}


/*--- Similar Franchises Section Start ---*/
.similar-section {
    padding-bottom: 100px;
}
body .similar-section .section-title {
    padding: 85px 0 126px 0;
}
.similar-section .section-title h3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    font-family: "Helvetica";
}
.similar-wrap {
    padding: 40px 50px;
    background: url('../images/new/similar-bg.png') center center no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    margin-top: -67px;
    background-color: #F4F6F9;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}
.similarSlider {
    overflow: hidden;
    padding: 0;
}
.similar-wrap .swiper-button-next,
.similar-wrap .swiper-button-prev {
    background-color: transparent;
    box-shadow: none;
    color: #A4B3D8;
    font-size: 370px;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    outline: none;
    margin: 0;
    width: 16px;
    height: 26px;
}
.similar-wrap .swiper-button-next {
    right: 15px;
    left: auto;
}
.similar-wrap .swiper-button-next:after,
.similar-wrap .swiper-button-prev:after {
    font-size: 24px;
}


.similarFranchise-card {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: 1px;
    color: #000000;
    display: block;
    text-decoration: underline;
    transition: inherit;
    max-width: 221px;
}
.similar-wrap .owl-theme .owl-nav [class*=owl-] {
    font-size: 0px;
    color: #A4B3D8;
    background: transparent!important;
    padding: 0;
    margin: 0;
}
.similar-wrap .owl-theme .owl-nav {
  position: absolute;
  width: 100%;
  left: 0;
  top:34%;
  transform: translate(0 , -34%);
  margin-top: 0;
}
/* .similar-wrap .owl-carousel, .owl-carousel .owl-item {


} */
.similar-wrap .owl-prev, .similar-wrap .owl-next{
    position: absolute;
}
.similar-wrap .owl-prev::before,
.similar-wrap .owl-next::before{
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 40px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.similar-wrap .owl-prev::before{
    content:"\f104";
}
.similar-wrap .owl-next::before{
    content:"\f105";
}
.similar-wrap .owl-prev{
    left: -30px;
}
.similar-wrap .owl-next{
    right:  -30px;
}
.similar-wrap .owl-theme .owl-dots .owl-dot span {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
}
.similar-wrap .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--color-secondary);
    transform: scale(1.5);
}

.similarFranchise-card:hover {
    color: #000000;
    text-decoration: underline;
}
.similarFranchise-card .similar-img {
    height: 225px;
}
.similarFranchise-card .similar-img img {
    height: 100%;
    object-fit: cover;
}

.similarFranchise-card span {
    padding: 20px;
    display: block;
    text-align: center;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
}

.franchiseDescription-section .similar-section .swiper-pagination {
    bottom: 0;
}

/*--- Similar Franchises Section End ---*/

/*--- Inquiry Popup CSS Start ---*/
.inquiryModal-wrap  {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: 0.3s all ease;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 999;
    overflow: auto;
    padding: 15px;
}
.inquiryModal-wrap.show {
    visibility: visible;
    opacity: 1;
    z-index: 9999999;
    overflow: hidden;
}
.inquiryModal-wrap .modal-backdrop {
    touch-action: none !important;
    -ms-touch-action: none !important;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
}

.inquiry-modal {
    max-width: 400px;
    width: 100%;
    position: relative;
    height: 100vh;
    top: 40px;
    transition: 0.3s all ease;
    padding-bottom: 50px;
}
.inquiryModal-wrap.show .inquiry-modal {
    top: 100px;
}
/* .contactCard h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #454056;
    margin-bottom: 8px;
    text-align: center;
    font-style: normal !important;
}
.contactCard p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #666276;
    margin-bottom: 15px;
    font-style: normal !important;
}
.contactCard .search-icon {
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    margin-left: auto;
    position: relative;
    left: 7px;
    z-index: 1;
}
.contactCard .search-icon .animated {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
}
.search-icon .searchInside {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 30px;
    height: 30px;
    z-index: -1;
}
input{
  border: 0;
  outline: 0;
  height: 100%;
}
.w-100{
  width: 100% !important;
}
.text-center{
  text-align: center;
}
.inputWrap{
  position: relative;
  margin-bottom: 13px;
}
.inputOuter {
    position: relative;
    z-index: 1;
}
.inputWrap.icon svg{
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.formItem {
    padding: 5px 45px 5px 16px;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    font-size: 13px;
    line-height: 18px;
    color: #263238;
    font-weight: 400;
    width: 100%;
    min-height: 44px;
    display: block;
    background-color: transparent;
    z-index: 1;
    position: relative;
}
.formItem::placeholder{
    color: #9691A4;
    text-transform: capitalize;
}
.formItem:focus {
    outline: none;
}
.floating-label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #9691A4;
    text-transform: capitalize;
    font-size: 13px!important;
    line-height: 14px;
    transition: 0.2s all ease;
    z-index: -1;
    margin: 0;
}
.focused .floating-label {
    top: 0;
    left: 10px;
    padding: 1px;
    z-index: 2;
    color: #1967D7;
    font-size: 13px;
    line-height: 14px;
    background: #fff;
}
.focused .formItem {
    border: 1px solid #006AFF;
}
 .focused .floating-label span {
     color: #df4d4d;
 }

textarea.formItem {
    min-height: 88px;
    resize: none;
    text-transform: inherit;
    padding: 6px 16px;
}
textarea.formItem::placeholder{
    text-transform: inherit;
}
.is-invalid {
    border-color: #dc3545;
}

.contactCard span.error {
    color: #dc3545;
    font-size: 14px;
    padding: 5px 0 0 5px;
    display: none;
}
.inputWrap.error .error {
    display: block;
}
.inputWrap.error .formItem,.inputWrap.error .floating-label {
    border-color: #dc3545;
    color:#dc3545;
}

.formCta{
    background: #23CE6B;
    border-radius: 8px;
    flex: none;
    order: 5;
    flex-grow: 0;
    padding:9px 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    border: none;
    color: #FFFFFF;
    margin-bottom: 9px;
    text-transform: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactCard {
    width: 100%;
    background: #FFFFFF;
    border: 0.5px solid #EFECF3;
    box-sizing: border-box;
    box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
    border-radius: 12px;
    padding: 35px 30px;
    position: relative;
    max-width: 400px;
}
.franchise-wrap .contactCard {
    padding: 11px 20px;
}
.contactCard .cardTopText {
  font-size: 14px;
  line-height: 21px;
  color: #2E2E2E;
  font-weight: 400;
  margin-bottom: 12px;
  text-align: center;
}
.contactCard .note {
  padding: 10px 16px;
  box-shadow: 0px 2px 2px -2px rgba(31, 27, 45, 0.08), 0px 4px 12px rgba(31, 27, 45, 0.08);
  display: flex;
}
.contactCard .note .searchText{
  flex: 0.9;
  text-align: left;
}
.contactCard .note .searchIcon{
  width: 50px;
  margin-left: 7px;
}
.contactCard .note .searchText p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6B6B6B;
    margin-bottom: 5px;
    text-align: left;
    white-space: nowrap;
}
.contactCard .note .searchText p strong{
  color:black;
  font-size: 13px;
}
.contactCard .note .searchText p span {
    color: var(--color-secondary);
    font-weight: 700;
    display: inline-block;
    padding-top: 0;
}
.contactCard .note .searchText p:first-child {
    font-weight: 700;
}
.contactCard .note .searchText p:last-child{
  margin-bottom: 0px;
} */
.contactDetails{
  max-width: 353px;
  width: 100%;
}

.inquiryModal-cLose {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5;
}

.logo-mob {
    width: 125px;
    height: 25px;
    margin: 0 auto;
    position: relative;
    left: -25px;
    top: -2.5px;
}
.logo-mob svg {
    width: 125px;
    height: 25px;
}
/*--- Inquiry Popup CSS End ---*/


/*--- Miniso New Page Style Start ---*/
body .franchiseNew-wrap .empty-title {
    padding: 105px 0 0 0;
}
.franchise-wrap {
    padding: 25px 0;
    position: relative;
}
@media (min-width: 992px) {
    .franchise-wrap .franchise-right .contactCard {
        position: sticky;
        top: 75px;
    }
}
.shadow-box {
    background: #FFFFFF;
    border: 1px solid #EFECF3;
    box-sizing: border-box;
    box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 30px;
}
.franchise-row {
    display: flex;
}
.franchise-left {
    width: 100%;
    flex: 0 0 67%;
    max-width: 67%;
    padding-right: 30px;
}
.franchise-title h1{
  font-weight: 700;
font-size: 25px;
line-height: 29px;
padding: 0 20px
}
.franchise-right {
    width: 100%;
    flex: 0 0 33%;
    max-width: 33%;
}
.sale-table {
    padding-bottom: 0;
}
.franchise-wrap .bread-crumb {
    margin-bottom: 30px;
}
.shadowBox-head {
    display: flex;
    align-items: center;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #E8E6E6;
}
.head-icon svg,
.head-icon img {
    width: 50px;
}
.shadowBox-head h3,
.shadowBox-head h1,
.shadowBox-head h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    text-transform: capitalize;
    color: #08083D;
}
.franchiseSm-logo {
  width: 58px;
  height: 54px;
  flex: 0 0 58px;
  border-radius: 5px;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgba(164, 164, 164, 0.25));
  margin-left: auto;
  padding: 0;
  background: white;
  text-align: center;
}
.franchiseSm-logo img {
    object-fit: contain;
}
.sale-table table {
    padding: 0 20px;
    width: 100%;
}
.sale-table tr  + tr {
    border-top: 1px solid #F0F0F0;
}
.sale-table td {
    font-style: normal;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    padding: 15px 5px;
}
.sale-table td:first-child {
    font-weight: 400;
}
.sale-table tr {
  display: block;
  width: 100%;
}
.sale-table td{
  display: inline-block;
  width: 50%;
}
.sale-table td:nth-child(2) {
    font-weight: 700;

}
.sale-table td a {
    display: block;
    width: 120px;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 4px;
    color: #3C76F2;
    line-height: 21px;
}
.table-cities {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 180px;
    height: auto;
    line-height: 1.25;
}
.table-cities.show {
    -webkit-line-clamp: inherit;
}
.images-block {
    padding: 15px 15px;
    display: flex;
}
.images-block .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(231px, 231px);
    grid-gap: 15px;
}
.images-block .swiper-slide:nth-child(1) {
    grid-column-end: span 1;
    grid-row-end: span 2;
}
.images-block .img img,
.images-block .img,
.images-block .img-col  {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}
.images-block .img {
    position: relative;
    z-index: 1;
}
.images-mobileSlider {
    overflow: hidden;
}
.overview-body {
    padding: 20px 0 0 0;
}
.overview-body p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    color: #707070;
}
.overview-body p + p {
    margin-top: 15px;
}
.overview-body p span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    transition: 0.3s all ease;
}
.overview-body p span.show {
     -webkit-line-clamp: unset;
       transition: 0.4s all ease;
}
.overview-body a {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #3C76F2;
    text-decoration: underline;
    display: inline-block;
    margin-top: 7px;
}


.estimated-investmentBox .investment-table-card-header {
    background: #06358F;
    padding: 7px 20px;
    border-radius: 7px 7px 0 0;
    display: flex;
    align-items: center;
}
.estimated-investmentBox .investment-table-card-header h3 {
    padding: 0;
}
.estimated-investmentBox .investment-table-card {
    margin: 0 !important;
    background: #FFFFFF;
    /* border: 1px solid #EDEDED; */
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: 20px;
}
.estimated-investmentBox .investment-table-card .table {
    min-width: 710px;
}
.table-scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
/* @supports (-webkit-touch-callout: none) {
          .table-scroll{
                      white-space:nowrap;
                      overflow: scroll;
                      -webkit-overflow-scrolling: touch;
                    }
                  } */
.estimated-investmentBox .investment-table-card  tr {
     border-left:1px solid #EDEDED;
     border-right:1px solid #EDEDED;
}
.estimated-investmentBox .investment-table-card   tr.total {
     border-left:1px solid transparent;
     border-right:1px solid transparent;
}
.info-note{
  margin: 25px 0 30px;
}
.info-note strong{
  color:#043899!important;
}
.estimated-investmentBox .investment-table-card th,
.estimated-investmentBox .investment-table-card td {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #6B6B6B;
    width: 180px;
    padding: 17px 13px;
}
.estimated-investmentBox .investment-table-card tbody td + td,
.estimated-investmentBox .investment-table-card thead th + th {
    border-left: 1px solid #EDEDED;
    width: 15%;
}
.estimated-investmentBox .investment-table-card th {
    font-weight: 700;
    color: #08083D;
    vertical-align: middle;
}
.estimated-investmentBox table {
    margin: 0;
}
.table-note {
    position: relative;
}
.note-inner {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}
.table-note svg {
    margin-right: 5px;
    width: 15px;
    flex: 0 0 15px;
}
.table-note p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.5px;
    color: #747474;
}
.table-note p + p {
    margin-top: 15px;
}
.table-note p a {
    color: #3C76F2;
    text-decoration: underline;
    display: inline-block;
    margin-left: 2px;
    text-decoration: underline;
}
.table-note p strong {
    font-weight: 700;
    color: var(--color-primary);
}
.note-sm {
    padding-top: 20px;
}
.note-desc {
    margin: 0;
    display: block;
    padding-top: 25px;
}
.note-desc li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    position: relative;
    padding-bottom: 13px;
    padding-left: 25px;
}
.note-desc li:last-child {
    padding-bottom: 0;
}
.position-relative{
  position:relative;
}
.view-overlay svg{
  margin-right: 7px;
}

.gallery-desk .img-col .img .view-overlay{
    position: absolute;
        top: 0;
        left:0;
        opacity: 0;
        transition: .2s all ease;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: #080808a1;
        color: white;
        justify-content: center;
        color: white;
        visibility: hidden;
}
.gallery-desk .view-overlay-default {
        position: absolute;
        top: 0;
        left:0;
        opacity: 1;
        transition: .2s all ease;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.48);
        justify-content: center;
        color: white;
        visibility: visible;
        z-index: 3;
}
.gallery-desk .img-col .img:hover .view-overlay {
    visibility: visible;
    opacity: 1;
}
.estimated-investmentBox .investment-table-card tbody  .total td{
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #08083D;
}
.investment-table-card tbody tr.total{
  background: #fff!important;
  border-top: 1px solid #EDEDED;
}
.estimated-investmentBox .investment-table-card tbody .total td + td {
    border-left: 1px solid #fff;
    width: 15%;
}
.estimated-investmentBox .investment-table-card tbody .total td:nth-child(5),
.estimated-investmentBox .investment-table-card tbody .total td:nth-child(6)  {
   background: #F4F6F9;
   border-left: 1px solid #F4F6F9;
   border-bottom: 1px solid #EDEDED;
}
.estimated-investmentBox .investment-table-card tbody .total td:nth-child(5)
{
    border-left:1px solid #ededed;
}
.estimated-investmentBox .investment-table-card tbody .total td:nth-child(6)
{
    border-right:1px solid #ededed;
}
.note-desc li:before{
    content: "";
    border-top: solid 8px transparent;
    border-left: solid 8px #043899;
    border-bottom: solid 8px transparent;
    height: 0;
    width: 0;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 4px;
}


.faqs-block .shadowBox-head {
    border: none;
    padding-bottom: 36px;
}
.faqs-block  .accordion-card + .accordion-card {
    margin-top: 30px;
}
.franchiseDescription-section .faqs-block .accordion-header,
.franchiseDescription-section .faqs-block .accordion-body {
    padding: 20px 15px;
}

.franchiseDescription-inner .strike-through {
    position: relative;
    display: inline-block;
 }
.franchiseDescription-inner .strike-through:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    margin-top: -0.5px;
    background: red;
}
.applyForfranchise-card-wrap p .strike-through + span {
    font-size: 22px;
}
.mrCod-note p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.5px;
    color: #747474;
    margin-bottom: 15px;
}
.mrCod-note p:last-child {
    margin-bottom: 0;
}
.mrCod-note p strong {
    color: #063088;
    font-weight: 700;
}
.mrCod-note p strong{
  color:#08083D;
  font-weight: 700;
}
.mrCod-note p span {
    color: #063088;
    font-weight: 700;
}
.mrCod-note p a {
    color: #3c76f2;
    text-decoration: underline;
}
/*--- Miniso New Page Style End ---*/

/*--- Small Popup CSS Start ---*/
.sm-popUp-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: -1;
    opacity: 0;

}
.sm-popUp-wrap.show {
    visibility: visible;
    z-index: 999;
    opacity: 1;
    transition: 0.3s all ease;
}
.sm-popUp-wrap .modal-backdrop {
    touch-action: none !important;
    -ms-touch-action: none !important;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
}
.sm-popUp {
    border-top: 5px solid #23CE6B;
    background: #fff;
    border-radius: 3px;
    padding: 25px 20px;
    max-width:  350px;
    margin: 0 auto;
    width: 100%;
    min-height: 270px;
    text-align: center;
}
.content-popup-sm {
    max-width: 250px
}
.sm-popUp .icon {
    width: 100px;
    height: 100px;
    background-color: #23CE6B;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.sm-popUp .icon img {
    width: 95px;
}
 .sm-popUp p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 10px;
}
.sm-popUp span {
    font-weight: 300;
    font-size: 15px;
    line-height: 26px;
    color: #333638;
}
.gallery-desk  .play-icon {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #23CE6B;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
}
.gallery-desk  .play-icon:after,
.gallery-desk  .play-icon:before {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgb(35 206 107);
    color: rgba(6, 48, 136, 0.29);
    content: " ";
    height: 100%;
    left: 0;
    opacity: .6;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.gallery-desk   .play-icon:after,
.gallery-desk   .play-icon:before {
    webkit-animation: shadowAnimate 2s infinite;
    animation: shadowAnimate 2s infinite;
}
.gallery-desk  .play-icon:hover:after {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}
.gallery-desk  .play-icon i {
    margin-left: 4px;
    color:white;
    font-size: 27px;
}

@-webkit-keyframes shadowAnimate {
    70% {
        box-shadow: 0 0 0 15px currentColor;
        opacity: 0
    }

    100% {
        box-shadow: 0 0 0 0 currentColor;
        opacity: 0
    }
}

@keyframes shadowAnimate {
    70% {
        box-shadow: 0 0 0 15px currentColor;
        opacity: 0
    }

    100% {
        box-shadow: 0 0 0 0 currentColor;
        opacity: 0
    }
}
/*--- Small Popup CSS End ---*/

/*--- Menu Modal CSS Start ---*/
.menuModal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease;
    min-height: 100% !important;
}
.menuModal-wrap.show {
    visibility: visible;
    opacity: 1;
    z-index: 999;
}
.menuModal-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    max-width: 400px;
    min-height: 200px;
    width: 100%;
    overflow: hidden;
    padding-left: 15px;
}
.menuModal-loader {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.77);
    z-index: 10;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menuModal-loader img {
    width: 100px;
}
.menuModal-box {
    padding-bottom: 50px;
    position: relative;
}
.menuModal-wrap .modal-backdrop {
    touch-action: none !important;
    -ms-touch-action: none !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100% !important;
    width: 100% !important;
}
.menuModal-head {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.menuModal-close {
    cursor: pointer;
}
.modal-img img {
    width: 100%;
}
.menuModal-wrap .swiper-pagination {
    bottom: 12px;
    width: 100%;
    left: 0;
    transform: none;
}
.menuModal-wrap .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    left: 18px;
    top: 0px;
    margin: 0px 6px;
}
.menuModal-wrap .swiper-pagination-bullet-active {
    background: #063088;
}
/*--- Menu Modal CSS End ---*/

/*--- Report Section CSS Start ---*/
.report-form {
    background: #FFFFFF;
    border: 0.5px solid #EFECF3;
    box-sizing: border-box;
    box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
    border-radius: 12px;
    padding: 25px 20px;
    margin: 30px 0;
    border-top: 11px solid #06358F;
}
  .fa-spinner {
    margin-left: 7px;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    font-size: 20px;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.report-section .report-form {
    margin: -78px 0 0 0;
}
.report-form h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.5px;
    color: #08083D;
    display: flex;
    margin-bottom: 10px;
}
.report-form h3 img {
    margin-right: 7px;
    width: 40px;
    position: relative;
    top: -7px;
}
.report-form .report-info {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #707070;
    margin-bottom: 30px;
    padding-left: 4px;
}
.report-form .report-input {
    border: 1px solid #DADCE0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #5F636C;
    background-color: transparent;
    width: 100%;
    z-index: 1;
}
.report-form span.error {
    top: 7px;
    position: relative;
    display: block;
    color: #dc3545;
    font-size: 14px;
    display: none;
}
.report-form .input_wrap.error input {
    border-color: #dc3545 !important;
}
.report-form .input_wrap.error span.error {
    display: block;
}
.report-form .input_wrap.error label {
    color: #dc3545 !important;
}
.report-form .report-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
}
.report-form .report-item {
    max-width: 33.333%;
    flex: 0 0 33.333%;
    padding: 0 6px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
}
.report-form button {
    width: 100%;
    background-color: #043899;
    padding: 18px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-bottom: 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.report-form p {
    font-size: 14px;
    line-height: 1.25;
    text-align: justify;
    letter-spacing: 0.5px;
    color: #8D8D8D;
}
.report-form p span {
    color: var(--color-secondary);
    font-weight: 700;
    font-size: 14px;
}
.report-form p a {
    color: var(--color-secondary);
    text-decoration: underline;
    white-space: nowrap;
}
.report-form button svg {
    margin-right: 10px;
}
/*--- Report Section CSS End ---*/

.input_wrap.error input {
    border-color: #dc3545 !important;
}
.input_wrap.error span.error {
    display: block;
}
.input_wrap.error label {
    color: #dc3545 !important;
}

.btn-strip {
    position: sticky;
    width: 100%;
    z-index: 100;
    bottom: -86px;
    background-color: rgb(246 249 255);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s all ease;
}
.btn-strip.show {
    background-color: var(--color-secondary);
    bottom: 0;
}
.btn-strip .btn-primary {
    width: 100%;
    text-transform: inherit;
    max-width: 200px;
    height: 45px;
}
.btn-strip.show.end {
    bottom: 199px;
}
.images-block .swiper-wrapper .swiper-slide:nth-child(1n + 4){
  display: none;
}


/*--- Bar CSS Start ---*/
.mobile-bar {
    padding:25px 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -2px 4px rgba(113, 113, 113, 0.38);
    z-index: 999;
    width: 100%;
    display: none;
    gap: 10px;
}

.mobile-bar .bar-btn {
    padding: 10px 20px;
    text-align: center;
    width: 20%;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-bar .call {
    background: #06358F;
}
.mobile-bar .whatsapp {
    background: #10BA18;;
}
.mobile-bar .inquiry {
    background: #23CE6B;
    width: 60%;
}
.mobile-bar .mob-animate {
    height: 100%;
    position: absolute;
    top: 0;
    animation: move 2.5s infinite;
}
.mobile-bar .mob-animate:after,
.mobile-bar .mob-animate:before {
    top: 14px;
}
/*--- Bar CSS End ---*/


/* Gallery Modal CSS Start */
.galleryModal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease;
    padding: 50px 70px;
  }
  .galleryModal-wrap .gallery-swiper img{
  object-fit: contain!important;
}
  .galleryModal-wrap .modal-backdrop {
      touch-action: none !important;
      -ms-touch-action: none !important;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.5);
      height: 100vh;
      width: 100%;
  }
  .galleryModal-wrap.show {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  }
  .galleryModal-container{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 40%);
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  }
  .swiper-button-next.gallerySliderPagination {
    top: 40%;
    right: 40px;
    transform: translateY(-40%);
    margin: 0;
  }
  .swiper-button-prev.gallerySliderPagination {
    top: 40%;
    left:40px;
    transform: translateY(-40%);
    margin: 0;
  }

  .galleryModal-head {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    z-index: 11;
    top: 0px;
   left: 0;
    width: 100%;
  }

  .swiper-button-next, .swiper-button-prev {
    background-color: var(--color-secondary);
  }
  .galleryModal-head svg,
  .galleryModal-head svg path  {
    fill:#fff;

  }
  .galleryModal-wrap .swiper {
    width: 100%;
    height: 100%;
  }

  .galleryModal-wrap .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .galleryModal-wrap  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .galleryModal-wrap  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .galleryModal-wrap    .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .galleryModal-wrap  .gallery-swiper {
    height: 80%;
    width: 100%;
  }

  .galleryModal-wrap  .gallery-swiper-thumb {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .galleryModal-wrap  .gallery-swiper-thumb .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    cursor: pointer;
  }

  .galleryModal-wrap  .gallery-swiper-thumb .swiper-slide-thumb-active {
    opacity: 1;
  }

  .galleryModal-wrap  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

 /* Gallery Modal CSS Send */

 /*============ Updates Franchise Page CSS Start ============*/
.estimated-investmentBox {

}
.estimated-investmentBox .invest-row {
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 25px;
}
.estimated-investmentBox .investment-section .col {
    max-width: 50%;
    flex: 0 0 50%;
    padding-left: 6px;
    padding-right: 6px;
}
.estimated-investmentBox .investment-section .estimated-col {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 25px;
}
.estimated-investmentBox  .investment-section .investment-title {
    padding: 16px 15px 20px 15px;
    height: auto;
}
.estimated-investmentBox .investment-title h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 5px;
    color: #FFFFFF;
}
.estimated-investmentBox .investment-title p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}
.estimated-investmentBox .investment-title .icon {
    display: flex;
}
.estimated-investmentBox .invest-title-desc svg {
    margin-left: 5px;
    transform: rotate(90deg);
    width: 14px;
}
.estimated-investmentBox .invest-button {
    min-height: 175px;
    height: auto;
    border: 3px solid transparent;
    border-radius: 8px;
}
.estimated-investmentBox .invest-title-desc {
    justify-content: flex-start;
}
.estimated-investmentBox .invest-button h4 {
    font-size: 22px;
    line-height: 1.25;
    margin-bottom: 10px;
}
.estimated-investmentBox .invest-button p {
    font-size: 18px;
    line-height: 1.25;
}
.estimated-investmentBox  .invest-button h3 {
    font-size: 22px;
    line-height: 1.15;
    text-align: center;
}
.estimated-investmentBox .invest-button .invest-price .gif {
    width: 90px;
    height: 90px;
}
.estimated-investmentBox .invest-accordion {
    display: block;
}
.estimated-investmentBox .investAccordion-col {
    flex: 0 0 100%;
    max-width: 100%;
    display: none;
    box-shadow: none;
}
.estimated-investmentBox .greenOutlineContent {
    border-left: 1px solid #2bff84;
    border-right: 1px solid #2bff84;
}
.estimated-investmentBox .blueOutlineContent {
    border-left: 1px solid #B9D5FF;
    border-right: 1px solid #B9D5FF;
}
.estimated-investmentBox .investAccordion-row {
    overflow: hidden;
}
.estimated-investmentBox .investment-section {
    padding: 0;
}
.estimated-investmentBox .invest-button:after {
    content: "";
    width: 0;
    height: 0;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-top: solid 10px #063088;
    position: absolute;
    bottom: -10px;
    left: 43px;
    opacity: 0;
}
.estimated-investmentBox .greenOutlineButon.active {
    border-color: #23CE6B;
}
.estimated-investmentBox .blueOutlineButton.active {
    border-color: #06358F;
}
.estimated-investmentBox .greenOutlineButon.active:after {
    border-top-color: #23CE6B;
}
.estimated-investmentBox .blueOutlineButton.active:after {
    border-top-color: #06358F;
}
.estimated-investmentBox .invest-button.active:after {
    opacity: 1;
}
.estimated-investmentBox .invest-accordion h2 {
    font-size: 25px;
    line-height: 34px;
    padding: 0;
}
.estimated-investmentBox .investAccordion-head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.estimated-investmentBox .invest-accordion img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.similar-section .section-title h3 {
    font-size: 33px;
    font-weight: 700;
    line-height: 31px;
}
.report-form:last-child {
    margin-bottom: 0;
}
.overview-block h2 {
  padding-left: 3px;
}
/*sprite image positions*/
.cc-picker-flag.zw{
  background-position:0 0
}
.cc-picker-flag.zm{
  background-position:-16px 0
}
.cc-picker-flag.za{
  background-position:0 -11px
}
.cc-picker-flag.yt{
  background-position:-16px -11px
}
.cc-picker-flag.ye{
  background-position:-32px 0
}
.cc-picker-flag.ws{
  background-position:-32px -11px
}
.cc-picker-flag.wf{
  background-position:0 -22px
}
.cc-picker-flag.vu{
  background-position:-32px -22px
}
.cc-picker-flag.vn{
  background-position:0 -33px
}
.cc-picker-flag.vi{
  background-position:-16px -33px
}
.cc-picker-flag.vg{
  background-position:-32px -33px
}
.cc-picker-flag.ve{
  background-position:-48px 0
}
.cc-picker-flag.vc{
  background-position:-48px -11px
}
.cc-picker-flag.va{
  background-position:-48px -22px
}
.cc-picker-flag.uz{
  background-position:-48px -33px
}
.cc-picker-flag.uy{
  background-position:0 -44px
}
.cc-picker-flag.us{
  background-position:-16px -44px
}
.cc-picker-flag.um{
  background-position:-16px -44px
}
.cc-picker-flag.ug{
  background-position:-32px -44px
}
.cc-picker-flag.ua{
  background-position:-48px -44px
}
.cc-picker-flag.tz{
  background-position:-64px 0
}
.cc-picker-flag.tw{
  background-position:-64px -11px
}
.cc-picker-flag.tv{
  background-position:-64px -22px
}
.cc-picker-flag.tt{
  background-position:-64px -33px
}
.cc-picker-flag.tr{
  background-position:-64px -44px
}
.cc-picker-flag.to{
  background-position:0 -55px
}
.cc-picker-flag.tn{
  background-position:-16px -55px
}
.cc-picker-flag.tm{
  background-position:-32px -55px
}
.cc-picker-flag.tl{
  background-position:-48px -55px
}
.cc-picker-flag.tk{
  background-position:-64px -55px
}
.cc-picker-flag.tj{
  background-position:0 -66px
}
.cc-picker-flag.th{
  background-position:-16px -66px
}
.cc-picker-flag.tg{
  background-position:-32px -66px
}
.cc-picker-flag.tf{
  background-position:-48px -66px
}
.cc-picker-flag.td{
  background-position:-64px -66px
}
.cc-picker-flag.tc{
  background-position:-80px 0
}
.cc-picker-flag.sz{
  background-position:-80px -11px
}
.cc-picker-flag.sy{
  background-position:-80px -22px
}
.cc-picker-flag.sx{
  background-position:-80px -33px
}
.cc-picker-flag.sv{
  background-position:-80px -44px
}
.cc-picker-flag.st{
  background-position:-80px -55px
}
.cc-picker-flag.ss{
  background-position:-80px -66px
}
.cc-picker-flag.sr{
  background-position:0 -77px
}
.cc-picker-flag.so{
  background-position:-16px -77px
}
.cc-picker-flag.sn{
  background-position:-32px -77px
}
.cc-picker-flag.sm{
  background-position:-48px -77px
}
.cc-picker-flag.sl{
  background-position:-64px -77px
}
.cc-picker-flag.sk{
  background-position:-80px -77px
}
.cc-picker-flag.si{
  background-position:-96px 0
}
.cc-picker-flag.sh{
  background-position:-96px -11px
}
.cc-picker-flag.sg{
  background-position:-96px -22px
}
.cc-picker-flag.se{
  background-position:-96px -33px
}
.cc-picker-flag.sd{
  background-position:-96px -44px
}
.cc-picker-flag.sc{
  background-position:-96px -66px
}
.cc-picker-flag.sb{
  background-position:-96px -77px
}
.cc-picker-flag.sa{
  background-position:0 -88px
}
.cc-picker-flag.rw{
  background-position:-16px -88px
}
.cc-picker-flag.ru{
  background-position:-32px -88px
}
.cc-picker-flag.rs{
  background-position:-48px -88px
}
.cc-picker-flag.ro{
  background-position:-64px -88px
}
.cc-picker-flag.qa{
  background-position:-80px -88px
}
.cc-picker-flag.py{
  background-position:-96px -88px
}
.cc-picker-flag.pw{
  background-position:0 -99px
}
.cc-picker-flag.pt{
  background-position:-16px -99px
}
.cc-picker-flag.ps{
  background-position:-32px -99px
}
.cc-picker-flag.pr{
  background-position:-48px -99px
}
.cc-picker-flag.pn{
  background-position:-64px -99px
}
.cc-picker-flag.pm{
  background-position:-80px -99px
}
.cc-picker-flag.pl{
  background-position:-96px -99px
}
.cc-picker-flag.pk{
  background-position:-112px 0
}
.cc-picker-flag.ph{
  background-position:-112px -11px
}
.cc-picker-flag.pg{
  background-position:-112px -22px
}
.cc-picker-flag.pf{
  background-position:-112px -33px
}
.cc-picker-flag.pe{
  background-position:-112px -44px
}
.cc-picker-flag.pa{
  background-position:-112px -55px
}
.cc-picker-flag.om{
  background-position:-112px -66px
}
.cc-picker-flag.nz{
  background-position:-112px -77px
}
.cc-picker-flag.nu{
  background-position:-112px -88px
}
.cc-picker-flag.nr{
  background-position:-112px -99px
}
.cc-picker-flag.no{
  background-position:0 -110px
}
.cc-picker-flag.bv{
  background-position:0 -110px
}
.cc-picker-flag.sj{
  background-position:0 -110px
}
.cc-picker-flag.nl{
  background-position:-16px -110px
}
.cc-picker-flag.ni{
  background-position:-32px -110px
}
.cc-picker-flag.ng{
  background-position:-48px -110px
}
.cc-picker-flag.nf{
  background-position:-64px -110px
}
.cc-picker-flag.ne{
  background-position:-80px -110px
}
.cc-picker-flag.nc{
  background-position:-96px -110px
}
.cc-picker-flag.na{
  background-position:-112px -110px
}
.cc-picker-flag.mz{
  background-position:-128px 0
}
.cc-picker-flag.my{
  background-position:-128px -11px
}
.cc-picker-flag.mx{
  background-position:-128px -22px
}
.cc-picker-flag.mw{
  background-position:-128px -33px
}
.cc-picker-flag.mv{
  background-position:-128px -44px
}
.cc-picker-flag.mu{
  background-position:-128px -55px
}
.cc-picker-flag.mt{
  background-position:-128px -66px
}
.cc-picker-flag.ms{
  background-position:-128px -77px
}
.cc-picker-flag.mr{
  background-position:-128px -88px
}
.cc-picker-flag.mq{
  background-position:-128px -99px
}
.cc-picker-flag.mp{
  background-position:-128px -110px
}
.cc-picker-flag.mo{
  background-position:0 -121px
}
.cc-picker-flag.mn{
  background-position:-16px -121px
}
.cc-picker-flag.mm{
  background-position:-32px -121px
}
.cc-picker-flag.ml{
  background-position:-48px -121px
}
.cc-picker-flag.mk{
  background-position:-64px -121px
}
.cc-picker-flag.mh{
  background-position:-80px -121px
}
.cc-picker-flag.mg{
  background-position:-96px -121px
}
.cc-picker-flag.me{
  background-position:0 -132px
}
.cc-picker-flag.md{
  background-position:-112px -121px
}
.cc-picker-flag.mc{
  background-position:-128px -121px
}
.cc-picker-flag.ma{
  background-position:-16px -132px
}
.cc-picker-flag.ly{
  background-position:-32px -132px
}
.cc-picker-flag.lv{
  background-position:-48px -132px
}
.cc-picker-flag.lu{
  background-position:-64px -132px
}
.cc-picker-flag.lt{
  background-position:-80px -132px
}
.cc-picker-flag.ls{
  background-position:-96px -132px
}
.cc-picker-flag.lr{
  background-position:-112px -132px
}
.cc-picker-flag.lk{
  background-position:-128px -132px
}
.cc-picker-flag.li{
  background-position:-144px 0
}
.cc-picker-flag.lc{
  background-position:-144px -11px
}
.cc-picker-flag.lb{
  background-position:-144px -22px
}
.cc-picker-flag.la{
  background-position:-144px -33px
}
.cc-picker-flag.kz{
  background-position:-144px -44px
}
.cc-picker-flag.ky{
  background-position:-144px -55px
}
.cc-picker-flag.kw{
  background-position:-144px -66px
}
.cc-picker-flag.kr{
  background-position:-144px -77px
}
.cc-picker-flag.kp{
  background-position:-144px -88px
}
.cc-picker-flag.kn{
  background-position:-144px -99px
}
.cc-picker-flag.km{
  background-position:-144px -110px
}
.cc-picker-flag.ki{
  background-position:-144px -121px
}
.cc-picker-flag.kh{
  background-position:-144px -132px
}
.cc-picker-flag.kg{
  background-position:0 -144px
}
.cc-picker-flag.ke{
  background-position:-16px -144px
}
.cc-picker-flag.jp{
  background-position:-32px -144px
}
.cc-picker-flag.jo{
  background-position:-48px -144px
}
.cc-picker-flag.jm{
  background-position:-64px -144px
}
.cc-picker-flag.je{
  background-position:-80px -144px
}
.cc-picker-flag.it{
  background-position:-96px -144px
}
.cc-picker-flag.is{
  background-position:-112px -144px
}
.cc-picker-flag.ir{
  background-position:-128px -144px
}
.cc-picker-flag.iq{
  background-position:-144px -144px
}
.cc-picker-flag.io{
  background-position:-160px 0
}
.cc-picker-flag.in{
  background-position:-160px -11px
}
.cc-picker-flag.im{
  background-position:-160px -22px
}
.cc-picker-flag.il{
  background-position:-160px -31px
}
.cc-picker-flag.ie{
  background-position:-160px -42px
}
.cc-picker-flag.id{
  background-position:-160px -53px
}
.cc-picker-flag.hu{
  background-position:-160px -64px
}
.cc-picker-flag.ht{
  background-position:-160px -75px
}
.cc-picker-flag.hr{
  background-position:-160px -86px
}
.cc-picker-flag.hn{
  background-position:-160px -97px
}
.cc-picker-flag.hk{
  background-position:-160px -108px
}
.cc-picker-flag.gy{
  background-position:-160px -119px
}
.cc-picker-flag.gw{
  background-position:-160px -130px
}
.cc-picker-flag.gu{
  background-position:-160px -141px
}
.cc-picker-flag.gt{
  background-position:0 -155px
}
.cc-picker-flag.gs{
  background-position:-16px -155px
}
.cc-picker-flag.gr{
  background-position:-32px -155px
}
.cc-picker-flag.gq{
  background-position:-48px -155px
}
.cc-picker-flag.gp{
  background-position:-64px -155px
}
.cc-picker-flag.gn{
  background-position:-80px -155px
}
.cc-picker-flag.gm{
  background-position:-96px -155px
}
.cc-picker-flag.gl{
  background-position:-112px -155px
}
.cc-picker-flag.gi{
  background-position:-128px -155px
}
.cc-picker-flag.gh{
  background-position:-144px -155px
}
.cc-picker-flag.gg{
  background-position:-160px -155px
}
.cc-picker-flag.ge{
  background-position:-176px 0
}
.cc-picker-flag.gd{
  background-position:-176px -11px
}
.cc-picker-flag.gb{
  background-position:-176px -22px
}
.cc-picker-flag.ga{
  background-position:-176px -33px
}
.cc-picker-flag.fr{
  background-position:-176px -44px
}
.cc-picker-flag.gf{
  background-position:-176px -44px
}
.cc-picker-flag.re{
  background-position:-176px -44px
}
.cc-picker-flag.mf{
  background-position:-176px -44px
}
.cc-picker-flag.bl{
  background-position:-176px -44px
}
.cc-picker-flag.fo{
  background-position:-176px -55px
}
.cc-picker-flag.fm{
  background-position:-176px -66px
}
.cc-picker-flag.fk{
  background-position:-176px -77px
}
.cc-picker-flag.fj{
  background-position:-176px -88px
}
.cc-picker-flag.fi{
  background-position:-176px -99px
}
.cc-picker-flag.eu{
  background-position:-176px -121px
}
.cc-picker-flag.et{
  background-position:-176px -132px
}
.cc-picker-flag.es{
  background-position:-176px -143px
}
.cc-picker-flag.er{
  background-position:-176px -154px
}
.cc-picker-flag.eh{
  background-position:-16px -166px
}
.cc-picker-flag.eg{
  background-position:-32px -166px
}
div.cc-picker-flag.ee{
  background-position:-48px -166px
}
.cc-picker-flag.ec{
  background-position:-64px -166px
}
.cc-picker-flag.dz{
  background-position:-80px -166px
}
.cc-picker-flag.do{
  background-position:-96px -166px
}
.cc-picker-flag.dm{
  background-position:-112px -166px
}
.cc-picker-flag.dk{
  background-position:-128px -166px
}
.cc-picker-flag.dj{
  background-position:-144px -166px
}
.cc-picker-flag.de{
  background-position:-160px -166px
}
.cc-picker-flag.cz{
  background-position:-176px -166px
}
.cc-picker-flag.cy{
  background-position:0 -177px
}
.cc-picker-flag.cx{
  background-position:-16px -177px
}
.cc-picker-flag.cw{
  background-position:-32px -177px
}
.cc-picker-flag.cv{
  background-position:-48px -177px
}
.cc-picker-flag.cu{
  background-position:-64px -177px
}
.cc-picker-flag.cs{
  background-position:-80px -177px
}
.cc-picker-flag.cr{
  background-position:-96px -177px
}
.cc-picker-flag.co{
  background-position:-112px -177px
}
.cc-picker-flag.cn{
  background-position:-128px -177px
}
.cc-picker-flag.cm{
  background-position:-144px -177px
}
.cc-picker-flag.cl{
  background-position:-160px -177px
}
.cc-picker-flag.ck{
  background-position:-176px -177px
}
.cc-picker-flag.ci{
  background-position:-192px 0
}
.cc-picker-flag.cg{
  background-position:-192px -11px
}
.cc-picker-flag.cf{
  background-position:-192px -22px
}
.cc-picker-flag.cd{
  background-position:-192px -33px
}
.cc-picker-flag.cc{
  background-position:-192px -44px
}
.cc-picker-flag.ca{
  background-position:-192px -66px
}
.cc-picker-flag.bz{
  background-position:-192px -77px
}
.cc-picker-flag.by{
  background-position:-192px -88px
}
.cc-picker-flag.bw{
  background-position:-192px -99px
}
.cc-picker-flag.bt{
  background-position:-192px -110px
}
.cc-picker-flag.bs{
  background-position:-192px -121px
}
.cc-picker-flag.br{
  background-position:-192px -132px
}
.cc-picker-flag.bq{
  background-position:-192px -143px
}
.cc-picker-flag.bo{
  background-position:-192px -154px
}
.cc-picker-flag.bn{
  background-position:-192px -165px
}
.cc-picker-flag.bm{
  background-position:-192px -176px
}
.cc-picker-flag.bj{
  background-position:0 -188px
}
.cc-picker-flag.bi{
  background-position:-16px -188px
}
.cc-picker-flag.bh{
  background-position:-32px -188px
}
.cc-picker-flag.bg{
  background-position:-48px -188px
}
.cc-picker-flag.bf{
  background-position:-64px -188px
}
.cc-picker-flag.be{
  background-position:-80px -188px
}
.cc-picker-flag.bd{
  background-position:-96px -188px
}
.cc-picker-flag.bb{
  background-position:-112px -188px
}
.cc-picker-flag.ba{
  background-position:-128px -188px
}
.cc-picker-flag.az{
  background-position:-144px -188px
}
.cc-picker-flag.ax{
  background-position:-160px -188px
}
.cc-picker-flag.aw{
  background-position:-176px -188px
}
.cc-picker-flag.au{
  background-position:-192px -188px
}
.cc-picker-flag.hm{
  background-position:-192px -188px
}
.cc-picker-flag.at{
  background-position:-208px 0
}
.cc-picker-flag.as{
  background-position:-208px -11px
}
.cc-picker-flag.ar{
  background-position:-208px -22px
}
.cc-picker-flag.ao{
  background-position:-208px -33px
}
.cc-picker-flag.an{
  background-position:-208px -44px
}
.cc-picker-flag.am{
  background-position:-208px -55px
}
.cc-picker-flag.al{
  background-position:-208px -66px
}
.cc-picker-flag.ai{
  background-position:-208px -77px
}
.cc-picker-flag.ag{
  background-position:-208px -88px
}
.cc-picker-flag.af{
  background-position:-208px -99px
}
.cc-picker-flag.ae{
  background-position:-208px -110px
}
.cc-picker-flag.ad{
  background-position:-208px -121px
}
.cc-picker-flag.np{
  background-position:-208px -132px
}
.cc-picker-flag.ch{
  background-position:-208px -143px
}

.cc-picker-flag  {
  width: 16px;
  height: 11px;
  margin-right: 5px;
  display: inline-block;
  /*itti-img.png'*/
  background: url('../images/itti-img.png');
  position: relative;
  top: 5px;
  padding-left: 12px;
  font-weight: 700;
  margin: 5px 0;
}
.countryCodeSelect-report ng-select {
  width: 38%;
    left: 1px;
    display: inline-block;
    position: absolute;
    z-index: 11;
    height: 100%;
}
body .report-form .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}
.countryCodeSelect-report .ng-select .ng-select-container {
    color: #333;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 1px solid transparent;
    min-height: 36px;
    align-items: center;
}

.report-form .country-phone{
  padding-left: 109px!important;
}
.report-form .phone-input .floating-label {
  left: 115px;
}
.report-form .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  display: flex;
  gap: 9px;
  position: relative;
}
  /* .contactCard .phone-input.focused .floating-label,
  .contactCard .phone-input input:focus + .floating-label{
    left: 15px;
    z-index: 11111111;
  } */
.countryCodeSelect-report{
  position: relative;
  display: flex;
}
.report-form .input_wrap .countryCodeSelect-report ng-dropdown-panel label{
position: unset!important;
padding: unset!important;
}

.countryCodeSelect-report ng-dropdown-panel {
  width: 100% !important;
  z-index: 99999;
  top: 57px!important;
}
.report-form .form-group.error .ng-select .ng-select-container {
      border: 1px solid  #b93d3d!important;
}
.report-form  .ng-select:focus,
.report-form  .ng-select:focus-visible{
  border-color: #E1E1E1!important;
  outline: none!important;
}
.report-form.form-group .ng-select .ng-select-container {
      border: 1px solid transparent!important;
      font-size: 13px;
      background-color: #F5F5F5;
      line-height: 18px;
      color: #263238;
      font-weight: 400;
      width: 100%;
      min-height: 42px;
      border-radius: 8px 0px 0px 8px;
}
.report-form .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #000 transparent transparent;

}
.report-form .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    border-color: transparent transparent #020202!important;
}
.report-form .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 10px;
}
.report-form .ng-select .ng-arrow-wrapper {
    padding-right: 13px;
    top:3px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: unset;
    text-overflow: unset;
}
.report-form .ng-select .ng-clear-wrapper {
   display: none;
}
.report-form .ng-dropdown-panel.ng-select-bottom {
    min-width: 100%!important;
}

.report-form .ng-select.ng-select-single .ng-select-container {
    height: 5px;
    height: 54px;
top: 1px;
left: 1px;
  border-radius: 3px 0 0 3px;
  padding: 0 6px;

}

.report-form .countryCodeSelect-report ng-select .ng-value{
   display:flex;
   align-items: center;
}
.report-form .countryCodeSelect-report ng-select .ng-value label{
  position: unset!important;
  padding: unset!important;
  margin-bottom: 0;
}
.report-form .input_wrap.conuntry-dial-lable label {

    left: 91px!important;

}
.countryCodeSelect-report .ng-input {
  padding-right: 0px !important;
}
.countryCodeSelect-report .ng-select .ng-arrow-wrapper {
  width: 15px;
  padding-right: 0;
}
.countryCodeSelect-report .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {

    padding-left: unset;

}
.countryCodeSelect-report b {
  font-size: 15px;
  font-weight: 400;
  padding-left: 7px;
  color:#000;
}
.countryCodeSelect-report .ng-select .ng-select-container .ng-value-container{
  padding-left: 0px;
}


/*============ Updates Franchise Page CSS Start ============*/


@media only screen and (max-width: 1199px) {
    .investment-title h3 {
        font-size: 30px;
    }
}


@media only screen and (max-width: 991px) {
    .applyForfranchise-card-wrap .map-icon {
        margin-right: 20px;
    }
    .report-form .ng-select.ng-select-single .ng-select-container {
        height: 49px;
    }
    .report-form .input_wrap.conuntry-dial-lable label {
        left: 98px!important;
    }
    .report-form #phone {
        padding-left: 103px!important;
        padding-right: 8px;
    }
    .additional-info .add-detail h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
        color: #08083D;
    }
    .countryCodeSelect-report ng-select {
        width: 92px;
    }
    .report-form .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        top: -1px;
    }
    .estimated-investmentBox .investment-table-card-header {
      padding: 7px 18px;
    }
    .overview-block h2 {
      padding-left: 6px;
    }
    .estimated-investmentBox .investment-section .investment-title {
      padding: 16px 11px 18px 11px;
    }
    .franchise-title h1 {
      font-weight: 700;
      font-size: 18px;
      line-height: 29px;
      padding: 0 20px;
    }
      .shadowBox-head h3,
      .shadowBox-head h1,
      .shadowBox-head h2 {
      font-size: 18px;
      line-height: 21px;
      }
      .overview-block .head-icon svg,
      .overview-block .head-icon img {
        width: 80px;
        margin-top: 4px;
      }
      .franchise-left .franchise-title{
      order:-1;
      padding-bottom: 18px;
      }
      .shadowBox-head {
      align-items: baseline;
      }
    .franchiseDescription-section .faqs-block .accordion-header,
    .franchiseDescription-section .faqs-block .accordion-body {
        padding: 20px 12px;
    }
    .total-footer p {

    font-size: 16px;

}
    .btn-strip {
      display: none;
    }
    textarea.formItem {
        min-height: 90px;

    }
    .img-col .img .view-overlay{
      display: none !important;
    }
    .images-block .swiper-wrapper .swiper-slide:nth-child(1n + 4){
      display: block;
    }
    .franchiseDescription-section .title-none {
        display: none;
    }
    .franchiseDescription-section .bread-crumb {
        display: none;
    }
    .franchiseDescription-section .lg-show{
        display: none;
    }
    .franchiseDescription-section .sm-bredcrumb.bread-crumb {
        display: none;
    }
    .franchiseDescription-section .sm-bredcrumb.bread-crumb {
        margin-bottom: 0px;
    }
    .franchiseDescription-section .section-title.sm-bredcrumb.bread-crumb li {
        display: block !important;
    }
    body .franchiseDescription-section>.section-title {
        padding: 24px 0 55px;
    }
    .franchiseDescription-section .section-title.title-bg h2,
    .faqs .section-title h3 {
        font-size: 20px;
        line-height: 23px;
    }
    .franchiseDescription-section .franchiseDescription-inner {
        padding: 24px 0 0 0;
    }
    .franchiseDescription-section .breadcrumb {
        display: none;
    }
    .franchiseDescription-inner>.container {
        padding-left: 0;
        padding-right: 0;
    }
    .franchiseProduct-swiper {
        padding: 0 0 50px 0;
        margin: -30px 0 -80px 0;
    }
    .franchiseProduct-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active,
    .franchiseProduct-swiper .swiper-pagination-bullet:before {
        background: var(--color-success) !important;
    }
    .franchiseDescription-section .swiper-pagination {
        bottom: -20px;
    }
    .franchiseDescription-section .franchiseProduct-img {
        border-radius: 0;
    }
    .franchiseDescription-section .applyForfranchise {
        padding-bottom: 52px;
    }
    .franchiseDescription-section .applyForfranchise .section-title {
        text-align: left;
        margin-bottom: 38px;
    }
    .franchiseDescription-section .applyForfranchise-card {
        margin: -95px 30px 0;
        flex-direction: row-reverse;
        padding: 20px;
        align-items: flex-start;
    }
    .franchiseDescription-section .applyForfranchise-card-wrap {
        padding: 0 0px;
    }
    .franchiseDescription-section .applyForfranchise-card-wrap .applybtn {
        display: none;
    }
    .applybtn-mobile {
        display: block;
        margin: 0 !important;
        max-width: 100%;
        position: fixed;
        width: calc(100% - 60px);
        left: 30px;
        bottom: 30px;
        z-index: 9999999999;
    }
    .mob-animate:before {
        content: "";
        width: 0px;
        height: 59px;
        display: block;
        position: absolute;
        border: 10px solid white;
        opacity: 0.4;
        z-index: 3;
        transform: skew(-32deg, 10deg);
        left: 0px;
        top: -30px;
    }
    .mob-animate:after {
        content: "";
        width: 0px;
        height: 59px;
        display: block;
        position: absolute;
        border: 7px solid white;
        opacity: 0.4;
        z-index: 3;
        transform: skew(-33deg, 14deg);
        left: 33px;
        top: -29px;
    }
    .mob-animate {
        position: relative;
        animation: move 1.9s infinite;
        display: block;
        width: 100%;
    }
     @keyframes move {
       0% {left: -10%;}
       100% {left: 110%;}
     }
    .franchiseDescription-section .applybtn-mobile a {
        margin: 0;
        max-width: 100%;
        text-transform: inherit;
        overflow: hidden;
    }
    .btn-text{
      width:100%;
      position: absolute;
      right: 0;
    }
    .franchiseDescription-section .applyForfranchiseDetail {
        margin-top: 0;
        padding: 27px 0 126px 0;
    }
    .franchiseDescription-section .contentContainer {
        margin: 0 0;
        padding: 0 18px;
    }
    .franchiseDescription-section .applyForfranchise .section-title.title-bg {
        padding: 75px 16px 132px;
        border-radius: 0px;
    }
    .franchiseDescription-section .applyForfranchise-card-header {
        max-width: 100.36%;
        width: 100%;
    }
    .franchiseDescription-section .applyForfranchise h3,
    .applyForfranchise .section-title h2 {
        font-size: 25px;
        line-height: 1.18;
        text-align: center;
    }
    .franchiseDescription-section .applyForfranchise-card-img {
        max-width: 77px;
        height: 85px;
        width: 100%;
        position: relative;
        transform: scaley(1.05);
    }
    .franchiseDescription-section .applyForfranchise-card-img .location-icon {
        right: 0px;
        bottom: 1px;
        width: 15.14px;
        height: 15.14px;
    }
    .franchiseDescription-section .px-sm-0 {
        padding: 0 0;
    }
    .franchiseDescription-section .applyForfranchise-card-wrap h4,
    .applyForfranchise-card-wrap h3 {
        font-size: 18px;
        line-height: 21px;
    }
    .franchiseDescription-section .applyForfranchise-card-wrap p {
        font-size: 14px;
        line-height: 16px;
    }
    .franchiseDescription-section .applyForfranchise-card-wrap p {
        font-size: 14px;
        line-height: 16px;
    }
    .franchiseDescription-section .applyForfranchise-card-wrap p strong {
        font-size: 15px;
        line-height: 21.7px;
    }
    .franchiseDescription-section .applyForfranchiseDetail-about h5,
    .applyForfranchiseDetail-about h3 {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 35px;
    }
    .franchiseDescription-section .applyForfranchiseDetail-about li span {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 18px;
    }
    .franchiseDescription-section .applyForfranchiseDetail-about p {
        font-size: 14px;
        line-height: 1.3;
        margin-top: 10px;
        color: #000;
    }
    .franchiseDescription-section .investment-table-card .investment-table-card-header h3 {
        font-size: 17px;
        line-height: 20px;
    }
    .franchiseDescription-section .investment-table-card thead th,
    .franchiseDescription-section .investment-table-card tbody td {
        font-size: 14px;
        line-height: 16px;
    }
    .view-details {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 11px;
        color: #043899;
    }
    .franchiseDescription-section .note p {
        font-size: 12px;
        line-height: 17px;
        font-weight: 400;
    }
    .franchiseDescription-section .investment-table-card {
        margin: -41px auto 35px;
    }
    .franchiseDescription-section .applyForfranchiseDetail-about li span:first-child {
        max-width: 265px;
    }
    .franchiseDescription-section .accordions-outer {
        padding: 0 20px 0 20px;
    }
    .franchiseDescription-section .currentOpportunity .accordions-outer
    .franchiseDescription-section .currentOpportunity {
        padding: 43px 0 113px;
    }
    .franchiseDescription-section .currentOpportunity .section-heading h3 {
        font-size: 25px;
        line-height: 29px;
        text-align: center;
        padding: 0 30px;
    }
    .franchiseDescription-section .currentOpportunity .accordion-header {
        padding: 14px 39px;
    }
    .franchiseDescription-section .currentOpportunity .accordion-card+.accordion-card {
        margin-top: 45px;
    }
    .franchiseDescription-section .currentOpportunity .accordion-card li {
        padding: 14px 39px;
    }
    .franchiseDescription-section .accordion-body {
        padding: 32px 25px;
        display: none;
    }
    .franchiseDescription-section .faqs {
        padding: 10px 0 62px;
    }
    .franchiseDescription-section .accordion-header {
        padding: 14px 25px;
    }
    .franchiseDescription .fakeImage {
        height: 170px;
    }
    .franchiseDescription-section {
        padding-bottom: 0;
    }
    .aboutFranchise-table td {
        font-size: 14px;
        padding: 20px 15px;
        border-right: none;
    }
    .aboutFranchise-table td:first-child {
        width: auto;
        border-right: none;
    }
    table td .cities {
        display: none;
    }
    .aboutFranchise-table details  {
        display: block;
    }
    .seeMore-link svg {
        margin-right: 7px;
        width: 30px;
        height: 20px;
        top: -3px;
    }
    .franchiseDescription-section .applyForfranchiseDetail.mrCod {
        padding: 20px 0;
    }
    .franchiseDescription-section .applyForfranchiseDetail {
        margin-top: 40px;
    }
    .investment-section {
        padding: 18px 15px;
        background: #FFFFFF;
        border: 0.5px solid #EFECF3;
        box-sizing: border-box;
        box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
        border-radius: 12px;
        margin: 0 15px;
        margin-bottom: 30px;
    }
    .investment-section .invest-row .col {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .investment-section .invest-row .col:nth-child(1) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .invest-title-desc svg {
        transform: rotate(90deg);
        margin-left: 5px;
        margin-top: -2px;
        width: 12px;
    }
    .investment-title h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 5px;
    }
    .investment-title p {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }
    .investment-title {
        height: auto;
        padding: 13px;
        background: #06358F;
        border-radius: 2px;
    }
    .investment-title .icon {
        display: block;
    }
    .invest-title-desc {
        justify-content: flex-start;
    }
    .invest-button {
        padding: 20px 18px;
        min-height: 190px;
        height: auto;
        box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
        border-radius: 4px;
        border: 2px solid transparent;
    }
    .investAccordion-row {
        flex-direction: column;
    }
    /* .greenOutlineButon.active {
        background: rgba(49, 209, 116, 0.1);
        border: 1px solid #B9D5FF;
        box-sizing: border-box;
        box-shadow: 3px 10px 15px 10px rgba(0, 0, 0, 0.04);
        border-color: #06358F;
    } */
    /* .blueOutlineButton.active {
        background: rgba(61, 123, 253, 0.15);
        border: 1px solid #B9D5FF;
        box-sizing: border-box;
        box-shadow: 3px 10px 15px 10px rgba(0, 0, 0, 0.04);
        border-color: #06358F;
    } */
    .investment-section .invest-row {
        margin-bottom: 0;
    }
    .invest-accordion {
        display: block !important;
        margin-top: 0;
        margin-bottom: 35px;
    }
    .viewInvest-btn {
        display: none;
    }
    .investAccordion-col {
        flex: 0 0 100%;
        max-width: 100%;
        transition: 0.3s all ease;
        margin: 0;
    }
    .invest-accordion h2 {
        border-radius: 8px 8px 0 0;
        display: none;
    }
    .breakdown-table h3 {
        font-size: 18px;
        line-height: 21px;
    }
    .breakdown-table:nth-child(2) h3 {
        border-radius: 8px 8px 0 0;
    }
    .greenOutlineContent {
        display: none;
    }
    .blueOutlineContent {
        display: none;
    }
    .breakdown-table th:first-child,
    .breakdown-table td:first-child {
        width: 90px;
    }
    .breakdown-table td {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    .faqs .faq-icon {
        width: 64px;
        height: 57px;
        margin-bottom: 15px;
    }
    .faqs .faq-icon svg {
        width: 100%;
        height: 100%;
    }
    .franchiseDescription-section .faqs .section-title {
        padding-top: 45px;
        padding-bottom: 45px;
    }
    .franchiseDescription-section .currentOpportunity {
      margin-bottom: 50px;
    }
    .applyForfranchiseDetail-about p a {
        font-size: 14px;
        text-decoration: none;
    }
    .inquiry-modal {
        top: 30px !important;
    }

    body .franchiseNew-wrap .empty-title {
        padding: 70px 0 0 0;
    }

    .franchise-row {
        flex-wrap: wrap;
    }
    .franchise-left,
    .franchise-right {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }
    .franchise-right {
        display: none;
    }
    .shadowBox-head h3,
    .shadowBox-head h1,
    .shadowBox-head h2 {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
    }
    .images-block .swiper-wrapper {
        display: flex;
        grid-template-columns: none;
        grid-auto-rows: unset;
        grid-gap: 0;
    }
    .images-block .swiper-slide:nth-child(1) {
        grid-column-end: unset;
        grid-row-end: unset;
    }
    .images-block .swiper-slide {
        height: 398px;
    }
    .applyForfranchise-card-wrap p .strike-through + span {
        font-size: 18px;
    }
    .investAccordion-col {
        padding-top: 25px;
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 30px;
    }
    .total-footer {
        width: calc(100% + 50px);
        left: -25px;
    }
    .breakdownLists h3 {
        font-size: 18px;
        line-height: 20px;
    }
    .mrCod-report {
        padding: 33px 33px;
    }
    .report-section {
        padding-bottom: 68px;
    }
    .seeMore-desktop {
        display: none;
    }
    .seeMore-mobile,
    .overview-body .seeMore-link {
        display: flex !important;
        align-items: center;
        font-size: 16px;
    }
    .overview-body .seeMore-desktop {
        display: none !important;
    }
    .input_wrap {
        max-width: 100%!important;
        flex: 0 0 100%!important;
        margin-bottom: 20px;
    }
     .input_wrap input[type="text"],
     .input_wrap input[type="tel"] {
        border-radius: 3px;
        height: 51px!important;
        width: 100%;

    }
    .report-row {
        margin: 0 -10px;
    }
    .input_wrap label {
        top: 0px !important;
        left: 8px;
    }
    .input_wrap input:focus + label, .input_wrap input:valid + label {
        top: -10px!important;
    }
    .mobile-bar {
        display: flex;
    }
    .mrCod .franchiseProduct-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active,
    .mrCod .franchiseProduct-swiper .swiper-pagination-bullet:before {
        background-color: #06287A !important;
        background: #06287A !important;
    }

    /* Similar Franchise Section */
    .similar-section {
        background: #FFFFFF;
        border: 1px solid #EFECF3;
        box-sizing: border-box;
        box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
        border-radius: 12px;
        margin: 0 15px;
        padding: 25px 0 20px 0;
        margin-bottom: 30px;
    }
    .similar-section>.container {
        padding-left: 0;
        padding-right: 0;
    }
    .similar-wrap {
        padding: 0;
        background-color: transparent;
        background: none;
        box-shadow: none;
        margin: 0;
    }
    body .similar-section .section-title {
        padding: 0;
        margin-bottom: 10px;
        background: #fff;
    }
    .similar-section .section-title h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #08083D;
    }

    .similar-wrap .owl-carousel .owl-stage-outer {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0,0,0);
        padding: 0;
    }
    .similar-wrap .owl-theme .owl-nav.disabled+.owl-dots {
      z-index: 11111;
      position: relative;
      margin-top: 0;
    }
    .similar-wrap .owl-item   {
        padding: 15px 0;
        transition: .4s all ease;
    }
    .similar-wrap .owl-item.active   {
        transition: .3s all ease;
    }
    .similarFranchise-card {
        max-width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
        border-radius: 4px;
        padding: 8px 11px 14px 11px;
        text-decoration: none;
    }
    .similarFranchise-card span {
        padding: 14px 10px 0 10px;
        height: auto;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.15;
        color: #08083D;
        text-decoration: none;
    }
    .similarFranchise-card >img{
        filter: drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.25));
        border-radius: 4px;
        height: 200px;
        object-fit: cover;
        border-radius: 4px;
    }
    .similarFranchise-card:hover {
        text-decoration: none;
    }


    /*========== New Design Style Responsive ========*/
    .franchise-left {
        display: flex;
        flex-direction: column;
    }
    .shadow-box.gallery-desk {
        order: -1;
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
        border-radius: 12px;
        position: relative;
    }
    .gallery-desk .img,
    .gallery-desk .img img {
        border-radius: 12px;
    }

    .franchiseDescription-section .gallery-desk  .swiper-pagination {
        bottom: 10px;
    }
    .estimated-investmentBox .investment-section {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        margin-bottom: 0;
        box-shadow: none;
        border: none;
    }
    .estimated-investmentBox .invest-button {
        background: #FFFFFF;
        box-shadow: 0px 0px 9px 1px rgba(112, 112, 112, 0.1);
        border-radius: 4px;
        padding: 20px 16px;
    }
    .estimated-investmentBox .invest-button p,
    .estimated-investmentBox .invest-button h4 {
        text-align: left;
    }
    .estimated-investmentBox .invest-price {
        justify-content: flex-start;
    }
    .estimated-investmentBox .info-price {
        padding-left: 10px;
    }
    .estimated-investmentBox .invest-button .invest-price .gif {
        width: 65px;
        height: 65px;
        top: -8px;
    }
    .estimated-investmentBox .price-footer {
        padding: 10px 0;
    }
    .view-details {
        margin-top: 0;
    }
    .faqs-block .shadowBox-head {
        padding-bottom: 25px;
    }
    .estimated-investmentBox .investAccordion-col {
        padding: 25px 25px 0 25px;
    }
    .estimated-investmentBox .investAccordion-head {
        display: none;
    }
    .franchiseSm-logo {
        width: 50px;
        height: 50px;
        flex: 0 0 50px;
    }
    .images-block .img:after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 96.21%);
        z-index: 2;
    }
    .gallery-desk .view-overlay-default {
        background: rgba(0, 0, 0, 0.15)
    }
}

@media only screen and (max-width: 767px) {
    .applyForfranchise-card-wrap .map-icon {
        display: none;
    }
    .franchiseDescription-section .applyForfranchise {
        margin-top: -8px;
    }

    body .franchiseDescription-sectio >.section-title {
        padding: 24px 0 38px;
    }
    .franchiseDescription-section .franchiseDescription-inner  {
        background-color: transparent;
        padding: 23px 0 0 0;
    }
    .franchiseDescription-section .applyForfranchise .section-title.title-bg {
        padding: 67px 16px 85px;
    }

    .franchiseDescription-section .franchiseProduct-swiper.swiper-container-horizontal>.swiper-pagination-bullets,
    .franchiseDescription-section .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -16px;
        left: 0;
        width: 100%;
    }


    .franchiseDescription-section .franchiseProduct-img {
        height: 517px;
        margin: 0 auto;
        max-width: 100%;
        border-radius: 8px 8px 0px 0px;
    }
    .franchiseDescription-section .accordion-card h4 {
        font-size: 16px;
        line-height: 17px;
        color: #000;
        font-weight: bold;
        padding-right: 10px;
    }
    .franchiseDescription-section .franchiseProduct-img img {
        object-fit: cover;
        object-position: top center;
    }

    .franchiseDescription-section .px-0 {
        padding: 0 0;
    }

    .franchiseDescription-section .faqs h3 {
        font-size: 25px;
        line-height: 29px;
        color: #08083D;
    }

    .franchiseDescription .fakeImage {
        width: 100%;
    }
    .franchiseDescription .fakeImage + .fakeImage {
        display: none;
    }
    .franchiseDescription .fakeText,
    .franchiseDescription .fakeCard .fakeTextLine {
        width: 100%;
    }
    .franchiseDescription .fakeCard .fakeImage {
        height: 100px;
    }
    .franchiseDescription .fakeCard {
        min-height: 140px;
    }

    .franchiseDescription-section .frDesc-title {
        padding: 12px 0;
    }
    .franchiseDescription-section .frDesc-title h4 {
        font-size: 16px;
    }

    .franchiseDescription-section .currentOpportunity .container,
    .franchiseDescription-section .faqs .container {
        padding-right: 25px;
    }

    .franchiseDescription-section .similar-section .swiper-pagination {
        bottom: 26px;
    }
    .invest-button h3 {
        font-size: 16px;
    }
    .aboutFranchise-table td:first-child {
        width: 160px;
    }
    .invest-button {
        padding: 20px 15px;
    }
    .franchiseDescription-section .franchiseProduct-img {
        height: 470px;
    }
    .sale-table td:first-child {
        padding-right: 15px;
    }
    .table-scroll {
        overflow: auto;
    }
    .investment-table-card .table-scroll table {
        min-width: 650px;
    }
    .report-item {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .franchiseDescription-section .currentOpportunity .accordion-card li {
        min-width: 100%;
        border-right: 1px solid #fff !important;
        border-left: 1px solid #fff !important;
    }
    .franchiseDescription-section .currentOpportunity .accordion-card li:last-child {
        border-bottom: 1px solid #fff !important;
    }

    /*========== New Design Style Responsive ========*/
    .images-mobileSlider .shadow-box {
        padding: 0;
        box-shadow: none;
    }
    .sale-table tr {
        border: none;
    }
    .sale-table tr:nth-child(2n + 2) {
        background: #F4F6F9;
    }
    .estimated-investmentBox .invest-button p {
        font-size: 13px;
        line-height: 17px;
        white-space: nowrap;
    }
    .estimated-investmentBox .invest-button h4 {
        font-size: 16px;
        line-height: 1.2;
        color: #000000;
        margin-bottom: 6px;
    }
    .estimated-investmentBox .invest-button {
        padding: 20px 13px;
    }
    .estimated-investmentBox .invest-button .invest-price .gif {
        width: 35px;
        height: 35px;
        top: -4px;
    }
    .invest-button .svg {
        height: 35px;
        width: 35px;
        flex: 0 0 35px;
    }
    .estimated-investmentBox .invest-button h3 {
        font-size: 15px;
    }
    .estimated-investmentBox .invest-button h3 span {
        font-size: 20px;
    }
    .estimated-investmentBox .investment-title h3 {
        font-size: 16px;
        line-height: 22px;
    }
    .shadow-box {
        padding: 20px 15px;
    }
    .estimated-investmentBox .info-price {
        padding-left: 4px;
    }
    .estimated-investmentBox .invest-price {
        margin-left: -7px;
    }
    .sale-table {
        padding-left: 0;
        padding-right: 0;
    }
    .sale-table .shadowBox-head {
        padding-left: 15px;
        padding-right: 15px;
    }
    .sale-table td {
        padding: 15px 10px;
        font-size: 14px;
    }
    .sale-table td:first-child {
        width: 48%;
        font-weight: 400;
    }
    .contactCard .note .searchText p strong {
    font-size: 12px!important;
    }
    .contactCard .note .searchText p {
    font-size: 11px!important;
    line-height: 16px!important;
  }
}

@media (max-width: 480px) {
    .franchiseDescription-section .applyForfranchise h3 {
        font-size: 22px;
    }
    .price-footer h3 span {
        font-size: 17px;
        letter-spacing: -1px;
    }
    .investment-section .invest-row .col  {
        padding-left: 4px;
        padding-right: 4px;
    }
    .investment-section .invest-row {
        margin-left: -4px;
        margin-right: -4px;
    }
    .invest-button .price-footer h3 {
        white-space: nowrap;
    }
    .similar-wrap .owl-item {
        transform: scale(0.9);
    }
    .similar-wrap .owl-item.active {
        transform: scale(1);
    }
    .similarFranchise-card >img {
        height: 200px;
    }
}
@media (max-width: 400px) {
    /* .contactCard .note .searchText p {
        white-space: pre-wrap;
    }
    .contactCard .note .searchText p {
        font-size: 10px;
    }
    .contactCard .note .searchText {
        flex: 1;
    }
    .contactCard .note {
        align-items: center;
    }
    .contactCard .search-icon {
        width: 42px;
        height: 41px;
        flex: 0 0 32px;
        left: 7px;
    }
    .contactCard .note .searchText p strong {
        font-size: 12px;
    }
    */

.shadowBox-head h3, .shadowBox-head h1, .shadowBox-head h2 {
    font-size: 16px;
    line-height: 20px;
  }
    .invest-button {
        padding: 20px 10px;
    }
    .price-footer h3 span {
        font-size: 16px;
    }
    .invest-button {
        padding: 20px 7px;
    }

    .similarFranchise-card >img{
        height: 152px;
    }
    .search-icon .searchInside {
        left: 3px;
        top: 8px;
        width: 20px;
        height: 20px;
    }

    .estimated-investmentBox .invest-button p {
        color: #414141;
        font-size: 12px;
    }
    .estimated-investmentBox .invest-button h3 span {
        font-size: 18px;
    }
    .estimated-investmentBox .invest-button h3 {
        font-size: 13px;
    }
    .estimated-investmentBox .invest-button {
        padding: 17px 8px;
        min-height: 162px;
    }
}

@media only screen and (max-width: 375px) {
    .franchiseDescription-section .applyForfranchise-card-wrap p {
        font-size: 12px;
        line-height: 16px;
    }
    .franchiseDescription-section .frDesc-title h4 {
        font-size: 14px;
    }

    .franchiseDescription-section .applyForfranchise-card-wrap p strong {
        font-size: 14px;
        line-height: 21.7px;
    }

    .franchiseDescription-section .applyForfranchiseDetail-about li span {
        font-size: 12px;
    }

    .franchiseDescription-section .investment-table-card thead th,
    .franchiseDescription-section .investment-table-card tbody td {
        font-size: 12px;
    }
    .franchiseDescription-section .accordions-outer {
        padding-left: 0;
        padding-right: 0;
    }

    .invest-button {
        padding: 20px 10px;
    }
    .invest-button h3 {
        font-size: 16px;
    }
    .franchiseDescription-section .applyForfranchise h3 {
        font-size: 18px;
    }
    .total-footer p {
        font-size: 18px;
    }
}

@media (max-width: 370px){
    .franchiseDescription-section .contentContainer {
        padding: 0;
    }
    .invest-price {
        flex-direction: column;
        text-align: center;
    }
    .info-price {
        padding-left: 0;
    }
    .info-price * {
        text-align: center;
    }
    .estimated-investmentBox .invest-button h3 span {
        display: block;
    }
}

/*--- Franchise Description CSS End ---*/


/* .fancybox-container:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.77);
    z-index: 99999;
    animation-name: hideLoader;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.fancybox-container:before {
    content: "";
    background: url("../images/new/334-loader-5.gif") center center no-repeat;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 999999;
    background-size: 100px;
    animation-name: hideLoader;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes hideLoader {
    0% {
        content: none;
    }
    100% {
        content: none;
    }
} */
