/* FilePond Themes */
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

@font-face{
  src:url(fonts/Gilroy-ExtraBold.otf);
  font-family:"Gilroy-ExtraBold";
  font-display: swap;
}
@font-face{
  src:url(fonts/Gilroy-Light.otf);
  font-family:"Gilroy-Light";
  font-display: swap;
}
@font-face{
  src:url(fonts/Roboto-Medium.ttf);
  font-family:"Roboto-Medium";
  font-display: swap;
}
@font-face{
  src:url(fonts/Roboto-Bold.ttf);
  font-family:"Roboto-Bold";
  font-display: swap;
}
@font-face{
  src:url(fonts/Roboto-Regular.ttf);
  font-family:"Roboto-Regular";
  font-display: swap;
}
    body{
        font-family: -apple-system,system-ui,BlinkMacSystemFont,"Gilroy-Light","Roboto-Medium","Helvetica Neue",Arial,sans-serif;
    }

    .none{
        display:none !important;
    }

    .mt-50{
        margin-top:50px;
    }

    button{
        outline:none !important;
    }


    iframe.mapIframe {
    width: 100%;
    height: 500px;
    }

    /********************** CTA **************************/
    .modal {
    z-index: 99999;
    }
    .guidePage{
    width: 100%;
    float: left;
    margin-top: 85px;
    }
    .guideDetailPage{
        width: 100%;
        float: left;
    /* margin-top: 85px; */
        position: relative;
    }
    .guideDetailPage:before {
        content: "";
        background: linear-gradient(180deg, #06287A 20.94%, #070B43 87.72%);
        width: 100%;
        height: 125px;
        display: block;
    }
    .ctacopy{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 13px #0000001A;
        border-radius: 4px;
        width:100%;
        margin:0px auto;
        margin-top:120px;
        padding:40px 0px;
    }
    .ctacopy p{
        color: #7D8BA9;
        font-size:26px;
        font-family: inherit;
    }
    .ctacopy .darkblue{
        font-family: inherit;
        font-weight: 600;
        font-size:28px;
        color: #1E43FF;
        letter-spacing: 0.08px;
        line-height:37px;
    }
    .ctacopy .darkblue span{
        color: #FF0000;
    }

    /************************* Get Started ***********************/
    .mgetstarted {
        width: 100%;
         background-image: url(../images/pattern.svg);
         background-size: 400px;
         background-repeat: no-repeat;
         background-position: bottom left;
         padding-bottom: 8%;
    }
    .mgetstarted .top-logo{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .mgetstarted .top-logo a{
        color: #1E43FF;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
    }

    .mgetstarted .stepsect{
        margin-top: 80px;
        margin-left: 90px;
        margin-right: 80px;
    }
    .mgetstarted .progress {
        height: 13px;
        border-radius: 10px;
        background-color: #F0F1F7;
    }
    .mgetstarted .progress .progress-bar{
        border-radius: 10px;
        background-color: #1E43FF;
    }
    .mgetstarted .progress-bar-label{
        margin-top:20px;
        color: #7D8BA9;
        font-size: 18px;
        font-weight: 600;
    }
    .mgetstarted .getlogin{
        margin-top: 70px;
    }
    .mgetstarted .getlogin img
    {
        margin-left: 125px;
    }
    .mgetstarted .getlogin h3{
        color: #1E43FF;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 22px;
    }
    .mgetstarted .getlogin p{
        color: #7D8BA9;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .mgetstarted .inner-login{
        width:430px;
    }
    .mgetstarted .inner-login label{
        color: #242328;
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
    }
    .mgetstarted .inner-login label span{
        color: #FC5774;
    }
    .mgetstarted .inner-login .input-sec{
        border-bottom: 1px solid #bdc4d3;
        padding-bottom: 15px;
        margin-bottom: 30px;
    }
    .mgetstarted .inner-login .input-sec input{
        width: 100%;
        border: 0px;
        color: #7d8ba9;
        font-size: 25px;
        font-weight: 600;
        outline: none;
    }
    .mgetstarted .inner-login .input-sec input::placeholder{
        color: #bdc4d3;
    }

    .mgetstarted .inner-login a.btn{
        background-color: #1E43FF;
        width: 100%;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        padding: 20px 0px;
        border: 0px;
    }
    .mgetstarted .getlogin .inner-login p{
        color: #7D8BA9;
        font-size: 18px;
        margin-bottom: 0px;
        margin-top: 30px;
    }
    .mgetstarted .getlogin .inner-login p a{
        color: #1E43FF;
    }

    .pricetabs{
        width:736px;
        margin:0px auto;
        margin-top:80px;
    }
    .pricetabs .nav-tabs .nav-link{
        border-bottom: 0px;
        border-color: transparent;
        font-size: 18px;
        color: #1E43FF;
        font-weight: 600;
        padding: 18px 0px;
    }
    .pricetabs .nav-tabs .nav-link.active{
        background-color: #1E43FF;
        color: #fff;
    }
    .pricetabs .nav-tabs
    {
        border: 1px solid #1E43FF;
        border-radius: 12px;
        overflow: hidden;
    }

    .pricetabs .tab-content{
        width:100%;
        margin-top: 35px;
        box-shadow: 0px 3px 19px #00000029;
        border-radius: 12px;
    }

    .pricetabs .tab-content .inner-prc{
        padding: 39px 40px 0px 45px;
    }

    .pricetabs .tab-content .inner-prc h4
    {
        color:#242328;
        font-size:32px;
        font-weight:600;
        margin: 0px;
    }
    .pricetabs .tab-content .inner-prc p
    {
        color:#7D8BA9;
        font-size:18px;
        margin: 0px;
        text-align:right;
        margin-top: -12px;
    }
    .pricetabs .tab-content .inner-prc h3
    {
        color:#1E43FF;
        font-size:48px;
        margin: 0px;
        font-weight: 600;
    }
    .pricetabs .tab-content .inner-prc h3 span{
        font-size: 16px;
    }

    .pricetabs .tab-content .inner-prc h3 label {
        color: #FF0000;
        font-size: 19px;
        background: #F0F1F7 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 0px #00000029;
        border-radius: 4px;
        padding: 10px;
        margin-left: 15px;
        position: relative;
        top: -7px;
    }

    .pricetabs .tab-content .inner-prc .alignul{
        margin-top: 40px;
        display: flex;
        justify-content: end;
        margin-bottom: 40px;
    }

    .pricetabs .tab-content .inner-prc .alignul ul{
        list-style-image: url(../images/check.svg);
        padding-left: 30px;
        line-height: 40px;
    }

    .pricetabs .tab-content .inner-prc .alignul ul li
    {
        color:#7D8BA9;
        font-size:17px;
    }
    .pricetabs .tab-content .inner-prc .alignul ul + ul{
        margin-left:150px;
    }

    .pricetabs .tab-content .trybtn{
        color: #fff;
        font-size: 22px;
        font-weight: 600;
        background: transparent linear-gradient(270deg, #81D3E4 0%, #4A83F3 28%, #1E43FF 100%) 0% 0% no-repeat padding-box;
        border-radius: 6px;
        height: 65px;
        width: 100%;
        border:0px;
    }

    .card-section {
        margin-top: 140px;
        padding-bottom: 30px;
    }

    .card-detail{
        margin-top: 95px;
        text-align: center;
    }
    .card-detail h3{
        color: #1E43FF;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .card-detail p{
        color: #7D8BA9;
        font-size: 18px;
        margin-bottom:0px;
    }

    .card-section .inner-prc {
        padding: 25px 33px 25px 32px;
        box-shadow: 0px 3px 19px #00000029;
        border-radius: 12px;
        width: 400px;
    }
    .card-section .inner-prc h4{
        color: #242328;
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
    }

    .card-section .inner-prc p {
        color: #7D8BA9;
        font-size: 11px;
        margin-bottom: 5px;
        text-align: right;
        margin-top: -12px;
    }
    .card-section .inner-prc h3 {
        color: #1E43FF;
        font-size: 26px;
        margin: 0px;
        font-weight: 600;
    }
    .card-section .inner-prc h3 span {
        font-size: 9px;
    }
    .card-section .inner-prc h3 label {
        color: #FF0000;
        font-size: 11px;
        background: #F0F1F7 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 0px #00000029;
        border-radius: 4px;
        padding: 5px;
        margin-left: 15px;
        position: relative;
        top: -5px;
    }
    .card-section .inner-prc .alignul {
        margin-top: 40px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: end;
        margin-bottom: 0px;
    }
    .card-section .inner-prc .alignul ul {
        list-style-image: url(../images/check2.svg);
        padding-left: 20px;
        line-height: 25px;
    }
    .card-section .inner-prc .alignul ul li {
        color: #7D8BA9;
        font-size: 11px;
    }
    .card-section .inner-prc .alignul ul + ul {
        margin-left: 70px;
    }
    .card-section .inner-prc h5{
        color: #242328;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
    }

    .card-section .inner-prc p.btmtext{
        color: #7D8BA9;
        font-size: 11px;
        text-align: left;
        margin-top: 5px;
    }
    .medalsection{
        background-color: #F7F9FF;
        border-radius: 6px;
        margin-bottom:30px;
        text-align:center;
        color: #1E43FF;
        font-size: 16px;
        padding:17px 0px;
        width:400px;
    }
    .medalsection span{
        font-weight:600;
    }
    .medalsection img{
        margin-right:20px;
    }
    .mgetstarted.cardpayment{
        padding-bottom:0px;
        background-image: none;
    }

    .section-card{
        margin-top: 40px;
    }

    .section-card label{
        color: #242328;
        font-size: 16px;
        font-weight: 600;
    }
    .section-card  .inputst input{
        border: 0px;
        font-size: 25px;
        color: #7D8BA9;
        font-weight: 600;
        width: 100%;
        outline: none;
    }
    .section-card  .inputst input::placeholder{
        color: #bdc4d3;
    }
    .section-card .inputst {
        padding-bottom: 15px;
        border-bottom: 1px solid #bdc4d3;
    }

    .section-card .cardn{
        margin-top: 55px;
    }
    .section-card .cardn .oneline{
        display:flex;
        position: relative;
    }

    .section-card .cardn .cardmonth{
        width:125px;
    }
    .section-card .cardn .cvc{
        width:60px;
    }
    .section-card .cardn .cardnumber{
        padding-left:50px;
    }
    .section-card .cardn .oneline img{
        position: absolute;
        left: 0px;
    }
    .section-card .starttrail{
        font-weight: 600;
        font-size: 22px;
        width: 100%;
        background: transparent linear-gradient(270deg, #81D3E4 0%, #4A83F3 28%, #1E43FF 100%) 0% 0% no-repeat padding-box;
        border-radius: 6px;
        margin-top: 55px;
        padding: 17px 0px;
        border: 0px;
        color: #fff;
    }

    /*********************************************************/


    @media (max-width: 1920px) and (min-width:1400px){
        .container, .container-lg, .container-md, .container-sm, .container-xl {
            max-width: 1344px;
        }
        /* .mgetstarted .container{
            max-width: 1140px;
        } */
    }
    @media (max-width: 991px){
        .ctacopy .darkblue{
            font-size: 25px;
            padding:5px ;
        }
        .ctacopy{
            margin-top: 20px;
        }

    }
    @media (max-width: 767px)
    {
        .ctacopy .darkblue {
            font-size: 20px;
            padding: 5px;
        }
    }
    /************************* Media Queries *********************************/


    section.af-full-detail ul.breadcrumb {
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;
      display: inline-block;
    }
    section.af-full-detail .detailPageBread ul.breadcrumb{
      margin-bottom: 0;
    }
    .detailPageBread {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 10px 0px;
    }
    .breadcrumb>li {
        display: inline-block;
    }
    .outer-border-form ul.breadcrumb {
        width: 80%;
    }
    section.af-home-details ul.breadcrumb li a,
    section.af-full-detail ul.breadcrumb li a {
      color: #08083D;
      font-size: 13px;
    }
    section.af-full-detail ul.breadcrumb li a {
      font-size: 14px;
    }
    ul.breadcrumb p{
    display: inline-block;
    margin: 0;
    }
    section.af-home-details ul.breadcrumb li a:hover, section.af-full-detail ul.breadcrumb li a:hover {
        text-decoration: underline;
    }
    section.af-home-details ul.breadcrumb li,
    section.af-full-detail ul.breadcrumb li {
      font-family:"Roboto-Medium";
      font-size: 13px;
      color: #757575;
    }
    section.af-full-detail ul.breadcrumb li {
      font-size: 14px;
    }
    .breadcrumb>li+li:before {
      padding: 0 5px;
      color: #757575;
      content: "\f105 ";
      font-size: 16px;
      font-family: "FontAwesome";
    }
    .detailPageBread .breadcrumb>li+li:before{
        padding: 0 15px;
    }
    .listingDetailBanner .image-bannerDesign {
        height: 400px;
        background-color: #fff;
        border: none;
    }

    .listingDetailBanner .image-bannerDesign .halfImg {
        border: none;
        margin-right: 10px;
        /* border-radius: 25px 0px 0px 25px; */
        width: calc(50% - 10px);
    }
    .listingDetailBanner .image-bannerDesign .halfImg.borderRadCh {
        /* border-radius: 0 25px 25px 0; */
    }
    .image-bannerDesign .halfImg.fullWidthImg.default-img {
        /* border-radius: 25px; */
        margin: 0;
    }
    .image-bannerDesign .halfImg.fullWidthImg.default-img img {
      height: 100%;
      float: left;
      object-position: 50%;
    }
    .listingDetailBanner .image-bannerDesign .quaterImg:first-of-type {
        margin-right: 5px;
        width: calc(25% - 5px);
    }

    .listingDetailBanner .image-bannerDesign .quaterImg:last-of-type {
        margin-left: 5px;
        width: calc(25% - 5px);
    }

    .listingDetailBanner .image-bannerDesign .quaterImg a:first-child {
        border: none;
        margin-bottom: 10px;
        height: calc(50% - 5px);
    }

    .listingDetailBanner .image-bannerDesign .quaterImg a:last-child {
        border: none;
        margin-top: 10px;
        height: calc(50% - 5px);
    }

    .listingDetailBanner .image-bannerDesign .quaterImg:last-of-type a:first-child {
        /* border-radius: 0px 25px 0px 0px; */
    }

    .listingDetailBanner .image-bannerDesign .quaterImg:last-of-type a:last-child {
        /* border-radius: 0px 0px 25px 0px; */
    }

    .listingDetailBanner {
        margin-top: 20px;
    }
    section.af-full-detail h2 {
      color: #08083D;
      font-size: 26px;
      margin: 0 0;
      padding: 0;
      font-family: "Gilroy-ExtraBold";
    }
    section.af-full-detail .h3Head {
      margin-bottom: 15px;
      margin-top: 0;
      color: #484848;
      line-height: 25px;
      margin: 0;
      font-size: 24px;
      font-family: "GoogleSans-Bold";
      display: inline-block;
    }
    section.af-full-detail .h3Head span {
        font-size: 18px;
    }
    section.af-full-detail .full-address {
      color: #757575;
      margin: 5px 0 0;
      display: inline-block;
      float: left;
    }
    section.af-full-detail .full-address a {
        color: #08083D;
        font-size: 14px;
    }
    .shareSave {
        display: inline-block;
        float: right;
    }
    .shareSave a {
        display: inline-block;
        float: left;
        color: #222222;
        font-size: 14px;
        margin-right: 20px;
        text-decoration: underline;
    }
    .shareSave a svg {
        display: inline-block;
        color: #222222;
        margin-right: 3px;
    }
    .shareSave p {
        display: inline-block;
        float: left;
        margin: 0;
        font-size: 14px;
        color: #222;
        text-decoration: underline;
        cursor: pointer;
    }
    .shareSave p i.fa.fa-heart {
        color: #f00;
    }
    section.af-full-detail {
      padding: 0px 0px 50px;
      width: 100%;
      float: left;
      position: relative;
      min-height: calc(100vh - 273px);
    }
    .mobileTopbar {
      display: none;
    }
    section.af-full-detail.zIndexMax {
        z-index: 999999;
    }
    .af-full-detail hr{
      border-top: 1px solid #ebebeb;
      margin-bottom: 0;
    }
    section.recent-agency-properties.newDesign {
      padding: 25px 0 15px;
      border-bottom: 1px solid #ddd;
    }
    .main-heading-similiar {
        font-size: 24px;
        font-family: "GoogleSans-bold";
        color: #424242;
        padding-bottom: 25px;
        margin: 0;
    }
    .prevnext {
      width: 100%;
      text-align: center;
    }
    .outer-border-form {
    padding-top: 30px;
    }
    .prevnext a {
      padding: 0 10px 0px;
      color: #000;
      font-family: "GoogleSans-Regular";
    }
    .prevnext a.active {
      color: #08083D;
    }
    .property-type p {
      margin: 0;
      padding: 13px 0 15px;
      line-height: 19px;
      color: #666;
      font-size: 16px;
    }
    .property-type {
      display: inline-block;
    }
    .fav-hide-rep {
      display: inline-block;
      float: right;
      padding: 0 0 5px;
      position: relative;
      z-index: 999999;
    }
    .fav-hide-rep a {
      font-size: 20px;
      margin: 0 45px 0 0;
      display: inline-block;
      text-align: center;
      color: #424242;
      font-family: "GoogleSans-Regular";
      cursor: pointer;
      text-decoration: none;
    }
    .graph-tabs.inner-tabs .panel.panel-default {
        width: 100%;
        float: left;
    }
    .fav-hide-rep a.af-report {
        margin: 0;
    }
    .fav-hide-rep a span {
      display: block;
    }
    .fav-hide-rep a.af-favorite:hover i {
      color: #ffc922;
    }
    .fav-hide-rep a.af-favorite i {
      background: #eee;
      background-clip: text;
      -webkit-background-clip: text;
    }
    .fav-hide-rep a.af-trash:hover i, .fav-hide-rep a.af-report:hover i {
      color: #f00;
    }
    .property-type p.premium i {
      font-size: 20px;
      padding: 0 10px 0 0;
      color: #f00;
    }
    .property-type p span {
      line-height: 19px;
      border-bottom: 1px solid rgba(102, 102, 102,1);
    }
    .af-details span {
      font-family: "GoogleSans-Regular";
      color: #757575;
    }
    .af-details img {
      width: 25px;
      margin-bottom: 10px;
      height: 20px;
    }
    .af-full-features {
        width: 100%;
        float: left;
        margin: 20px 0 0 0;
    }
    .col-xs-4.pd480-5 {
        padding: 0;
    }
    .col-xs-4.pd480-5:last-of-type .af-details {
        border-right: 0;
    }
    .featureRow {
      width: 100%;
      float: left;
      margin-bottom: 10px;
      padding: 30px 15px 15px;
    }
    .downPayRow{
      width: 100%;
      float: left;
      border-bottom: 1px solid #EBEBEB;
    }
    .localInformation {
        width: 100%;
        float: left;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
    }
    .mapImage {
        width: 100%;
        height: 180px;
        margin-bottom: 10px;
    }
    .pac-container {
      z-index: 999999999999 !important
    }
    .pac-item {
      z-index: 999999999999 !important
    }
    .localInformation.recentListingAgents .mapImage img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
    }
    .mapInnerBoxes p.mapTitle {
        font-size: 16px;
        color: #3b4144;
        font-family: "GoogleSans-Medium";
        margin: 0 0 3px;
    }
    .mapInnerBoxes p {
        color: #484848;
        margin-bottom: 0;
    }
    .mapBoxCaro {
        width: 100%;
        float: left;
        padding: 0px 0 20px;
    }
    .localInformation.recentListingAgents .owl-nav button.owl-prev{
        left: -15px;
    }
    .localInformation.recentListingAgents .owl-nav button.owl-next{
        right: -15px;
    }
    .mapModals .modal-body {
        width: 100%;
        float: left;
    }
    .modal-dialog.mapModals {
      height: calc(100% - 100px);
      width: calc(100% - 96px);
      margin: 50px auto;
    }
    .mapModals .tab-content {
        height: calc(100% - 60px);
    }
    .mapModals .tab-content .tab-pane {
        height: 100%;
        position: relative;
    }
    .mapModals .modal-content {
        width: 100%;
        float: left;
        height: 100%;
    }
    .mapModals .modal-body {
        width: 100%;
        float: left;
        padding: 0;
        height: 100%;
    }
    .mapTabs {
        width: 100%;
        float: left;
        height: 100%;
    }
    .mapModals iframe {
        border-radius: 0 0 8px 8px;
    }
    .mapTabs ul.nav.nav-tabs {padding: 10px;}
    .mapModals button.close {
        padding: 10px 20px;
        position: absolute;
        right: 0;
        top: 8px;
    }
    .mapModals .mapTabs .nav-tabs li.active a {
        border: 1px solid #ddd;
        color: #08083D;
    }
    .mapModals .mapTabs .nav-tabs li.active a:hover {
        background-color: transparent;
        border: 1px solid #ddd;
    }
    .mapModals .mapTabs .nav-tabs li a {
        color: #3b4144;
        font-size: 18px;
        font-family: "GoogleSans-Medium";
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
    }
    .mapModals .mapTabs .nav-tabs li a:hover {
        border-color: transparent;
        background-color: rgba(232, 233, 234,1);
    }
    .mapSideInfoBox {
        width: 300px;
        background-color: #fff;
        position: absolute;
        z-index: 9999;
        right: 15px;
        top: 15px;
        bottom: 15px;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    }
    .mapSideInfoBox a {
        position: absolute;
        right: 20px;
        font-size: 25px;
        top: 5px;
        color: #222;
    }
    .infoInner {
        width: 100%;
        float: left;
    }
    .infoInner p.title {
        color: #3b4144;
        font-family: "GoogleSans-Medium";
        font-size: 20px;
        margin: 0;
    }
    .af-details {
      width: 100%;
      text-align: center;
      margin: 20px 0;
      border-right: 1px solid #757575;
    }
    .af-details p {
      color: #757575;
      margin: 0;
      font-family: "GoogleSans-Regular";
    }
    .sm-details h5{
      color: rgba(66, 66, 66, 1);
      font-family: "GoogleSans-Medium";
    }

    .about-property {
      font-family: "GoogleSans-Regular";
      width: 100%;
      float: left;
    }
    .af-full-features img {
      width: 18px;
      margin-right: 10px;
    }
    section.af-full-detail h4 a {
      color: #08083D;
    }
    section.af-full-detail h4 a:hover {
      text-decoration: none;
      color: #08083D;
    }
    .full-posted-by a {
      color: #666;
    }
    .full-posted-by a:hover {
      color: #000;
      text-decoration: none;
    }
    .total-listing .green {
      color: #00897b;
    }
    .total-listing .blue {
      color: #08083D;
      padding: 0 0 0 5px;
    }
    .detail-conti {
      padding: 15px 0 0;
      width: 100%;
      float: left;
    }
    .af-req-box {
      width: 100%;
      float: left;
      padding: 20px 25px 20px;
      margin: 0 0;
      border: 1px solid #DDDDDD;
      border-radius: 6px;
    }
    .need-help-boxMobile {
        width: 100%;
        float: left;
    }
    .reportLinkBox {
        width: 100%;
        float: left;
        text-align: center;
        padding: 10px 0;
    }
    .reportLinkBox a {
        color: #3F3F3F;
        font-size: 14px;
        cursor: pointer;
    }
    .reportLinkBox a i {
        padding-right: 5px;
    }
    .af-req-box.mt-20.downBox {
        border: 1px solid #EBEBEB;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
    }
    .af-req-box.mt-20 {
        margin-top: 30px;
    }
    .exploreSec {
        width: 100%;
        float: left;
        padding: 15px 0 30px;
        border-bottom: 1px solid #ddd;
    }
    .usefulLinksBox ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .usefulLinksBox ul li {
        padding: 3px 0;
        width: 33.3%;
        float: left;
    }
    .usefulLinksBox ul li:last-child {
        padding: 3px 0 0;
    }
    .usefulLinksBox ul li a {
        color: #767676;
        font-size: 16px;
        font-family: "GoogleSans-Regular";
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .usefulLinksBox ul li a:hover{
      text-decoration: underline;
    }
    .reqForm-header {
      width: 100%;
      float: left;
      margin: 0 0;
      padding: 0 0 15px;
    }
    .af-req-box .reqForm-header p {
        margin: 0;
        text-align: center;
        font-size: 24px;
        color: #424242;
        font-family: "GoogleSans-Medium";
    }
    .af-req-box p {
      font-size: 13px;
      font-family: "GoogleSans-Medium";
    }
    .reqPopBtn {
        width: 100%;
        float: left;
        padding: 20px 0 0px;
    }
    .reqPopBtn button {
        width: 100%;
        border: 2px solid transparent;
        background-color: #23CE6B;
        color: #fff;
        padding: 10px 0px;
        font-size: 16px;
        font-family:"Roboto-Medium";
        box-shadow: 0px 8px 8px #23CE6B80;
        border-radius: 5px;
    }
    .reqPopBtn button:hover{
      background-color: transparent;
      border-color:  #23CE6B;
      color: #23CE6B;
    }
    .marketed-by {
      width: 100%;
      float: left;
      padding: 0;
      display: inline-block;
      -webkit-box-align: center;
      align-items: center;
    }
    .userImage {
        width: 70px;
        height: 70px;
        float: left;
        display: inline-block;
        border-radius: 50%;
    }
    .marketed-by img {
      display: inline-block;
      float: left;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .marketed-by p {
      color: #424242;
      font-size: 16px;
      margin: 0;
      padding: 0 0 0 20px ;
      float: left;
      display: inline-block;
      width: calc(100% - 70px);
    }
    .marketed-by p.propertyPostedBy {
        font-size: 14px;
        font-family: "GoogleSans-Regular";
        padding-top: 15px;
    }
    .show-num-btn button {
      width: 100%;
      background-color: #08083D;
      border: 1px solid #08083D;
      border-radius: 5px;
      padding: 7px 0;
      text-align: center;
      color: #fff;
      margin-bottom: 10px;
      font-size: 14px;
    }
    .modal-dialog.propertyFormPop {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }
    .rquest-form .af-input-box.required:before {
    z-index: 9999;
    position: absolute;
    content: "";
    border-color: #08083D transparent transparent #08083D;
    border-style: solid;
    border-width: 3px;
    border-top-left-radius: 2px;
    left: 0;
    }
    p.optionHead {
        color: #484848;
        font-family: "GoogleSans-Bold";
        font-size: 24px;
        margin: 0;
        padding-bottom: 30px;
        text-align: center;
    }
    .rquest-form h4 {
    font-family: "Gilroy-ExtraBold";
    }
    .rquest-form p {
      color: #08083D;
      font-size: 16px;
      padding: 15px 0 26px;
      margin: 0;
      font-family: "Gilroy-Light";
    }
    .propertyFormPop .modal-content {
        border-radius: 0px;
    }
    .propertyFormPop .modal-body {
        padding: 70px 0px 25px;
    }
    .propertyFormPop .container {
        position: relative;
    }
    div#inquiryFormPop {
        padding-right: 0px !important;
    }
    button.reqFormClose.close {
        float: left;
        position: absolute;
        top: -45px;
        opacity: 1;
        left: -30px;
    }
    .show-num-btn button:hover {
      background-color: transparent;
      color: #08083D;
    }
    .rquest-form input {
      border: 1px solid #d9d9d9;
      color: #424242;
      border-radius: 2px;
      background: #fff;
      padding: 0px 15px 0 60px;
      width: 100%;
      margin-bottom: 0px;
      font-family:"Roboto-Medium";
      height: 45px;
    }
    .rquest-form textarea{
      width: 100%;
        float: left;
        background-color: #f8f8f8;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        font-size: 12px;
        font-family: "GoogleSans-Regular";
        color: #424242;
        padding: 7px 15px 0 45px;
        height: 75px;
    }
    .af-input-box.required i {
    background-color: transparent;
    border: none;
    border-right: 1px solid hsla(0,0%,43.9%,.5);
}
.af-input-box i {
    position: absolute;
    left: 1px;
    top: 1px;
    font-size: 16px;
    color: #c4c4c4;
    bottom: 1px;
    margin: auto 0;
    width: 45px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: #f8f8f8;
    border-right: 1px solid #d9d9d9;
}
    .rquest-form.loan textarea {
        padding: 7px 15px 0 60px;
    }
    .rquest-form input:focus, .rquest-form textarea:focus{
      outline: 0;
      box-shadow: none;
      border-color: #08083D;
    }
    .rquest-form input:focus + i, .rquest-form textarea:focus + i{
      color: #08083D;
    }
    .rquest-form .af-input-box {
        position: relative;
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding: 0;
    }
    .af-input-box.af-select select {
        background-color: #f8f8f8;
        font-size: 14px;
        color: #424242;
    }
    .rquest-form button {
      width: auto;
      border: 1px solid transparent;
      background-color: #23CE6B;
      color: #fff;
      padding: 15px 60px;
      border-radius: 6px;
      font-size: 15px;
      box-shadow: 0px 8px 8px #23CE6B80;
      border-radius: 5px;
      font-family:"Roboto-Medium";
    }
    .formSubBtn {
    width: 100%;
    float: left;
    text-align: center;
    padding: 15px 0;
}

.privacyText {
    width: 100%;
    float: left;
}

.privacyText h4 {
    display: inline-block;
    float: left;
    margin: 0;
    padding: 0;
    line-height: 17px;
}

.privacyText p {
    padding: 0;
}

.privacyText a {
    color: #23CE6B;
}

.rquest-form h4 a {
    font-size: 14px;
    color: #23CE6B;
}
    .rquest-form button:hover{
      background-color: transparent;
      border: 1px solid #23CE6B;
      color: #23CE6B;
    }
    .show-num-btn button i {
      padding: 0 5px 0 0;
    }
    .formSubmBtn button {
        width: 100%;
        padding: 10px 0;
        font-family: "GoogleSans-Bold" !important;
    }
    .bankImg {
        padding: 15px 0;
    }
    .save-property {
      width: 100%;
      margin: 0;
      float: left;
      text-align: center;
    }
    .save-property p {
      font-size: 13px;
      color: #08083D;
      margin: 0;
      padding: 8px 10px;
      display: inline-block;
      border: 1px solid #08083D;
      border-radius: 3px;
      cursor: pointer;
    }
    .save-property p span {
      cursor: pointer;
    }
    .save-property p i {
      color: #3390e9;
      font-size: 14px;
      margin: 0 10px 0 0;
      cursor: pointer;
    }
    .save-property p .fa-heart {
      color: #f95757;
    }
    .send-to-friend {
      width: 100%;
      margin: 10px 0;
    }
    .send-to-friend p {
      font-size: 14px;
      color: #757575;
      margin: 0;
      padding: 10px 0;
    }
    .send-to-friend p i {
      color: #3390e9;
      font-size: 20px;
      margin: 0 10px 0 0;
    }
    .share-area input {
      border: 1px solid #e3e3e3;
      color: #424242;
      border-radius: 2px;
      background: #fff;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
    }
    .share-area button {
      width: 100%;
      border: 1px solid transparent;
      background-color: #3390e9;
      color: #fff;
      font-size: 14px;
      padding: 7px 50px;
      border-radius: 3px;
    }
    .social-imgs {
      display: flex;
      justify-content: center;
    }
    .social-imgs img {
      width: 35px;
      height: 35px;
    }
    .shw-btns a {
      color: #08083D;
      font-family: 'Gilroy-Light';
      text-decoration: underline;
    }
    .send-to-friend:hover {
      cursor: pointer;
    }
    .price-right {
        width: 100%;
        text-align: center;
        float: left;
        padding: 50px 0 21px;
    }
    .projectView.detailProperty {
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        padding: 15px 15px;
        margin-top: 10px;
        margin-bottom: 20px;
        background-position: 95% center;
    }
    .image-bannerDesign {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        overflow: hidden;
        background: black;
        height: 592px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        position: relative;
    }
    .mobileBannerDesign {
    display: none;
    }

    .listingDetailBanner .image-bannerDesign .halfImg {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        border: 1px solid #707070;
        height: 100%;
    }
    .image-bannerDesign .halfImg.fullWidthImg {
        width: 100%;
        border-radius: 25px;
    }
    .listingDetailBanner .image-bannerDesign .halfImg.width60Banner {
        width: 60%;
    }
    .image-bannerDesign .fullImg{
      width: 100%;
      height: 100%;
    }
    .image-bannerDesign .halfImg img {
        width: 100%;
        height: 100%;
        object-position: center;
        transform: scale(1);
        transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
    .image-bannerDesign picture {
        width: 100%;
        height: 100%;
    }
    .image-bannerDesign .quaterImg a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        border: 1px solid #000;
        height: 50%;
    }
    .image-bannerDesign .quaterImg a.fullImgHeight {
        height: 100%;
    }
    .listingDetailBanner .image-bannerDesign .quaterImg a.fullImgHeight {
        height: 100%;
        margin: 0;
        border-radius: 0px 25px 25px 0 !important;
    }
    .image-bannerDesign .quaterImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(1);
        transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
    .image-bannerDesign a:hover img {
        transform: scale(1.05);
        transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    }
    .image-bannerDesign .quaterImg {
        width: 25%;
        height: 100%;
    }
    .image-bannerDesign .quaterImg.width40Banner {
        width: 40%;
    }
    .listingDetailBanner .image-bannerDesign .quaterImg.width40Banner {
        width: 40%;
    }

    .featureSubHead {
      font-size: 22px;
      font-family:"Gilroy-ExtraBold";
      color: #08083D;
      padding: 0px 0 20px;
      margin: 0;
      width: 100%;
      float: left;
    }
    .about-property p {
        width: calc(47% - 23px);
        float: left;
        display: -webkit-box;
        display: flex;
        padding: 5px 0px 5px 0px;
        color: #08083D;
        margin: 0;
    }
    .about-property p span.detailAns {
        color: #6184D8;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .about-property p span.citiesSpan{
        color: #6184D8;
    }
    .about-property p span.citiesSpan span{
      color: #6184D8;
    }
    .about-property p span.detailAns span{
      font-family: "GoogleSans-Regular";
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #6184D8;
    }
    .about-property p:nth-child(2n) {
        margin-right: 45px;
    }
    .descriptionBox {
      width: 100%;
      float: left;
      margin: 20px 0 10px;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .descriptionBox .shw-btns {
        width: 100%;
        float: left;
        padding-top: 15px;
    }
    .descriptionBox p {
      width: 100%;
      float: left;
      color: #08083D;
      font-size: 16px;
      margin: 0;
      white-space: pre-wrap;
      font-family: "Roboto-Medium";
    }
    .featuresBox {
      width: 100%;
      float: left;
      padding-bottom: 25px;
      border-bottom: 1px solid #ddd;
    }
    .about-property p > span {
      color: #08083D;
      font-size: 16px;
      padding-right: 5px;
      font-family: "Roboto-Medium";
    }
    .investTables{
      width: 100%;
      float: left;
    }
    .af-tablee caption {
        background-color: #6184D8;
        color: white;
        padding: 10px;
        text-align: left;
        caption-side: top;
        font-family:"Roboto-Medium";
    }

    .af-tablee {
        width: calc(50% - 7.5px);
        float: left;
        margin-bottom: 25px;
    }
    .investTables .af-tablee:nth-child(2n + 1){
    margin-right: 15px;
    }
    .af-tablee table {
        width: 100%;
        float: left;
    }

    .af-tablee td {
        border: 1px solid #d9d8d7;
        padding: 10px;
        text-align: left;
        font-family:"Gilroy-Light";
    }

    .af-tablee th {
        border: 1px solid #d9d8d7;
        padding: 10px;
        font-family:"Roboto-Medium";
    }
    .price-detail .af-price {
      display: inline-block;
      float: left;
      color: #424242;
      font-family:"Roboto-Medium";
      font-size: 16px;
      padding: 0;
      width: 100%;
      text-align: center;
    }
    .price-detail .af-price .currency {
      font-size: 24px;
      text-transform: uppercase;
    }
    .price-detail .af-project-price {
        font-size: 34px;
        float: left;
        font-family:"Roboto-Medium";
        color: #222;
    }
    .price-detail .af-project-price .currency {
        font-size: 24px;
        text-transform: uppercase;
    }
    .price-detail {
        padding: 0px 0px ;
        width: 100%;
        float: left;
    }
    .contact-btn {
        display: none;
    }
    .modal-dialog.ProjectCarousel {
        width: 100%;
        max-width: 100%;
        height: 100vh;
        margin: 0;
        overflow: hidden;
    }
    .ProjectCarousel .modal-body {
        height: 100vh;
        padding: 0 52px 0 0;
        position: relative;
    }
    div#ProjectCarouselModal {
        padding-right: 0px !important;
    }

    .ProjectCarousel .modal-content {
        border-radius: 0px;
    }
    .leftMainCarousel {
        width: calc(100% - 385px);
        float: left;
        display: inline-block;
        padding: 65px;
    }
    .rightSmallCarousel {
        width: 385px;
        float: left;
        display: inline-block;
        padding-top: 150px;
    }
    .leftMainCarousel img {
      max-width: 71%;
      margin: auto;
      height: 75vh;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 16px;
      max-height: 75vh;
      object-position: center;
    }
    .rightSmallCarousel img {
        border-radius: 6px;
        opacity: 0.4;
        max-height: 70px;
        min-height: 70px;
        width: 100%;
    }
    .rightSmallCarousel .swiper-slide-thumb-active img{
      opacity: 1;
      border: 2px solid #08083D;
    }
    .new-design .wd-100 .play-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        height: 47px;
        z-index: 999;
    }
    .new-design .wd-100 .play-btn span {
        width: 74px;
        height: 47px;
        background-color: rgba(0,0,0,.5);
        position: relative;
        float: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        vertical-align: middle;
        align-content: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
    }
    .image-bannerDesign a:hover .play-btn span {
        background-color: #f00;
    }

    .image-bannerDesign .play-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        height: 47px;
        z-index: 999;
    }
    .image-bannerDesign .play-btn span {
        width: 74px;
        height: 47px;
        background-color: rgba(0,0,0,.5);
        position: relative;
        float: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        vertical-align: middle;
        align-content: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
    }
    .image-bannerDesign a:hover .play-btn span {
        background-color: #f00;
    }


    .leftMainCarousel .owl-carousel .owl-item iframe {
        max-width: 100%;
        margin: auto;
        height: 75vh;
        border-radius: 16px;
        max-height: 75vh;
        width: 100%;
    }
    .leftMainCarousel video {
        max-width: 100%;
        margin: auto;
        height: 75vh;
        max-height: 75vh;
        width: 100%;
    }
    .leftMainCarousel .bannerVideoPro {
      max-width: 100%;
      margin: auto;
      height: 75vh;
      -o-object-fit: cover;
      border-radius: 16px;
      max-height: 75vh;
      width: 71%;
    }
    .leftMainCarousel .swiper-slide{
      text-align: center;
    }
    .rightSmallCarousel .swiper-wrapper .swiper-slide {
        border-radius: 6px;
    }

    .owl-custom-thumbnails .owl-item.active.current img {
        opacity: 1;
    }
    .ProjectDetails h3 {
        font-size: 18px;
        font-family: "GoogleSans-Regular";
        margin: 0;
        padding: 0;
    }
    .ProjectDetails p {
        font-size: 20px;
        font-family: "GoogleSans-Medium";
        margin-bottom: 16px;
    }
    .ProjectDetails {
        padding: 40px 0 0px;
    }
    .ProjectCarousel button.close {
      font-size: 60px;
      position: absolute;
      top: 65px;
      right: 25px;
      padding: 32px;
      z-index: 99;
      color: #484848;
      opacity: 1;
    }
    .ProjectCarousel.newDesign button.close {
        font-size: 60px;
        position: absolute;
        top: 0;
        left: 25px;
        padding: 32px;
        z-index: 99;
        color: #484848;
        opacity: 1;
    }
    .owlItemCounts {
        width: 100%;
        float: left;
        text-align: center;
        padding: 30px 0 0;
    }
    .owlItemCounts p {
        font-family:"Roboto-Medium";
        font-size: 18px;
        width: 100%;
        float: left;
        margin: 0;
        letter-spacing: 3px;
        bottom: unset;
    }
    .newDesign .leftMainCarousel {
        width: 100%;
        float: left;
        display: inline-block;
        padding: 65px;
    }
    .newDesign .leftMainCarousel .owl-nav .owl-prev {
        position: absolute;
        height: 58px;
        width: 58px;
        left: 20px;
        top: 0;
        bottom: 0;
    }
    .newDesign .leftMainCarousel .owl-nav .owl-next {
        position: absolute;
        height: 58px;
        width: 58px;
        right: 20px;
        top: 0;
        bottom: 0;
    }
    .newDesign .leftMainCarousel .owl-nav button span {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 50px !important;
        color: #000;
        border-radius: 50%;
        line-height: 0;
        text-align: center;
        padding: 24px 0;
        border: 1px solid #ddd;
        font-family: GoogleSans-Regular;
    }
    .newDesign .leftMainCarousel .owl-carousel .owl-nav button span {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 50px !important;
        color: #000;
        border-radius: 50%;
        line-height: 0;
        text-align: center;
        padding: 24px 0;
        border: 1px solid #ddd;
        font-family: GoogleSans-Regular;
    }
    .newDesign .leftMainCarousel .owl-nav button:hover span {
        background-color: #eee;
    }
    .rightSmallCarousel .swiper-wrapper {
        padding: 30px 0 0;
    }
    .image-bannerDesign a.forOverlay:hover .projectBannerOverlay {
        opacity:0;
        transition: 0.4s all ease;
    }
    .image-bannerDesign a.forOverlay:not(:hover) .projectBannerOverlay {
        opacity:1;
        transition: 0.4s all ease;
    }
    .projectBannerOverlay {
        position: absolute;
        background-color: rgba(0,0,0,0.3);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        transition: 0.4s all ease;
    }
    .leftMainCarousel .owl-carousel .owl-nav button.owl-next span {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
        display: block;
        font-size: 70px;
    }
    .leftMainCarousel .swiper-button-next, .leftMainCarousel .swiper-button-prev {
        display: block;
        box-shadow: none;
        background-color: transparent;
    }

    .leftMainCarousel .swiper-button-prev:after, .leftMainCarousel .swiper-button-next:after {
        font-size: 26px;
    }
    .leftMainCarousel .swiper-button-next, .leftMainCarousel .swiper-button-prev {
    width: 60px;
    height: 60px;
    background-color: #6184D8;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    display: flex;
    }
    .leftMainCarousel .owl-theme .owl-nav {
        position: absolute;
        left: 0;
        right: 0;
        top: -22%;
        bottom: 0;
        margin: auto;
        width: 100%;
    }
    .leftMainCarousel .owl-theme .owl-nav [class*=owl-]:hover {
        color: #000;
    }
    .no-padd {
        padding: 0 !important;
    }
    .fullPageOverlay.af-display {
        display: block;
    }
    .fullPageOverlay.af-searchDisplay{
        display: block;
        z-index: 99999;
    }
    .fullPageOverlay {
        position: fixed;
        z-index: 9999999999;
        background-color: rgba(0,0,0,0.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: none;
    }
    .af-stickyProject{
      position: sticky;
      top: 58px;
      z-index: 999;
    }
    .af-stickyProject.zIndexMax {
        z-index: 9999999999;
    }
    .overflow-hidden {
        overflow: hidden !important;
    }
    section.full-box {
        padding: 40px 0;
    }
    section.full-box.paddingBott {
        padding: 40px 0 100px;
    }
    .projectView.detailProperty .projectViewContent p {
      padding: 0;
    }
    .navbar-light .navbar-toggler {
      background-color: transparent;
      padding: 10px 10px 0;
    }
    .navbar-light .navbar-toggler-icon{
    background-image: none !important;
    }
    .navbar-light .navbar-toggler-icon {
      width: 30px;
      height: 2px;
      background-color: #fff;
      margin: 0 0 5px;
      display: block;
    }
    .swiper-button-next, .swiper-button-prev {
      width: 45px;
      height: 45px;
      color: #fff;
      background-color: #6184D8;
      border-radius: 50%;
      box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
      display: flex;
      outline: none;
    }
    .swiper-button-next:after, .swiper-button-prev:after {
      font-size: 14px;
      font-weight: 700;
    }

    .swiper-pagination-bullet {
      background: #C2C2C2;
      opacity: .8;
    }
    .swiper-pagination-bullet-active {
      background: #fff;
      opacity: 1;
    }

    .guideBlogTemp {
      width: 100%;
      float: none !important;
      margin: 20px 0 0px;
      padding-bottom: 20px;
    }

    .guideBlogTemp ul.breadcrumb {
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    .guideBlogTemp ul.breadcrumb a {font-family: "Gilroy-Light";
      color: #08083D;
      font-size: 14px;
    }

    .guideBlogTemp ul.breadcrumb p {
      font-family: "Gilroy-Light";
      color: #777;
      display: inline-block;
      margin: 0;
      font-size: 14px;
    }
    .guideTemplateBody {
      width: 100%;
      float: left;
      padding: 60px 60px 30px;
      border: 1px solid #dadce0;
      border-radius: 8px;
      margin-bottom: 50px;
    }
    .guideTemplateBody h1 {
        font-size: 28px;
        line-height: 32px;
        color: #142b40;
        margin: 0px 0 15px;
        padding: 0;
        font-family: "Gilroy-ExtraBold";
    }
    .guideTemplateBody img {
        width: 100%;
        margin: 10px 20px 20px 0;
        padding: 0 70px 0 0;
    }
    .guideTemplateBody p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
        font-family: "Gilroy-Light";
        color: #626e7a;
    }
    .guideTemplateBody b, .guideTemplateBody strong {
        font-family: "Roboto-Medium";
        color: #142b40;
        font-weight: 500;
    }
    .guideTemplateBody p i {
        font-weight: 600;
    }
    .guideTemplateBody h2 {
        font-family: "Gilroy-ExtraBold";
        color: #08083d;
        margin: 10px 0;
        font-size: 22px;
    }

    .guideTemplateBody blockquote {
        margin: 20px 0;
        line-height: 26px;
        background: #f2f1f0;
        padding: 20px;
        border-left: 5px solid #08083d;
        color: #626e7a;
        font-family: "Roboto-Medium";
    }

    .guideTemplateBody blockquote i {font-style: normal;}

    .guideTemplateBody h3 {
        font-family: "Gilroy-ExtraBold";
        color: #08083d;
        margin: 10px 0;
        font-size: 20px;
    }
    .guideTemplateBody ol li {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    font-family: Gilroy-Light;
    color: #626e7a;
    position: relative;
    }
    .guideTemplateBody ul li {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
        font-family: "Gilroy-Light";
        color: #626e7a;
        position: relative;
        padding-left: 20px;
    }

    .guideTemplateBody ul li:before {
        content: "\f0c8";
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        font-size: 10px;
        color: #08083d;
        position: absolute;
        top: 0;
        left: 0;
    }

    .guideTemplateBody ul {
        list-style: none;
        padding: 0 0 0 10px;
    }
    .blogTable {
    width: 100%;
    float: left;
    }

    .blogTable table {
        border: 1px solid #ebebeb;
        margin-bottom: 25px;
        width: 100%;
    }

    .blogTable table td {
        padding: 10px;
        font-family: "Gilroy-Light";
        border: 1px solid #ebebeb;
    }

    .ezTestimonials .swiper-button-next,
    .ezTestimonials .swiper-button-prev {
        width: 45px;
        height: 45px;
        color: #fff;
        background-color: #6184D8;
    }
    .ezTestimonials .swiper-button-next {
    right: 100px;
    }
    .ezTestimonials .swiper-button-prev {
    left: 100px;
    }

    @media (max-width: 1400px) {
      .about-property p {
      width: calc(50% - 23px);
      }
      .about-property p > span{
      font-size: 16px;
      }

    }
    @media (max-width: 1200px) {
      .about-property p > span {
      font-size: 14px;
      }
      .about-property p:nth-child(2n) {
      margin-right: 20px;
      }
      .about-property p {
      width: calc(50% - 10px);
      }
      .descriptionBox p{
      font-size: 16px;
      }
      .guideTemplateBody{
      padding: 40px 30px 30px;
      }
    }

.rippleAnim.home {
    position: absolute;
    left: auto;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(100%) translateY(35%);
    width: 40px;
    height: 40px;
    margin-left: -.5rem;
    z-index: 99;
}
.rippleAnim.header {
    position: absolute;
    left: auto;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-163%) translateY(-50%);
    width: 30px;
    height: 29px;
    margin-left: -.5rem;
}

.rippleAnim:before, .rippleAnim:after {
    content: "";
    display: block;
    border-radius: 50%;
}

.rippleAnim:before {
    position: relative;
    width: 150%;
    height: 150%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    background-color: #6df52f;
    animation: rippleEffect 1.25s cubic-bezier(.215,.61,.355,1) infinite;
}

.rippleAnim:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: green;
    -webkit-box-shadow: 0 0 12.8px rgba(0,0,0,.3);
    box-shadow: 0 0 12.8px rgba(0,0,0,.3);
    -webkit-box-shadow: 0 0 0.8rem rgba(0,0,0,.3);
    box-shadow: 0 0 0.8rem rgba(0,0,0,.3);
    animation: rippleEffect 1.25s cubic-bezier(.455,.03,.515,.955) -.4s infinite;
    opacity: 1%;
}
.rippleAnim.fixed {
    position: absolute;
    left: auto;
    top: 50%;
    transform: translateX(0%) translateY(-50%);
    width: 70px;
    height: 70px;
    z-index: 99999;
}

@media (max-width: 767px) {
  .rippleAnim.home {
  transform: translateX(74%) translateY(20%);
  }
  .guideTemplateBody img {
  padding: 0;
  }
}

@keyframes move {
  0%  { top: -595px; }
  13.3% { top: -515px; }
  26.6% { top: -425px; }
  39.9% { top: -340px; }
  53.1% { top: -255px; }
  66.4% { top: -170px; }
  78.6% { top: -85px; }
  90.8% {top: 0px; }
  99.99% {top: 0px; }
  100% { top: -595px; }
}
@keyframes storieBackAnimate {
  0%{
    background-position: 0 0;
  }
  100%{
    background-position: 0 150%;
  }
}
@keyframes rippleEffect{
  0%{
    transform:scale(.33);
  }
  80%, to{opacity:0}
}


.customDatePicker {
    width: 100%;
    float: left;
}

.customDatePicker bs-datepicker-inline {
    width: 100%;
}

.customDatePicker .bs-datepicker {
    width: 100%;
    float: left;
    background-color: transparent;
    box-shadow: none;
}

.customDatePicker bs-datepicker-inline-container {
    width: 100%;
}
.customDatePicker .bs-datepicker-container{
    padding: 0;
    width: 100%;
}
.customDatePicker bs-days-calendar-view {
    width: 100%;
}
.customDatePicker .theme-green .bs-datepicker-head {
    background-color: transparent;
    text-align: left;
    padding: 0;
    height: 40px;
    border-bottom: 1px solid #C2C2C2;
}
.customDatePicker .theme-green .bs-datepicker-head button{
    color: #08083D;
    font-family: "Roboto-Regular";
}
.customDatePicker .theme-green .bs-datepicker-head button.previous {
    position: absolute;
    right: 50px;
}
.customDatePicker .theme-green .bs-datepicker-head button.next {
    position: absolute;
    right: 0px;
}
.customDatePicker .theme-green .bs-datepicker-head button.current {
  padding: 0;
}
.customDatePicker .bs-datepicker-body{
  border: none;
  font-family: "Roboto-Regular";
  padding: 10px 0;
}
.customDatePicker .bs-datepicker-body table td {
    color: #08083D;
}
.customDatePicker .theme-green .bs-datepicker-body table td span.selected{
    background-color: #6184D8;
}
.customDatePicker bs-years-calendar-view{
   width: 100%;
}
.customDatePicker bs-month-calendar-view{
   width: 100%;
}
.customDatePicker .bs-datepicker-body table th {
    color: #08083D;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    background-color: transparent !important;
}

/* new style start */
.filepond--wrapper {
    min-height: 100px;
    background-color: #fff;
}
.filepond--root {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 16px;
    margin: 0 auto;
}
.filepond--root .filepond--drop-label {
    min-height: 190px;
    background-color: #f9fafb;
    color: #4c4e53;
}
.filepond--panel-root {
    background-color: #edf0f4;
}
/* new style end */





@media (max-width: 1440px) {
    .blogTable table td {
        width: 17.5%;
    }
    .blogTable table td:first-child {
        width: 30%;
    }
}

@media (max-width: 767px) {
    .blogTable table td {
        width: auto;
    }
    .blogTable table td:first-child {
        width: auto;
    }
}
