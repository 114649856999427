/********************** Compaign Page ***********************/
.compaignpage {
	max-width: 1200px;
	padding: 78px 0px 0;
	margin: 0 auto;
}

/********************** Main Wrap **************************/
.main-wrapper {
	background-color: #F7F8FB;
	height: 100%;
	width: calc(100% - 190px);
	margin-left: auto;
}

.flexdiv {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.main-wrapper .head {
	color: #242328;
	margin-bottom: 16px;
	font-size: 20px;
	font-family: inherit;
	font-weight: 600;
}

.compaignpage .firstsection {
	width: 50%;
}

.compaignpage .secondsection {
	width: 50%;
}

.compaignpage .secondsection button {
	background-color: #1E43FF;
	width: 240px;
	height: 32px;
	border-radius: 5px;
	color: #fff;
	border: 0px;
	font-size: 14px;
	line-height: 10px;
}

.dropoptions {
	display: flex;
}

.dropoptions span {
	color: #9196B6;
	font-size: 16px;
	font-family: inherit;
}

.dropoptions .dropbtn {
	background-color: transparent;
	border: 0px;
	font-size: 16px;
	outline: none;
}

.allcompaign {
	margin-right: 15px;
}

.dropoptions .dropbtn.dropdown-toggle::after {
	display: inline-block;
	margin-left: .3em;
	vertical-align: .255em;
	content: "";
	border: 0px;
	background-image: url(../images/arrow-point-to-right.svg);
	width: 9px;
	height: 5px;
	background-size: contain;
	background-repeat: no-repeat;
	position: relative;
	top: 2px;
}

.searchbar {
	position: relative;
	text-align: right;
	margin-top: 10px;
}

.searchinput {
	display: inline-block;
	width: 30px;
	opacity: 0;
	transition: width .15s ease-in, opacity .15s ease-in;
}

.searchinput input {
	width: 100%;
	border-radius: 5px;
	background-color: #fff;
	border: none;
	padding: 0 12px;
	margin: 0;
	height: 30px;
	display: inline-block;
	text-align: start;
	font-size: .8rem;
	outline: none;
}

.compstatus {
	color: #9196B6;
	font-size: 16px;
	font-family: inherit;
}

.searchinput.showinput {
	opacity: 1;
	width: 320px;
}

.searchicon {
	position: absolute;
	height: 30px;
	width: 30px;
	top: 0px;
	right: 0px;
	cursor: pointer;
	text-align: center;
	border-radius: 5px;
}

.searchicon:hover {
	background-color: #fff;
}

.fa-icon {
	display: inline-block;
	fill: #7D8BA9;
}

.searchportion {
	text-align: center;
	margin-top: 150px;
}

.searchportion img {
	width: 500px;
}

.searchportion .head {
	color: #4270FC;
	font-size: 18px;
	font-family: inherit;
	font-weight: 600;
}

.midsearch {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 50px;
}

.midsearch input {
	width: 315px;
	height: 40px;
	border: 1px solid #70707070;
	border-radius: 5px;
	margin-right: 8px;
	color: #7D8BA98C;
	font-size: 14px;
	padding-left: 22px;
	font-family: inherit;
}

.midsearch button {
	width: 101px;
	height: 40px;
	background: #1E43FF 0% 0% no-repeat padding-box;
	border-radius: 5px;
	color: #fff;
	border: 0px;
	font-size: 14px;
	font-family: inherit;
}

.createcompaign {
	width: 800px;
	height: 100%;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #fff;
	box-shadow: -10px 3px 19px #00000029;
	text-align: center;
	padding-top: 140px;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.createcompaign .head {
	color: #4270FC;
	font-size: 19px;
	font-family: inherit;
	font-weight: 600;
}

.createcompaign img {
	width: 520px;
	margin: 0px auto;
}

.createcompaign .closebtn {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: absolute;
	line-height: 13px;
	text-align: center;
	left: -30px;
	top: 15px;
	background-color: #FFFFFF;
	border: 0px;
	color: #7D8BA9;
	font-size: 12px;
}

.shadowd {
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
}

/************************** Goals ****************************/

.main-wrapper.creategoal {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding: 12.1rem 0rem;
	flex-direction: column;
	justify-content: center;
}

.main-wrapper.creategoal .mainimg {
	width: 390px;
	height: 390px;
	background: #F0F1F7 0% 0% no-repeat padding-box;
	border-radius: 50%;
	text-align: center;
	padding-top: 35px;
}

.main-wrapper.creategoal h5 {
	color: #9196B6;
	font-size: 32px;
	font-weight: 600;
	font-family: inherit;
	letter-spacing: 0.1px;
	margin-top: 15px;
}

.main-wrapper.creategoal p {
	color: #9196B6;
	font-size: 18px;
	font-family: inherit;
	letter-spacing: 0.05px;
}

.main-wrapper.creategoal button {
	font-size: 15px;
	color: #fff;
	font-family: inherit;
	border-radius: 5px;
	width: 148px;
	height: 32px;
	background-color: #1E43FF;
	border: 0px;
	margin-top: 50px;
	line-height: 15px;
}

.modalgoal .goalbtn {
	width: 142px;
	height: 32px;
	background: #1E43FF 0% 0% no-repeat padding-box;
	border-radius: 5px;
	font-size: 14px;
	color: #fff;
	line-height: 16px;
	border: 0px;
	margin-left: 36px;
}

.modalgoal .cancelbtn {
	background-color: transparent;
	border: 0px;
	color: #7D8BA9;
	height: 32px;
	border-bottom: 1px solid #7D8BA9;
}

.modalgoal .modal-header {
	text-align: right;
	align-items: center;
	justify-content: flex-end;
	border: 0px;
	padding: 0px;
}

.modalgoal .modal-content {
	background-color: #fff;
	border-radius: 20px;
	border: 0px;
	padding: 45px 38px 80px 110px;
}

.modalgoal .goalform {
	margin-top: 60px;
}

.modalgoal .goalform h3 {
	font-weight: 600;
	font-family: inherit;
	font-size: 24px;
	color: #242328;
}

.modalgoal .goalform p {
	color: #7D8BA9;
	font-size: 16px;
}

.modalgoal .goalform .inner-goal {
	margin-top: 50px;
}

.modalgoal .goalform .inner-goal label {
	color: #242328;
	font-size: 16px;
}

.modalgoal .goalform .inner-goal label.bold {
	font-weight: 600;
}

.modalgoal .goalform .inner-goal label span {
	color: #1E43FF;
}

.modalgoal .goalform .inner-goal label img {
	margin-left: 5px;
}

.modalgoal .goalform .inner-goal .ng-select-container {
	border: 0px;
	padding-bottom: 5px;
	border-bottom: 1px solid #9196B6;
	border-radius: 0px;
}

.modalgoal .goalform .inner-goal .ng-select-container .ng-value-container {
	padding-left: 0px;
}

.modalgoal .goalform .inner-goal .ng-select-container .ng-value-container .ng-input {
	padding-left: 0px;
}

.modalgoal .goalform .inner-goal .ng-select.ng-select-focused {
	outline: none;
	box-shadow: none;
	border: 0px;
}

.modalgoal .goalform .inner-goal .ng-select .ng-value-label,
.modalgoal .goalform .inner-goal .ng-select .ng-placeholder {
	color: #7D8BA9;
	font-size: 16px;
	font-weight: 600;
}

.modalgoal .goalform .inner-goal .ginput {
	padding-bottom: 15px;
	border-bottom: 1px solid #9196B6;
}

.modalgoal .goalform .inner-goal .ginput input {
	border: 0px;
	width: 100%;
	color: #7D8BA9;
	font-size: 25px;
	font-weight: 600;
	outline: none;
}

.modalgoal .goalform .inner-goal .ginput input::placeholder {
	color: #7D8BA9;
}

.modalgoal .goalform .inner-goal .ginput.conversion {
	position: relative;
}

.modalgoal .goalform .inner-goal .ginput.conversion::before {
	content: "$";
	position: absolute;
	left: 0px;
	color: #242328;
	font-size: 25px;
	font-weight: 600;
	top: 1px;
}

.modalgoal .goalform .inner-goal .ginput.conversion input {
	padding-left: 25px;
}

.description-sec {
	margin-top: 30px;
}

.description-sec button {
	background: transparent;
	border: 0px;
	color: #1E43FF;
	font-size: 16px;
	padding: 0px;
	outline: none;
	margin-bottom: 20px;
}

.main-wrapper.p-goal {
	height: 100vh;
}

.scrollgoal {
	height: 100%;
	overflow-y: visible;
}

.main-wrapper.p-goal .goalsdiv {
	max-width: 1200px;
	margin: 0px auto;
	margin-top: 25px;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv {
	width: 384px;
	height: 181px;
	box-shadow: 0px 3px 16px #00000029;
	border-radius: 20px;
	padding: 25px 35px 20px 40px;
	position: relative;
	background-color: #fff;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv h3 {
	color: #242328;
	font-size: 20px;
	font-weight: 600;
	margin-top: 0px;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv p {
	color: #9196B6;
	font-size: 14px;
	margin-bottom: 10px;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv button {
	width: 77px;
	height: 25px;
	border: 0px;
	background-color: #F0F1F7;
	border-radius: 12px;
	color: #7D8BA9;
	font-size: 13px;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv p.cust {
	margin-bottom: 0px;
	margin-top: 20px;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv:hover .cust-dropdown {
	display: block;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv .cust-dropdown {
	position: absolute;
	top: 0px;
	right: 30px;
	display: none;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv .cust-dropdown button {
	background-color: transparent;
	width: auto;
	height: auto;
	font-size: 24px;
	font-weight: bolder;
	outline: none;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv .cust-dropdown button::after {
	display: none;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv .cust-dropdown .dropdown-menu {
	min-width: 85px;
	padding: 2px;
	border: 0px;
	right: 0px;
	left: auto !important;
	box-shadow: 0px 3px 16px #00000029;
}

.main-wrapper.p-goal .goalsdiv .inner-goaldiv .cust-dropdown .dropdown-menu button {
	font-size: 14px;
	font-weight: normal;
	padding: 2px 10px;
}

.modalgoal .ginput2 {
	display: flex;
	border: 1px solid #9196B6;
	border-radius: 3px;
	position: relative;
	overflow: hidden;
	margin-top: 35px;
}

.modalgoal .ginput2 select {
	background-color: #FCFCFC;
	border: 0px;
	border-right: 1px solid #9196B6;
	width: 130px;
	color: #9196B6;
	font-size: 14px;
	border-radius: 0px;
}

.modalgoal .ginput2 input {
	border: 0px;
	width: 100%;
	padding: 0px 10px;
	color: #9196B6;
	font-size: 14px;
}

.modalgoal .ginput2 input::placeholder {
	color: #9196B66B;
}

.modalgoal .ginput2 input:focus {
	outline: none;
}

.modalgoal .ginput2 .removebtn {
	background-color: #9196B6;
	width: 140px;
	height: 31px;
	margin: 2px;
	color: #fff;
	font-size: 14px;
	border: 0px;
	border-radius: 3px;
	position: absolute;
	right: 0;
	outline: none;
}

.modalgoal .ginput2 input:focus~.removebtn {
	display: block;
}

.modalgoal .ginput2.none~.tog-advance button {
	opacity: 0;
}

.modalgoal .ginput2~.tog-advance button {
	opacity: 1;
}

.modalgoal .ginput2+.ginput2 {
	margin-top: 20px;
}


@media (min-width: 992px) {
	.modal-dialog {
		max-width: 860px;
	}
}

/************************ Toggle Switch **********************/

.tog-advance {
	margin-top: 10px;
	align-items: center;
	justify-content: space-between;
	color: #BCC4D3;
}

.tog-advance button {
	background-color: transparent;
	border: 0px;
	color: #1E43FF;
	float: left;
	font-size: 16px;
	padding: 0px;
	outline: none;
	opacity: 0;
}

.switch {
	position: relative;
	display: inline-block;
	width: 55px;
	height: 22px;
	margin-bottom: 0px;
	margin-right: 5px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #D8D9E2;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 3px;
	bottom: 1.5px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: #28D867;
}

input:focus+.slider {
	box-shadow: 0 0 1px #28D867;
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
	left: 7px;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}


/************************ Compaign Edit **********************/
.main-wrapper.editcompaign {
	height: 100vh;
}

.topeditoptions {
	padding: 25px 20px;
	background-color: #fff;
}

.topeditoptions .backtop {
	width: 20px;
	height: 20px;
	background-color: #F0F1F7;
	border-radius: 50%;
	border: 0px;
	outline: none;
	position: relative;
	top: 3px;
}

.topeditoptions label {
	margin-bottom: 0px;
	font-size: 20px;
	color: #242328;
	font-weight: 600;
	margin-left: 30px;
}

.topeditoptions .backtop img {
	position: relative;
	top: -5px;
}

.topeditoptions .right-side-btn {
	text-align: right;
}

.topeditoptions .right-side-btn button {
	background-color: #1E43FF;
	border: 0px;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	padding: 6px 15px;
	margin-left: 39px;
}

.topeditoptions .right-side-btn button.greybtn {
	color: #9196B6;
	background-color: #F7F8FB;
	padding: 6px 20px;
}

.compaigntabs {
	padding-bottom: 30px;
	background-color: #f7f8fb;
}

.compaigntabs ul.nav-tabs {
	border-bottom: 0px;
}

.compaigntabs ul.nav-tabs li a {
	background-color: #fff;
	border: 0px;
	color: #9196B6;
	font-size: 16px;
	font-weight: 600;
	padding: 15px 0px;
}

.compaigntabs ul.nav-tabs li a.active {
	background-color: transparent;
	color: #1E43FF;
	border: 0px;
}

.compaigntabs ul.nav-tabs li a label {
	width: 30px;
	height: 30px;
	background-color: #F0F0F5;
	color: #9196B6;
	border-radius: 50%;
	line-height: 28px;
	margin-right: 10px;
}

.compaigntabs ul.nav-tabs li a.active label {
	background-color: #1E43FF;
	color: #fff;
}

.compaigntabs .goaltab {
	margin: 0px 35px;
	margin-top: 60px;
	background-color: #fff;
	border-radius: 10px;
}

.compaigntabs .tab-content .setgoals.new {
	text-align: center;
	padding-bottom: 120px;
}

.compaigntabs .tab-content .setgoals h3 {
	font-size: 32px;
	font-weight: 600;
	padding: 35px 0px;
	color: #242328;
}

.compaigntabs .tab-content .setgoals p {
	color: #9196B6;
	font-size: 16px;
}

.compaigntabs .tab-content .setgoals .dashed-sec {
	width: 90%;
	margin-top: 60px;
	height: 360px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	z-index: 1;
	display: none;
}

.compaigntabs .tab-content .setgoals .dashed-sec:before {
	content: "";
	position: absolute;
	border: 2px dashed #9196B6;
	top: -3px;
	bottom: -3px;
	left: -3px;
	right: -3px;
	border-radius: 10px;
	z-index: -1;
}

.compaigntabs .tab-content .setgoals .dashed-sec button {
	background-color: transparent;
	border: 0px;
	color: #1E43FF;
	border-bottom: 1px solid #1E43FF;
	font-size: 15px;
	margin-top: 50px;
}

.compaigntabs .tab-content .setgoals .dashed-sec .innercircle {
	width: 163px;
	height: 163px;
	background-color: #F0F1F7;
	border-radius: 50%;
	margin: 50px auto;
}

.compaigntabs .tab-content .setgoals .dashed-sec .innercircle img {
	width: 100px;
	margin-top: 15px;
}

.compaigntabs .tab-content .setgoals.new .dashed-sec {
	display: block;
}

.compaigntabs .tab-content .setgoals.primary {
	text-align: center;
	position: relative;
	height: 600px;
}

.compaigntabs .tab-content .setgoals.primary .primary-sec button {
	background-color: transparent;
	border: 0px;
	color: #1E43FF;
	border-bottom: 1px solid #1E43FF;
	font-size: 15px;
	position: absolute;
	right: 70px;
	top: 40px;
}

.setgoals.primary .primary-sec .inner-sec {
	width: 85%;
	margin: 0px auto;
	text-align: left;
	margin-top: 70px;
}

.setgoals.primary .primary-sec .inner-sec h6 {
	color: #242328;
	font-size: 16px;
}

.setgoals.primary .primary-sec .inner-sec h6 img {
	margin-left: 5px;
	position: relative;
	top: -2px;
}

.setgoals.primary .primary-sec .inner-sec ul {
	padding: 0px;
	margin-top: 15px;
}

.setgoals.primary .primary-sec .inner-sec ul li {
	display: inline-block;
}

.setgoals.primary .primary-sec .inner-sec ul li a {
	padding: 2px 10px;
	border: 1px solid #C8CBDB;
	border-radius: 12px;
	font-size: 16px;
	margin-right: 15px;
	color: #C8CBDB;
}

.setgoals.primary .primary-sec .inner-sec ul li a:hover {
	background-color: #1E43FF;
	border-color: #1E43FF;
	color: #fff;
}

.datacapture {
	margin-top: 100px;
}

.datacapture h3 {
	color: #242328;
	font-size: 32px;
	font-weight: 600;
	text-align: center;
}

.autoleadcapture {
	width: 950px;
	margin: 0px auto;
	margin-top: 35px;
}

.autoleadcapture .captn {
	background-color: #1E43FF;
	color: #fff;
	font-size: 17px;
	border: 0px;
	border-radius: 5px;
	padding: 7px 55px;
}

.autoleadcapture .captn img {
	margin-right: 10px;
}

.capture-inner {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 14px #00000029;
	border-radius: 10px;
	margin-top: 25px;
	overflow: hidden;
}

.capture-inner h4 {
	color: #1E43FF;
	font-size: 20px;
	background-color: #EFF8FF;
	margin: 0px;
	padding: 19px 0px;
	padding-left: 33px;
}

.capture-inner .inner1 {
	padding: 60px 111px 60px 111px;
}

.capture-inner .inner1 p {
	color: #7D8BA9;
	font-size: 18px;
	margin-bottom: 20px;
}

.capture-inner .inner1 input {
	border: 1px solid #C8CBDB;
	border-radius: 3px;
	padding: 5px;
	width: 100%;
}

.inner1 .tog-advance {
	margin-top: 30px;
}

.inner1 .ginput2 {
	display: -webkit-box;
	display: flex;
	border: 1px solid #9196B6;
	border-radius: 3px;
	position: relative;
	overflow: hidden;
	margin-top: 0px;
}

.inner1 .ginput2.none~.tog-advance button {
	opacity: 0;
}

.inner1 .ginput2~.tog-advance button {
	opacity: 1;
}

.inner1 .orsec {
	margin: 8px 0px;
}

.inner1 .ginput2 select {
	background-color: #FCFCFC;
	border: 0px;
	border-right: 1px solid #9196B6;
	width: 130px;
	color: #9196B6;
	font-size: 14px;
	border-radius: 0px;
}

.capture-inner .inner1 .ginput2 input {
	border: 0px;
	width: 100%;
	padding: 0px 10px;
	color: #9196B6;
	font-size: 14px;
}

.capture-inner .inner1 .ginput2+.ginput2 {
	margin-top: 20px;
	width: 96%;
	overflow: visible;
}

.capture-inner .inner1 .removebtn {
	background-color: transparent;
	color: #C8CBDB;
	font-size: 24px;
	border: 0px;
	position: absolute;
	right: -30px;
	top: -2px;
}

.datacapture.datacapture2 {
	width: 950px;
	margin: 0px auto;
	margin-top: 48px;
}

.customalert {
	display: flex;
	justify-content: space-between;
	background-color: #F6E5E8;
	padding: 15px 35px;
	border-left: 3px solid #FC5774;
	margin-bottom: 20px;
}

.customalert p {
	color: #FC5774;
	font-size: 18px;
	font-weight: 600;
	margin: 0px;
	line-height: 30px;
}

.customalert button {
	color: #FC5774;
	font-size: 14px;
	border-radius: 5px;
	background-color: #fff;
	padding: 7px 15px;
	border: 0px;
}

.notifcation-sec {
	padding: 0px 35px;
}

.notifcation-sec .traffictop {
	margin-top: 60px;
	background-color: #F0F1F7;
	color: #69D1C5;
	font-size: 16px;
	font-weight: 600;
	width: 100%;
	border-radius: 10px;
	padding: 10px 0px;
	text-align: center;
}

.traffic-sec1 {
	border-radius: 10px;
	background-color: #fff;
	padding: 42px 75px 42px 46px;
	margin-top: 25px;
}

.traffic-sec1 .switch {
	margin-right: 15px;
}

.traffic-sec1 .switch input:checked+.slider {
	background-color: #1d43ff;
}

.traffic-sec1 .inner-varient .switch input:checked+.slider {
	background-color: #28DB67;
}

.traffic-sec1 h6 {
	color: #9196B6;
	font-size: 16px;
	font-weight: 600;
}

.traffic-sec1 h6.blue {
	color: #1E43FF;
}

.traffic-sec1 p {
	color: #C8CBDB;
	font-size: 16px;
	margin-top: 10px;
}

.traffic-sec1 img.smt {
	margin-left: 5px;
	width: 14px;
	position: relative;
	top: -2px;
}

ul.traffic-inner1 {
	list-style: none;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0px;
	align-items: center;
}

ul.traffic-inner1 li label {
	color: #9196B6;
	font-size: 16px;
	font-weight: 600;
}

ul.traffic-inner1 li button {
	border: 0px;
	width: 39px;
	height: 39px;
	background: #F0F1F7 0% 0% no-repeat padding-box;
	border-radius: 50%;
	line-height: 10px;
}

ul.traffic-inner1 li .progress {
	width: 430px;
	height: 2px;
	background-color: #C8CBDB;
}

ul.traffic-inner1 li .progress .progress-bar {
	background-color: #1E43FF;
}

.recent-input2 {
	display: flex;
	padding: 35px 40px 0px 44px;
	align-items: center;
}

.recent-input2 p {
	width: 50%;
	color: #242328;
	font-size: 16px;
	margin: 0px;
}

.recent-input2 p span {
	color: #1E43FF;
}

.display-options {
	padding: 22px 40px 20px 44px;
}

.display-options p {
	color: #242328;
	font-size: 16px;
}

.display-options p input {
	width: 41px;
	height: 34px;
	border: 1px solid #C8CBDB;
	border-radius: 6px;
	color: #9196B6;
	font-size: 16px;
	text-align: center;
	margin: 0px 10px;
}

.display-options ul {
	list-style: none;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.display-options ul li {
	color: #9196B6;
}

.display-options ul li .switch {
	margin-left: 10px;
}

.display-options ul li p {
	margin: 0px;
}

.display-options ul li p span {
	color: #1E43FF;
}


/********************* NG5 slider *******************************/

.ng5-slider.customrange .ng5-slider-pointer {
	width: 18px;
	height: 18px;
	top: -7px;
	background-color: #1E43FF;
}

.ng5-slider.customrange .ng5-slider-selection {
	background-color: #1E43FF !important;
}

.ng5-slider.customrange .ng5-slider-bar {
	background-color: #F0F1F7;
}

.ng5-slider.customrange .ng5-slider-pointer:after {
	display: none;
}

.ng5-slider.customrange .ng5-slider-bar-wrapper.ng5-slider-selection-bar {
	visibility: visible !important;
}

.ng5-slider.customrange {
	margin: 0px;
}


.traffic-inner1 button.savebtn {
	border: 1px solid #9196B6;
	border-radius: 5px;
	color: #9196B6;
	font-size: 14px;
	width: 71px;
	height: 32px;
	background-color: #fff;
}

.traffic-inner1 .customrange {
	width: 350px;
}

.traffic-inner1 .customrange,
.traffic-inner1 button.savebtn {
	display: none;
}

.traffic-inner1.edit .customrange,
.traffic-inner1.edit button.savebtn {
	display: block;
}

.traffic-inner1.edit .progress,
.traffic-inner1.edit button.editbtn {
	display: none;
}

.notifcation-sec .traffic-inner1 .effectbtn {
	display: none;
}

.notifcation-sec .traffic-inner1.edit .effectbtn {
	display: block;
}


.notifcation-sec button.addvarient {
	font-size: 18px;
	font-weight: 600;
	padding: 57px 0px;
	width: 100%;
	border-radius: 10px;
	background-color: #1E43FF;
	color: #fff;
	border: 0px;
	margin-top: 25px;
}

.notifcation-sec button.effectbtn {
	border: 0px;
	width: 39px;
	height: 39px;
	background: #F0F1F7 0% 0% no-repeat padding-box;
	border-radius: 50%;
	line-height: 10px;
	margin-right: 40px;
}

.notifcation-sec .progressli {
	display: flex;
	align-items: center;
}

.inner-varient {
	margin-top: 50px;
}

.inner-varient .notification {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 10px #00000029;
	border-radius: 10px;
	text-align: center;
	transition: 0.5s linear;
	min-height: 230px;
}

.inner-varient .notification ul {
	list-style: none;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 0px 15px;
	-webkit-box-align: center;
	align-items: center;
	padding-top: 15px;
}

.inner-varient .notification ul li p {
	margin: 0px;
	font-size: 14px;
	color: #4DE9A8;
	background: #F0FCF7 0% 0% no-repeat padding-box;
	border-radius: 11px;
	padding: 1px 11px;
	line-height: 18px;
}

.inner-varient .notification img {
	height: 63px;
}

.inner-varient .notification h3 {
	color: #1E43FF;
	font-size: 20px;
	font-weight: bold;
	margin-top: 15px;
}

.inner-varient .notification p {
	color: #C8CBDB;
	font-size: 16px;
	margin-bottom: 0px;
	padding-bottom: 20px;
}

.inner-varient .notification .btn-sec {
	background-color: #F0F1F7;
	border: 1px solid #C8CBDB;
	padding: 10px 0px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: none;
}

.inner-varient .notification .btn-sec button {
	border: 1px solid #C8CBDB;
	color: #9196B6;
	font-size: 16px;
	padding: 4px 18px;
	background-color: transparent;
	border-radius: 4px;
}

.inner-varient .notification.checked .btn-sec {
	display: block;
}

.inner-varient h2.mhead {
	color: #242328;
	font-size: 32px;
	font-weight: 600px;
	margin: 50px 0px;
	text-align: center;
}

.inner-varient .objectives {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 10px #00000029;
	border-radius: 10px;
	overflow: hidden;
}

.inner-varient .objectives h4 {
	color: #1E43FF;
	font-size: 20px;
	background-color: #EFF8FF;
	margin: 0px;
	padding: 8px 0px 8px 28px;
}

.inner-varient .objectives .inner-objective {
	padding: 20px 20px 10px 28px;
}

.inner-varient .objectives .inner-objective p {
	margin-top: 0px;
	color: #C8CBDB;
	font-size: 16px;
}

.inner-varient .objectives .inner-objective label {
	color: #242328;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 8px;
}

.inner-varient .objectives .inner-objective input {
	border: 1px solid #C8CBDB;
	width: 100%;
	border-radius: 3px;
	color: #7475a2;
	font-size: 16px;
	padding: 7px 10px;
}

.inner-varient .objectives .inner-objective input::placeholder {
	color: #C8CBDB;
}

.inner-varient .objectives .inner-objective p.last-obj {
	color: #242328;
	font-size: 14px;
	margin-top: 10px;
	display: flex;
}

.inner-varient .objectives .inner-objective p.last-obj label {
	font-weight: normal;
	margin: 0px;
}

.inner-varient .objectives .inner-objective p.last-obj label.container2 {
	top: 3px;
}

.objectives.appearance ul {
	list-style: none;
	margin: 0px;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 0px;
	align-items: center;
	margin-bottom: 14px;
}

.objectives.appearance ul li.lefttxt {
	font-size: 16px;
	color: #242328;
}

.objectives.appearance ul li.lefttxt span {
	color: #1E43FF;
	margin-left: 10px;
}

.objectives.appearance ul li {
	color: #9196B6;
	font-size: 14px;
	font-weight: 600;
	display: flex;
}

.objectives.appearance ul li .switch {
	margin: 0px;
	width: 45px;
	height: 20px;
	margin-left: 10px;
}

.objectives.appearance ul li .switch .slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 3px;
	bottom: 0.8px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.objectives.appearance ul li .switch input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	transform: translateX(26px);
	left: -2px;
}

.switch.mr-5 {
	margin-right: 5px !important;
}

.objectives.appearance ul li select {
	background-color: #F0F1F7;
	font-size: 14px;
	color: #9196B6;
}

.objectives.timing {
	margin-top: 40px;
}

.objectives.timing h4 {
	color: #242328;
}

.objectives.timing h4 span {
	color: #1E43FF;
}

.objectives.timing .inner-objective {
	padding: 30px 20px 30px 28px;
}

.objectives.timing .inner-objective p {
	color: #242328;
	margin-bottom: 20px;
}

.objectives.timing .inner-objective p input {
	width: 41px;
	height: 34px;
	border: 1px solid #C8CBDB;
	border-radius: 6px;
	text-align: center;
	margin: 0px 14px;
}

.objectives.language {
	margin-top: 40px;
}

.objectives.language .inner-objective {
	padding: 20px 20px 20px 28px;
}

.objectives.language h4 {
	padding-right: 18px;
}

.objectives.language h4 ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0px;
}

.objectives.language h4 .ptxt {
	color: #242328;
}

.objectives.language h4 ul li.languageswitch {
	color: #C8CBDB;
	font-size: 14px;
	font-weight: normal;
	display: flex;
	align-items: center;
}

.objectives.language h4 ul li.languageswitch label.switch {
	margin: 0px 10px;
}

.objectives.language .translate-sec {
	padding: 0px 15px 0px 15px;
}

.objectives.language .language-dropdown {
	margin-top: 20px;
	;
}

.language-dropdown label {
	color: #242328;
	font-size: 16px;
	display: block;
}

.objectives.language select {
	background-color: #F0F1F7;
	border: 1px solid #C8CBDB;
	color: #9196B6;
	font-size: 14px;
	border-radius: 3px;
}

.recentactivity .modal-dialog {
	width: 830px;
	margin: 0px;
	position: absolute;
	right: 0;
	height: 100%;
}

.recentactivity .modal-dialog .modal-content {
	border-radius: 0;
	border: 0px;
}

.recentactivity .modal-body {
	padding: 0px;
}

.recentactivity .mbtnclose {
	width: 19px;
	height: 19px;
	border-radius: 50%;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	color: #7D8BA9;
	border: 0px;
	position: absolute;
	left: -30px;
	top: 20px;
	padding: 0px;
}

.recentactivity .mbtnclose span {
	position: relative;
	top: -4px;
	font-size: 16px;
	left: 0px;
}

.recentactivity .inner-recent {
	background-color: #435B8C;
	padding: 20px 0px 30px 44px;
}

.recentactivity .inner-recent h4 {
	color: #fff;
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 10px;
}

.recentactivity .inner-recent p {
	color: #C8CBDB;
	font-size: 18px;
	margin-bottom: 0px;
}

.recentactivity .recentimg {
	margin-left: 30px;
}

.recentactivity .recent-input {
	padding-left: 44px;
	padding-right: 40px;
}

.recentactivity .recent-input label {
	color: #242328;
	font-size: 16px;
	font-weight: 600;
}

.recentactivity .recent-input input {
	width: 100%;
	border: 1px solid #C8CBDB;
	border-radius: 3px;
	font-size: 14px;
	padding: 10px 0px;
	padding-left: 15px;
}

.recentactivity .recent-input input::placeholder {
	color: #C8CBDB;
}

.recentactivity .headtxt {
	background-color: #F0F1F7;
	padding: 10px 0px 10px 44px;
	color: #242328;
	font-size: 20px;
	font-weight: 600;
	margin-top: 30px;
}

.recentactivity .headtxt span {
	font-size: 16px;
	color: #1E43FF;
}

.donebtn {
	padding: 15px 0px 38px 44px;
}

.donebtn button {
	border: 0px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	background-color: #1E43FF;
	border-radius: 5px;
	padding: 7px 15px;
}

.display-options hr {
	margin: 0px;
}


/******************** Dashboard - Getting Started page ***************************/


/*********************** Pricing page ***************************/


/*********************** End Pricing Page ***********************/


/*********************** How it Works Page ***********************/


/*********************** How it Works Page End ***********************/

/* The container */
.container2 {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 21px;
	border-radius: 50%;
	border: 1px solid #C8CBDB;
}

/* On mouse-over, add a grey background color */
.container2:hover input~.checkmark {
	background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked~.checkmark {
	background-color: #4DE9A8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container2 input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark:after {
	left: 7px;
	top: 3px;
	width: 5px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}


/************************** animation ************************/

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}


/***************************** Media Queries ********************************/

@media(max-width:1440px) {
	.compaignpage {
		max-width: 1000px;
	}

	ul.traffic-inner1 {
		padding: 0px;
	}

	ul.traffic-inner1 li .progress {
		width: 350px;
	}

	.traffic-sec1 {
		padding: 42px 40px 42px 46px;
	}

	.setgoals.primary .primary-sec .inner-sec ul li {
		margin: 5px 0px;
	}

	.traffic-inner1 .customrange {
		width: 260px;
	}

	.inner-varient .notification p {
		font-size: 14px;
		padding: 0px 10px;
	}

	.inner-varient .notification p br {
		display: none;
	}
}


/*--- New Dashboard Style Start---*/
/*--- Dashboard CSS Start ---*/
.dashboard-wrap {
	/* background: #E5E5E5; */
	min-height: 100vh;
}

.dashboard-wrap .head-title {
	width: 100%;
	left: 0;
	position: relative;
}

.dashboard-wrap .mobile-divider {
	padding: 100px 0 20px 0;
}

.dashboard-nav-mobile {
	transition: all ease 1s;
}

.dashboard-nav-mobile.show .navWrap {
	transition: all 0.3s ease-out;
	transform: translate(0%);
}

.dashboard-nav-mobile .navWrap {
	width: 256px;
	flex: 0 0 256px;
	height: 100vh;
	box-shadow: 0px 8px 18px rgba(207, 207, 207, 0.27);
	padding: 37px 0;
	border-radius: 0 8px 8px 0;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	position: fixed;
	/* visibility: hidden; */
	left: 0;
	top: 0;
	transform: translate(-100%);
	transition: all 0.2s ease-out;
	z-index: 111111;
}

.dashboard-nav-mobile .modal-backdrop {
	touch-action: none !important;
	-ms-touch-action: none !important;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 111;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100%;
	display: none;
}

.burger-menu {
	position: absolute;
	left: 29px;
	cursor: pointer;
}

.dashboard-nav-mobile.show .modal-backdrop {
	display: block;
}

.dashboard-nav {
	width: 256px;
	flex: 0 0 256px;
	height: 100vh;
	box-shadow: 0px 8px 18px rgba(207, 207, 207, 0.27);
	padding: 0px 0 37px;
	border-radius: 0 8px 8px 0;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 111;
}

.dashboard-row {
	display: flex;
}

.dashboard-right {
	max-width: 100%;
	width: 100%;
	flex: 0 0 100%;
	padding-left: 247px;
}

.main-content-wrap {
	max-width: 940px;
	width: 100%;
	margin: 0 auto;
	box-shadow: 0px 11px 17px rgb(207 207 207 / 30%);
	border-radius: 6px;
	margin-top: -32px;
}

.dashboard-detail {
	display: flex;
	align-items: center;
}

.desktop-hide {
	display: block;
}

.dasbhboard-header .user-detail,
.dasbhboard-content .user-detail {
	margin-left: 25px;
}

.dasbhboard-header .user-detail h3,
.dasbhboard-content .user-detail h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 6px;
}

.dasbhboard-header .user-detail p,
.dasbhboard-content .user-detail p {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
}

.dashboardNav-logo,
.dashboardNav-logo-mob {
	padding-bottom: 80px;
	padding-top: 77px;
	text-align: center;
}

.dashboard-menu,
.dashboard-menu-mob {
	height: calc(100vh - 180px);
	display: flex;
	flex-direction: column;
	position: relative;
}

.dashboard-menu li+li,
.dashboard-menu-mob li+li {
	margin-top: 12px;
	position: relative;
}

.dashboard-menu li:last-child::before,
.dashboard-menu-mob li:last-child::before {
	content: "";
	width: 80%;
	position: absolute;
	z-index: 11;
	border-top: 1px solid #EBF2FF;
	padding-top: 20px;
	margin: 0 auto;
	left: 50%;
	transform: translate(-50%, -24%);
}

.dashboard-menu a,
.dashboard-menu-mob a {
	display: flex;
	align-items: center;
	justify-content: left;
	transform: all ease 0.3s;
}

.dashboard-menu li.active a::after,
.dashboard-menu li:hover a::after,
.dashboard-menu-mob li.active a::after,
.dashboard-menu-mob li:hover a::after {
	content: "";
	position: absolute;
	z-index: 11;
	width: 8px;
	height: 100%;
	left: -18px;
	top: 0;
	background: #063088;
	border-radius: 0 8px 8px 0;
}

.dashboard-menu a span,
.dashboard-menu-mob a span {
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	padding-left: 10px;
	text-align: center;
	color: #464962;
	margin-top: 3px;
}

.dashboard-menu a:hover,
.dashboard-menu .active a,
.dashboard-menu-mob a:hover,
.dashboard-menu-mob .active a {
	background: #F3F4F8;
	border-radius: 4px 0px 0px 4px;
}

.dashboard-menu a svg path,
.dashboard-menu-mob a svg path {
	fill: #464962;
}

.dashboard-menu a:hover svg path,
.dashboard-menu a:hover span,
.dashboard-menu .active span,
.dashboard-menu .active a svg path,
.dashboard-menu-mob a:hover svg path,
.dashboard-menu-mob a:hover span,
.dashboard-menu-mob .active span,
.dashboard-menu-mob .active a svg path {
	fill: #063088;
	color: #063088;
}

.dasbhboard-header {
	background: #063088;
	box-shadow: inset 0px 4px 4px rgba(199, 199, 199, 0.25);
	padding: 60px 0px 77px;
	display: flex;

}

.dasbhboard-header .db-container {
	max-width: 940px;
	margin: 0 auto;
	width: 100%;
}

.db-flex {
	align-items: center;
	justify-content: space-between;
	display: flex;
}

.dasbhboard-header h3 {
	font-weight: 700;
	font-size: 22px;
	line-height: 25px;
	color: #FFFFFF;
}

/* New User Screen CSS Start */
.newUser-screen {
	/* min-height: calc(100vh - 500px;); */
	padding: 155px 35px;
	background: url('../images/new/dashboard-bg.png') center center no-repeat;
	background-size: cover;
	display: flex;

	justify-content: center;
	text-align: center;
}

.newUser-container {
	max-width: 538px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dashboard-apply-info {
	border: 1px dashed #D1D1D1;
	;
	padding: 47px 64px 39px;
	margin-bottom: 38px;
	border-radius: 6px;
}

.logo-dashboard {
	margin-bottom: 50px;
}

.newUser-screen h3 {
	font-weight: 700;
	font-size: 34px;
	line-height: 44px;
	display: flex;
	color: #000000;
	margin-bottom: 14px;
}

.newUser-screen span {
	font-weight: 700;
	font-size: 15px;
	line-height: 19px;
	display: block;
	color: #000000;
	margin-bottom: 54px;
}

.newUser-screen .db-icon {
	max-width: 72px;
	margin: 0 auto 21px;
}

.newUser-screen p {
	color: #B0AFAF;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.newUser-screen .btn-primary {
	max-width: 294px;
	width: 100%;
	border-radius: 8rem;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
}


.dasbhboard-content {
	padding: 64px 0;
	background: #fff;
	box-shadow: 0px 11px 17px rgb(207 207 207 / 30%);
	min-height: calc(100vh - 300px);
}

.dbCards-container {
	padding: 0px 32px 0 32px;
}

.dbUser-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 34px;
}

.user-col {
	flex: 0 0 60%;
	max-width: 60%;
	width: 100%;
	display: flex;
}

.filters-col {
	flex: 0 0 40%;
	max-width: 40%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.user-img {
	width: 51px;
	height: 51px;
	flex: 0 0 51px;
	margin-right: 30px
}

.user-info h4 {
	font-weight: 700;
	font-size: 18px;
	line-height: 23px;
	color: #000000;
	margin-bottom: 4px;
}

.user-info p {
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #000000;
}

.dbUser-row .btn-primary {
	max-width: 214px;
	border-radius: 4px;
	font-size: 14px;
	height: 40px;
	width: 100%;
	margin-right: 10px;
}

.dbFilter-btn {
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 4px;
	filter: drop-shadow(0px 4px 4px rgba(148, 148, 148, 0.42));
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.db-filters {
	position: relative;
	z-index: 2;
}

.dbFilter-dropdown {
	background: #FFFFFF;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	width: auto;
	min-width: 170px;
	max-width: 213px;
	position: absolute;
	right: 0;
	top: calc(100% + 5px);
	overflow: hidden;
	display: none;
}

.dbFilter-dropdown li {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: var(--color-secondary);
	padding: 10px 20px;
	display: block;
	transition: 0.25s all ease;
	cursor: pointer;
}

.dbFilter-dropdown li:hover {
	background: #F6F9FF;
}

.dashboard-cards .col {
	max-width: 50%;
	flex: 0 0 50%;
	width: 100%;
	margin-bottom: 20px;
}

.dashboard-cards .db-icon {
	text-align: center;
}

/*--- Dashboard Card CSS Start  ---*/
.dashboard-card {
	padding: 22px;
	background: #FFFFFF;
	box-shadow: 0px 1px 4px rgba(158, 157, 157, 0.15);
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	position: relative;
}

.dbCard-tag {
	font-weight: 700;
	font-size: 15px;
	line-height: 12px;
	padding: 0px 0 13px;
	min-width: 58px;
	text-transform: capitalize;
}

.dbCard-tag:after {
	display: inline-block;
	font: normal normal normal 16px/1 FontAwesome;
	margin-left: 5px;
}

.dashboard-card:before {
	content: "";
	width: calc(100% - 20px);
	height: 1px;
	position: absolute;
	left: 10px;
	top: 0;
	z-index: 1;
	transition: 0.3s all ease;
}

.dashboard-card .id {
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: #666666;
	margin-bottom: 7px;
}

/* Franchise Live CSS */
.dashboard-card.live:before {
	background-color: var(--color-success);
}

.dashboard-card.live .dbCard-tag {
	background-color: var(--color-success);
	color: #fff;
	display: inline-block;
	border-radius: 3px;
	padding: 5px 6px;
	padding-left: 20px;
	position: relative;
	margin-bottom: 7px;
}

.dashboard-card.live .dbCard-tag:before {
	content: "";
	border-radius: 50%;
	width: 11px;
	height: 11px;
	display: inline-block;
	position: absolute;
	left: 5px;
	top: 50%;
	border: 1px solid #fff;
	transform: translateY(-50%);
	margin: 0;
}

.dashboard-card.live .dbCard-tag:after {
	content: "";
	border-radius: 50%;
	width: 7px;
	height: 7px;
	display: inline-block;
	position: absolute;
	left: 7px;
	top: 50%;
	background-color: #fff;
	transform: translateY(-50%);
	margin: 0;
}

/* Franchise incomplete CSS */
.dashboard-card,
.dashboard-card.incomplete,
.dashboard-card.pending,
.dashboard-card.rejected,
.dashboard-card.approved,
.dashboard-card.live {
	background: #FFFFFF;
	box-shadow: 0px 1px 4px rgba(158, 157, 157, 0.15);
	filter: drop-shadow(0px 4px 20px rgba(177, 177, 177, 0.2));
}

/* .dashboard-card.incomplete:before {
    background-color: #FC0113;
    width: 50%;
} */
.dashboard-card.incomplete .dbCard-tag {
	color: #FF9A3E;
}

.dashboard-card.incomplete .dbCard-tag:after {
	content: "\f05a";
	color: #FF9A3E;
}

/* Franchise Pending CSS */
/* .dashboard-card.pending:before {
    background-color: #3C7DF3;
} */
.dashboard-card.pending .dbCard-tag {
	color: #FFC700;
}

.dashboard-card.pending .dbCard-tag:after {
	content: "\f017";
	color: #FFC700;
}

/* Rejected Franchise CSS */
/* .dashboard-card.rejected:before {
    background-color: #FC0113;
} */
.dashboard-card.rejected .dbCard-tag {
	color: #FC0113;
}

.dashboard-card.rejected .dbCard-tag:after {
	content: "\f057";
	color: #FC0113;
}

/* Approved Franchise CSS */
/* .dashboard-card.approved:before {
    background-color: var(--color-success);
} */
.dashboard-card.approved .dbCard-tag {
	color: #00C136;
}

.dashboard-card.approved .dbCard-tag:after {
	content: "\f058";
	color: #00C136
}

.dashboard-card .f-name {
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #000000;
	margin-bottom: 17px;
}

.dashboard-card .img {
	border-radius: 5px;
	overflow: hidden;
	width: 60px;
	height: 70px;
	margin-bottom: 22px;
}

.dashboard-card img {
	width: 100%;
	max-width: 100%;
	height: 100%;
	object-fit: cover;
}

.dasbhboard-content .col .dashboard-apply-info {
	margin-bottom: 0px !important;
	height: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dashboard-card .date {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #D3D3D3;
	display: block;
	margin-top: 5px;

}

.dbCard-actions {
	text-align: right;
}

.dbCard-actions button {
	padding: 0 6px;
	margin: 0;
	background-color: transparent;
	border: none;
	line-height: 1;
}

.dbCard-actions button+button {
	border-left: 1px solid #E8E6E6;
	padding-right: 0;
}

.dbCard-actions button svg {
	width: 12px;
	height: 12px;
}

/*--- Dashboard Card CSS End  ---*/

.head-title.dashboard-title {
	width: 100%;
	left: 0;
	text-align: center;
	justify-content: center;
	padding: 20px;
}

.dashboard-title.fixed {
	background-color: var(--color-secondary);
}

.statistics-block {
	margin-top: 20px;
	padding: 0 0 58px 0;
	position: relative;
	z-index: 1;
}

.statistics-block:after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 90px;
	display: inline-block;
	z-index: -1;
	background: linear-gradient(180deg, rgba(225, 252, 215, 0.3) 0%, #FFFFFF 35.94%, #F9FAFB 92.19%);
}

.statistics-container {
	padding: 38px 90px 50px 90px;
}

.statistics-title {
	border-bottom: 1px solid #23CE6B;
	padding-bottom: 17px;
	margin-bottom: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.statistics-title h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
}

.statistics-title .statistics-close {
	display: none;
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
}

.statistics-block .col:first-child {
	max-width: 35%;
	flex: 0 0 35%;
	display: flex;
	flex-wrap: wrap;
}

.statistics-block .col:nth-child(2) {
	max-width: 65%;
	flex: 0 0 65%;
	display: flex;
	flex-wrap: wrap;
}

.statistics-card {
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
	border-radius: 8px 0 0 8px;
	max-width: 50%;
	flex: 0 0 50%;
	padding: 27px 18px;
	margin-bottom: 18px;
}

.statistics-card:nth-child(2) {
	background: #FBFBFB;
	border-radius: 0 8px 8px 0;
}

.statistics-card:nth-child(3) {
	background: #FBFBFB;
	border-radius: 8px 0 0 8px;
}

.statistics-card:nth-child(4) {
	border-radius: 0 8px 8px 0;
}

.statistics-card.active {
	border-radius: 0 8px 8px 0;
	background: rgba(225, 252, 215, 0.46);
}

.card-text {
	display: flex;
	align-items: flex-start;
	margin-bottom: 12px;
}

.statistics-card svg {
	margin-right: 12px;
	width: 13px;
	flex: 0 0 13px;
}

.statistics-card p {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
}

.statistics-card span {
	font-weight: 700;
	font-size: 28px;
	line-height: 36px;
	color: #23CE6B;
	display: block;
	text-align: center;
}

.graph-card {
	background: #FFFFFF;
	border-radius: 10px;
	height: 258px;
	width: 100%;
	padding: 15px 40px;
}

.chart-head {
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
}

.graph-card h4 {
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: #08083D;
}

.date-picker input {
	/* border: none; */
	padding: 0;
}

.graph-wrap {
	margin: -30px 0 0 0;
	width: calc(100% + 22px);
}

.dashboard-btn {
	display: none;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	position: fixed;
	bottom: 70px;
	left: 0;
	z-index: 999;
	width: 100%;
	background-color: #fff;
	padding: 7px 35px 10px 35px;
}

.dashboard-btn .btn-primary {
	width: 100%;
	max-width: 100%;
	border-radius: 10px;
}


.weeks {
	display: flex;
	align-items: center;
	justify-content: center;
}

.weeks button {
	padding: 0 5px;
	margin: 0;
	border-radius: none;
	cursor: pointer;
	background: transparent;
	border: none;
}

.weeks span {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: #666666;
	display: inline-block;
	margin: 0 10px;
}

.statistics-block .dashboard-card {
	background-color: transparent;
	padding: 0;
	border-radius: 0;
	margin-bottom: 35px;
	filter: none;
	box-shadow: none;
	display: none;
}

.statistics-block .dashboard-card:before {
	content: none;
}

.statistics-block .dashboard-card .img {
	width: 90px;
	height: 110px;
}

/*--- Dashboard CSS End ---*/
/*--- Dashboard Modal css End ---*/
.delete-popup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
	height: 100vh;
	pointer-events: none;
	transition: 0.3s all ease;
	background-color: transparent;
	z-index: 111;
}

.delete-popup h4 {
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 43px;
	text-align: center;
}

.delete-popup p {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 38px;
}

.popup-inner {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: 424px;
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	padding: 24px 36px;
	z-index: 1111;
	pointer-events: all;
}

.delete-popup.show {
	opacity: 1;
	z-index: 100;
	visibility: visible;
}

.delete-popup svg {
	transition: 0.3s all ease;
	transform: scale(1);
	animation-delay: 0.3s all ease;
}

.delete-popup.show svg {
	transform: scale(1);
}

.delete-popup-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.delete-popup .popup-inner .del-btn {
	color: #000000;

	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	border-bottom: 1px solid #000;
	padding-bottom: 2px;
}

.success {
	text-align: center;
}

.success .delete-popup-footer {
	justify-content: center !important;
}

.delete-popup .popup-inner.success p {

	margin: 17px 0;
}

.delete-popup .popup-inner .btn-primary {
	filter: unset;
	font-size: 14px;
	line-height: 15px;
	height: 34px;
	width: 110px;
	border-radius: 6px;
}

.delete-popup .popup-icon {
	border-radius: 100%;
	width: 95px;
	height: 95px;
	display: block;
	margin: 0 auto !important;
	animation: pulse 1500ms infinite;
}

.delete-popup .modalBackdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

.delete-popup .close-popup {
	background-color: transparent;
	outline: none;
	border: none;
	position: absolute;
	top: 19px;
	right: 19px;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 #23CE6B;
	}

	75% {
		box-shadow: 0 0 0 30px #23CE6B00;
	}
}

.delete-popup .popup-inner .btn-primary.outline {
	border: 1px solid var(--color-success);
	background-color: white !important;
	color: var(--color-success);
	margin-top: 13px;
}

.delete-popup .profile-popup span {
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
	color: #949494;
	display: block;
	margin: 25px 0 21px;
}


@media (max-width: 1199px) {
	.dashboard-cards .col {
		max-width: 50%;
		flex: 0 0 50%;
	}

	.dbCards-container {
		padding: 50px 50px 0 50px;
	}

	.statistics-container {
		padding: 38px 50px 50px 50px;
	}

	.main-content-wrap {

		width: 95%;
	}

	.dasbhboard-header .db-container {

		width: 95%;
	}
}

.mobile-show {
	display: none;
}

@media(max-width: 991px) {
	.dashboard-wrap {
		height: 100vh;
	}

	.newUser-screen .newUser-container .mobile-show .user-detail h3 {
		display: block !important;
		font-size: 18px;
		line-height: 19px;
		margin-bottom: 4px;
	}

	.mobile-show {
		display: block;
	}

	.newUser-container .mobile-show .dashboard-detail. .dasbhboard-content .mobile-show .dashboard-detail {
		margin-bottom: 26px;
	}

	.newUser-container .mobile-show .dashboard-detail .user-icon,
	.dasbhboard-content .mobile-show .dashboard-detail .user-icon {
		margin-right: 14px;
	}

	.newUser-screen .newUser-container .mobile-show .user-detail p,
	.dasbhboard-content .mobile-show .user-detail p {
		font-size: 14px;
		line-height: 18px;
		color: #666666;
	}

	.newUser-screen .newUser-container .mobile-show .user-detail,
	.dasbhboard-content .mobile-show .user-detail {
		text-align: left;
	}

	.dasbhboard-header .user-detail,
	.dasbhboard-content .user-detail {
		margin-left: 3px;
	}

	.user-col {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.btn-full {
		margin: 26px 0 36px;
	}

	.btn-full .btn-primary {
		height: 45px;
		border-radius: 6px;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
		font-size: 14px;
		line-height: 19px;
		margin: 0 auto;
		text-transform: capitalize !important;
	}

	.user-col .user-info {
		display: none;
	}
  .dasbhboard-content .dashboard-apply-info {
      padding: 47px 14px 39px;
      margin-bottom: 38px;
      margin-top: 0;
  }
	.dasbhboard-content .dashboard-wrap .mobile-divider {
		padding: 100px 0 0 0;
	}

	.dashboard-cards .col {
		max-width: 100%;
		flex: 0 0 100%;
	}

	/* .dashboard-cards .row {
        margin-left: 0;
        margin-right: 0;
    } */
	.dasbhboard-content {
		padding: 23px 20px;
		border-radius: 10px 10px 0 0;
		margin-top: -30px;
		height: calc(100vh - 200px);
		min-height: auto;
		background-color: #fff;
		box-shadow: none;
	}

	.dashboard-card,
	.dashboard-card.incomplete,
	.dashboard-card.pending,
	.dashboard-card.rejected,
	.dashboard-card.approved,
	.dashboard-card.live {

		box-shadow: 0px 2px 16px rgba(6, 51, 54, 8%);
		filter: unset;
	}

	.dasbhboard-header {
		display: none;
	}

	.dashboard-right {
		padding: 0;
	}

	.dashboard-nav {
		bottom: -1px;
		flex: 0 0 100%;
		max-width: 100%;
		top: auto;
		width: 100%;
		height: auto;
		z-index: 100;
		padding: 0;
		background-color: #fff;
		padding: 0px 15px;
		box-shadow: 0px 2px 8px rgba(224, 224, 224, 0.73);
	}

	.dashboard-nav li {
		padding: 0 5px;
		flex: 0 0 25%;
		max-width: 25%;
		width: 100%;
	}

	.dashboard-menu {
		height: auto;
		flex-direction: row;
		display: flex;
		margin-top: 0;
		top: 0;
		align-items: center;
		justify-content: space-between;
	}

	.dashboard-menu a {
		background: transparent;
		text-align: center;
		width: auto;
		height: auto;
		flex-direction: column;
		box-shadow: none;
		filter: none;
	}

	.dashboard-menu a span {
		display: block;
		font-size: 12px;
	}

	.dashboard-menu li:last-child::before {
		display: none;
	}

	.desk-none {
		display: none;
	}

	.dashboard-menu a:hover,
	.dashboard-menu .active a,
	.dashboard-menu .active a span {
		color: #063088 !important;
		background: unset;
		filter: none;
		border-radius: 0px;
	}

	.dashboard-menu a:hover svg path {
		fill: #063088 !important;
	}

	.dashboard-menu .active svg path {
		fill: var(--color-secondary) !important;
	}

	.dashboard-menu li+li {
		margin: 0;
	}

	.head-title .dbFilter-btn {
		padding: 0;
		background-color: transparent;
		height: 20px;
		width: auto;
		margin-left:

	}

	.head-title .dbFilter-btn svg path {
		stroke: #fff;
	}

	.filters-col {
		display: none;
	}

	.dbUser-row {
		margin-bottom: 0;
	}
	.dbCards-container {
		padding: 0;
	}
	.newUser-screen {
		min-height: calc(100vh - 270px);
		padding: 41px 20px;
		align-items: unset;
		justify-content: unset;
	}

	.main-content-wrap {
		box-shadow: 0px 8px 14px rgb(207 207 207 / 17%);
		width: 100%;
	}

	.dasbhboard-content .user-col {
		padding: 0;
	}

	.dashboard-menu .active a,
	.dashboard-menu a:hover {
		background: rgba(6, 48, 136, 0.12);
	}

	.dashboard-menu a {
		position: relative;
		padding: 26.5px 0px !important;
		margin-left: 0px !important;

	}

	.dashboard-menu a span {

		padding-left: 0px;
		text-align: center;

		margin-top: 3px;
	}

	.dashboard-menu li.active a::after,
	.dashboard-menu li:hover a::after {
		display: none;

	}

	.user-info h4 {
		font-size: 17px;
		color: #fff;
		line-height: 22px;
	}

	.user-info p {
		font-size: 14px;
		color: #fff;
	}

	.dashboardNav-logo {
		display: none;
	}

	.dashboard-cards {
		height: calc(100vh - 390px);
		overflow: auto;
		overflow-x: hidden;
    padding: 7px 5px 0;
	}

	.dashboard-btn {
		display: none;
	}

	.statistics-block {
		position: fixed;
		top: 0;
		left: -100%;
		transition: 0.3s all ease;
		width: 100%;
		height: 100%;
		overflow: auto;
		z-index: 9999;
		margin-top: 0;
		background: #fff;
		background-color: #fff;
		padding: 0;
	}


	.statistics-block.show {
		left: 0;
	}

	.statistics-block:after {
		top: 74px;
	}

	.statistics-title .statistics-close {
		display: block;
	}

	.statistics-container {
		padding: 30px 35px;
		background: linear-gradient(180deg, rgba(225, 252, 215, 0) 0%, #FFFFFF 41.67%, #FFFFFF 92.19%);
	}

	.statistics-block .col {
		flex: 0 0 100% !important;
		max-width: 100% !important;
		padding-left: 0;
		padding-right: 0;
	}

	.graph-card {
		margin-top: 35px;
		padding-left: 0;
		padding-right: 0;
	}

	.statistics-card: {
		margin-bottom: 12px;
	}

	.statistics-title {
		margin-bottom: 15px;
	}

	.graph-wrap {
		margin: 0;
		width: 100%;
	}

	.statistics-block .dashboard-card {
		display: flex;
	}

	.dashboard-wrap.zIndex {
		position: relative;
		z-index: 1050;
	}


	.newUser-screen {
		border-radius: 10px 10px 0 0;
		margin-top: 0px;

	}

	.newUser-screen span,
	.newUser-screen h3 {
		display: none;
	}

	.newUser-container {
		max-width: 360px;
		align-items: unset;
	}

	.newUser-screen .btn-primary {
		display: flex;
		margin: 0 auto;
	}

	.newUser-screen p {
		font-size: 14px;
		line-height: 16px;
	}

	.dashboard-apply-info {

		padding: 47px 14px 39px;
		margin-bottom: 38px;
		margin-top: 26px;
	}

	.wrap-newUser-screen {
		padding: 0 15px;
		margin-top: -33px;
	}

}


@media(max-width: 370px) {
	.dasbhboard-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.user-col {
		padding: 15px;
	}
}

/*--- New Dashboard Style End---*/


/* Skeleton */
.dashboard-skeleton {
	padding: 150px 0 60px 0;
	background: #F9FAFE;
	box-shadow: inset 0px 4px 4px rgb(199 199 199 / 25%);
	min-height: calc(100vh - 300px);
}

.container-skeleton {
	max-width: 940px;
	width: 100%;
	margin: 0 auto;
	margin-top: -185px;
	border-radius: 8px;
	background: white;
	box-shadow: 0px 11px 17px rgb(207 207 207 / 30%);
	min-height: calc(100vh - 300px);
	/* padding: 0 90px; */
}

.dashboard-skeleton .col {
	max-width: 33.333%;
	flex: 0 0 33.333%;
	margin-bottom: 20px;
}

.dHead-skeleton {
	padding: 64px 0;
}

.skeleton-box {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex: 0 0 100%;
	height: 120px;
	background: #FFFFFF;
	box-shadow: 0px 1px 4px rgb(158 157 157 / 15%);
	border-radius: 10px;
	padding: 10px;
}

.skel-lines {
	width: calc(100% - 120px);
	display: block;
}

.animated-background,
.skel-line,
.skel-img {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
	background-size: 800px 104px;
	position: relative;
}

.skel-line {
	width: 100%;
	display: block;
	height: 17px;
	margin-bottom: 10px;
}

.skel-line:last-child {
	margin-bottom: 0;
}

.dashboard-skeleton .skel-img {
	height: 100px;
	width: 100px;
}

.dHead-skeleton {
	align-items: center;
}

.dHead-skeleton .col {
	flex: 0 0 50%;
	max-width: 50%;
}

.dHead-skeleton .skeleton-box {
	box-shadow: none;
	background-color: transparent;
}


@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@media (max-width: 991px) {


	.dashboard-skeleton {
		padding: 30px 0;
		border-radius: 8px 8px 0 0;
		margin-top: -33px;
		margin-right: 15px;
		margin-left: 15px;
	}

	.dashboard-skeleton .col {
		max-width: 100%;
		flex: 0 0 100%;
		margin-bottom: 20px;
	}

	.delete-popup {
		max-width: 308px;
		padding: 24px 16px;
	}

	.delete-popup h4 {
		margin-bottom: 17px;
	}

	.delete-popup p {
		font-size: 12px;
		text-align: center;
		line-height: 12px;
		margin-bottom: 20px;
	}

	.dHead-skeleton {
		display: none !important;
	}

	.container-skeleton {

		margin: 0 auto;
		margin-top: -32px;

	}

	.container-skeleton {
		padding: 0 30px;
	}
}

.dashboard-menu a,
.dashboard-menu-mob a {
	position: relative;
	padding: 16px 27px;
	margin-left: 18px;
}
